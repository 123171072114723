import { Injectable, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { GlobalConstants } from '../app.globalConstants'; 
@Injectable({
  providedIn: 'root'
})
export class TransportService {

  constructor(
    private http: HttpClient,
  ) { }
    
  transportData(data){ 
    return this.http.post(GlobalConstants.endPoints.POST_ADD_TRANSPORT, data);
  }
  addTransport(data){ 
    return this.http.post(GlobalConstants.endPoints.POST_ADD_TRANSPORT, data); 
  }
}
