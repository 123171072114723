import { Injectable } from '@angular/core'; 
import { HttpClient } from '@angular/common/http'; 
import { GlobalConstants } from '../app.globalConstants'; 
 
@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  constructor(
    private http: HttpClient,
  ) { }
  contactData(data){
    return this.http.post(GlobalConstants.endPoints.POST_CONTACT_US, data);
  }
}
