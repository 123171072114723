import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.accordion-item .heading').on('click', function(e) {
      e.preventDefault();
  
      // Add the correct active class
      if($(this).closest('.accordion-item').hasClass('active')) {
          // Remove active classes
          $('.accordion-item').removeClass('active');
      } else {
          // Remove active classes
          $('.accordion-item').removeClass('active');
  
          // Add the active class
          $(this).closest('.accordion-item').addClass('active');
      }
  
      // Show the content
      var $content = $(this).next();
      $content.slideToggle(100);
      $('.accordion-item .content').not($content).slideUp('fast');
    });
  }
  decreasefontsize(){
    $('.inner-wrap h1').css('font-size', '-=1');
    $('.inner-wrap h2').css('font-size', '-=1');
    $('.inner-wrap h3').css('font-size', '-=1');
    $('.inner-wrap h4').css('font-size', '-=1');
    $('.inner-wrap h5').css('font-size', '-=1');
    $('.inner-wrap h6').css('font-size', '-=1');
    $('.inner-wrap p').css('font-size', '-=1');
    $('.inner-wrap a').css('font-size', '-=1');
    $('.inner-wrap li').css('font-size', '-=1');
    $('.inner-wrap span').css('font-size', '-=1');
  }
  increasefontsize(){
    $('.inner-wrap p').css('font-size', '+=2');
    $('.inner-wrap h1').css('font-size', '+=2');
    $('.inner-wrap h2').css('font-size', '+=2');
    $('.inner-wrap h3').css('font-size', '+=2');
    $('.inner-wrap h4').css('font-size', '+=2');
    $('.inner-wrap h5').css('font-size', '+=2');
    $('.inner-wrap h6').css('font-size', '+=2');
    $('.inner-wrap a').css('font-size', '+=2');
    $('.inner-wrap li').css('font-size', '+=2');
    $('.inner-wrap span').css('font-size', '+=2');
  }
}
