import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from 'src/app/api.constants';
import { UserActionTypes } from 'src/app/actions/user.actions';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
  
export class AuthService implements CanActivate {

  public get:Observable<any>;
  public auth;
  public username;
  private backendHnadler: HttpClient

  constructor(private http: HttpClient,
    private store: Store<User>,
    private handler: HttpBackend,
    private _router: Router) {
      this.backendHnadler = new HttpClient(handler);
      this.store.select(state=> state['user'])
      .subscribe(data=>{
        this.auth = data;
        if(data){
          this.username = data.email;
        }
      });
      this.checkUser();
    }

  login(payLoad): Observable<any>{
    this.destroyUser();
    return this.http.post(GlobalConstants.endPoints.LOGIN_USER, payLoad);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const user = this._authService.getUser();
    // if (user && user.token) return true;
    // this.router.navigate(['/signin']);
    // return false;


    let params:Object = route.params;
    if(params && Object.keys(params).length > 0 && params.hasOwnProperty('token')) {
      var token = (params as any)?.token;
      let header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      return this.backendHnadler.get(GlobalConstants.endPoints.GET_PROFILE, {headers:header} ).pipe((map(r => {
        let response = r as any;
        if(!response.status) {
          this._router.navigate(['/signin']);
          return false;
        }else{
          this.store.dispatch({ type: UserActionTypes.LOGIN_SUCCESS, payload: response.result });
          window['storageOption'] = 'sessionStorage';
          localStorage.setItem('remember', 'false');
          let result = {...response.result, 'token':token};
          (window[window['storageOption']] as any)?.setItem('auth', JSON.stringify(result));
          this._router.navigate(['/employee/employee-dashboard']);
          return true;
        }
      })),catchError(err=>{
        this._router.navigate(['/signin']);
        console.error(err);
        return of(false);
      }))
    } else if(this.auth.role == '1') {
      return true
    } else {
      this._router.navigate(['/signin']);
      // this.router.navigate(['/signin',{redirect:decodeURIComponent(state.url)}]);
      return false;  
    }
  }

  checkUser(){
    window['storageOption'] = localStorage.getItem('remember') == "true" ? 'localStorage': 'sessionStorage';
    let userData:any = (window[window['storageOption']] as any)?.getItem('auth') || false;
    let userObj = JSON.parse(userData);
    if (!userObj) return false;
    if(userObj){
      userObj.session = Date.now();
      this.username = userObj.name;
      this.store.dispatch({type:'LOGIN_SUCCESS', payload:userObj});
      return true
    }else{
      this.logout();
      return false;
    }
  }

  getUsername(){
    return this.auth.username;
  }

  logout(){
    this.store.dispatch({type:'USER_LOGOUT'});
    const remember = localStorage.getItem('remember');
    if(remember){
      if(remember === 'false'){
        sessionStorage.removeItem('auth')
      }else{
        localStorage.removeItem('auth');
      }
    }
    this._router.navigate(['/signin']);
  } 

  getUser(): any {
    return this.auth;
    // return JSON.parse(window.localStorage.getItem('auth'));
  }

  // saveUser(user): void{
  //   window.localStorage.setItem('auth', JSON.stringify(user));
  // }

  destroyUser(): void {
    window.localStorage.removeItem('auth');
  }

  forgetPassword(payload): Observable<any> {
    this.destroyUser();
    return this.http.post(GlobalConstants.endPoints.FORGOT_PASSWORD, payload)
  }

  verifyOtp(payload): Observable<any> {
    return this.http.post(GlobalConstants.endPoints.VERIFY_OTP, payload)
  }

  resetNewPassword(payload): Observable<any> {
    return this.http.post(GlobalConstants.endPoints.RESET_PASSWORD, payload)
  }

  changePassword(payload): Observable<any> {
    return this.http.post(GlobalConstants.endPoints.CHANGE_PASSWORD, payload)
  }
  
}