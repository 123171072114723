import { Component, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

declare let $:any;

@Component({
  selector: 'signature',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent{
    
    constructor(){}

     @ViewChild(SignaturePad) signaturePad: SignaturePad;
     @Output() signature = new EventEmitter()
     @Input() id:string = 'signaturePad';

    signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
        'minWidth': 5,
        'canvasWidth': 470,
        'canvasHeight': 200
    };
    signatureURI;

    ngAfterViewInit() {
        this.signaturePad.set('minWidth', 1.5);
        this.signaturePad.clear();
    }
    
    drawComplete() {
        this.signatureURI = this.signaturePad.toDataURL()
    }
    
    clear(){
        this.signaturePad.clear();
        this.drawComplete();
    }

    drawStart() {
        // console.log('begin drawing');
    }

    updateSignature(){
      this.signature.emit(this.signatureURI);
      $(`#${this.id}`).modal('hide');
      this.signaturePad.clear();
    }
}