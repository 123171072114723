import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {ToastrService} from 'ngx-toastr';

import { AuthenticateService } from './services/authServices/auth.service'


@Injectable()
export class ExtendedHttpService implements HttpInterceptor {
  constructor(
    private router: Router,
    private toaster: ToastrService,
    private _auth: AuthenticateService
  ){}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    window['storageOption'] = localStorage.getItem('remember') == "true" ? 'localStorage': 'sessionStorage';
    let userData:any = (window[window['storageOption']] as any)?.getItem('auth') || false;
    let userObj = JSON.parse(userData);
    const token = userObj?.token;
    if (token) {
        request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
    }

    // if (!request.headers.has('Content-Type')) {
    //     request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    // }

    // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
            return event;
        }),
        catchError((error: HttpErrorResponse)=>{
         return this.catchErrors(error)
          // return throwError(error)
        })
    )}

  private catchErrors(error) {
    if (error.status == 401) {
      this._auth.logout() 
    }
    else if (error.status > 401) {
      this.toaster.error(error.url, error.statusText)
    }
    // else if(error.status >= 500 || !error.status){
    else if(error.status >= 500){
      this.router.navigate(['/'])
    }else{
        this.toaster.error('Unknown error occured');
      }
      return throwError(error)
  }

}