import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { GlobalConstants } from '../app.globalConstants'; 
 

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(
    private http: HttpClient,
  ) { }
  addFeedbackData(data){
    return this.http.post(GlobalConstants.endPoints.POST_FEEDBACK, data);
  }
}
