import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../app.globalConstants'; 

@Injectable({
  providedIn: 'root'
})
export class ClientRefferalService {

  constructor(
    private http: HttpClient,
  ) { }
  addClientReferral(data){
    return this.http.post(GlobalConstants.endPoints.POST_ADD_CLIENT_REFERRAL, data);
  }
}
