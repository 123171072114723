import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray} from '@angular/forms';
import { ClientRefferalService } from './client-refferal.service';
import { ToastrService } from 'ngx-toastr'
declare let $: any;
@Component({
  selector: 'app-client-referral-form',
  templateUrl: './client-referral-form.component.html',
  styleUrls: ['./client-referral-form.component.scss']
})
export class ClientReferralFormComponent implements OnInit {

  constructor(
    private service: ClientRefferalService,
    private toast: ToastrService,
    private route:Router,
    private el:ElementRef
  ) { }
  clientReferral:FormGroup;
  submitted:boolean = false;
  isLoading:boolean = false;
  maxDate={year: new Date().getFullYear(), month:new Date().getMonth()+1, day:new Date().getDay()}

  cancel(){
    this.route.navigate(['/'])
  }
  
  handleRequirement(event){
    const formArray: FormArray = this.clientReferral.get('specific_requirements') as FormArray;
    const value = event.target.value;
    if(formArray.value.indexOf(value) == -1){
      formArray.push(new FormControl(value))
    }else{
      const index = formArray.value.indexOf(value);
      formArray.removeAt(index)
    }
  }

  convertDate(date){
    if(date && date?.year){
      return `${date.day}-${date.month}-${date.year}`
    }else{
      return "";
    }
  }

  itemChecked(key){
    const formArray: FormArray = this.clientReferral.get('specific_requirements') as FormArray;
    return formArray.value.indexOf(key) != -1 ? true : false
  }

  submit(){
    if(this.clientReferral.invalid){
      this.submitted = true;
      this.clientReferral.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      return false;
    }
    const payLoad = JSON.parse(JSON.stringify(this.clientReferral.value));
    payLoad.dob = this.convertDate(this.clientReferral.value.dob);
    payLoad.start_date = this.convertDate(this.clientReferral.value.start_date);
    payLoad.end_date = this.convertDate(this.clientReferral.value.end_date);
    this.isLoading = true;
    this.service.addClientReferral(payLoad)
    .subscribe((response: any)=>{
      if(response.status){
        this.isLoading = false;
        this.clientReferral.reset();
        this.toast.success(response.message)
      }else{
        this.isLoading = false;
        this.toast.error(response.message)
      }
    })
  }

  ngOnInit(): void {
    this.clientReferral =  new FormGroup({
      name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]+')]),
      email: new FormControl('', [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])(\.[a-z0-9]([a-z0-9-]*[a-z0-9]))*$/i)]),
      phonenumber: new FormControl('', [Validators.pattern('[- +()0-9]{9,12}')]),
      gender: new FormControl(''),
      dob: new FormControl(null),
      mobile: new FormControl('', [Validators.pattern('[- +()0-9]{9,12}')]),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      address: new FormControl(''),
      self_managed: new FormControl(''),
      funding_type: new FormControl(''),
      reference_number: new FormControl(''),
      coordinator: new FormControl(''),
      plan_manager: new FormControl(''),
      invoice_email: new FormControl(''),
      referring_organization: new FormControl(''),
      primary_diagnosis: new FormControl(''),
      specific_requirements: new FormArray([]),
      guardian_name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]+')]),
      guardian_phonenumber: new FormControl('',[Validators.required, Validators.pattern('[- +()0-9]{9,12}')]),
      guardian_email: new FormControl('', [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])(\.[a-z0-9]([a-z0-9-]*[a-z0-9]))*$/i)]),
      guardian_relationship: new FormControl('',[Validators.required]),
      monday_morning: new FormControl(''),
      tuesday_morning: new FormControl(''),
      wednesday_morning: new FormControl(''),
      thursday_morning: new FormControl(''),
      friday_morning: new FormControl(''),
      saturday_morning: new FormControl(''),
      sunday_morning: new FormControl(''),
      care_247_morning: new FormControl(''),
      monday_midday: new FormControl(''),
      tuesday_midday: new FormControl(''),
      wednesday_midday: new FormControl(''),
      thursday_midday: new FormControl(''),
      friday_midday: new FormControl(''),
      saturday_midday: new FormControl(''),
      sunday_midday: new FormControl(''),
      care_247_midday: new FormControl(''),
      monday_afternoon: new FormControl(''),
      tuesday_afternoon: new FormControl(''),
      wednesday_afternoon: new FormControl(''),
      thursday_afternoon: new FormControl(''),
      friday_afternoon: new FormControl(''),
      saturday_afternoon: new FormControl(''),
      sunday_afternoon: new FormControl(''),
      care_247_afternoon: new FormControl(''),
      monday_evening: new FormControl(''),
      tuesday_evening: new FormControl(''),
      wednesday_evening: new FormControl(''),
      thursday_evening: new FormControl(''),
      friday_evening: new FormControl(''),
      saturday_evening: new FormControl(''),
      sunday_evening: new FormControl(''),
      care_247_evening: new FormControl('')
    })
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
    
  decreasefontsize(){
    $('.inner-wrap h1').css('font-size', '-=1');
    $('.inner-wrap h2').css('font-size', '-=1');
    $('.inner-wrap h3').css('font-size', '-=1');
    $('.inner-wrap h4').css('font-size', '-=1');
    $('.inner-wrap h5').css('font-size', '-=1');
    $('.inner-wrap h6').css('font-size', '-=1');
    $('.inner-wrap p').css('font-size', '-=1');
    $('.inner-wrap a').css('font-size', '-=1');
    $('.inner-wrap li').css('font-size', '-=1');
    $('.inner-wrap span').css('font-size', '-=1');
    $('.inner-wrap td').css('font-size', '-=1');
    $('.inner-wrap th').css('font-size', '-=1');
  }
  increasefontsize(){
    $('.inner-wrap p').css('font-size', '+=2');
    $('.inner-wrap h1').css('font-size', '+=2');
    $('.inner-wrap h2').css('font-size', '+=2');
    $('.inner-wrap h3').css('font-size', '+=2');
    $('.inner-wrap h4').css('font-size', '+=2');
    $('.inner-wrap h5').css('font-size', '+=2');
    $('.inner-wrap h6').css('font-size', '+=2');
    $('.inner-wrap a').css('font-size', '+=2');
    $('.inner-wrap li').css('font-size', '+=2');
    $('.inner-wrap span').css('font-size', '+=2');
    $('.inner-wrap td').css('font-size', '+=2');
    $('.inner-wrap th').css('font-size', '+=2');
  }
}
