<section class="career-section">
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Career</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home"
                            title="Home">Home</a>
                    </li>
                    <li class="separator separator-home">
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i>
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">Career</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="career-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10"
                            (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary"
                            (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="left-section">
                        <h2 class="heading-title mb-20">Considering A Career In Caring?</h2>
                        <p class="mb-20">Do you want to work as part of a dynamic and friendly team? Want to make a
                            difference within your community? Caring Approach is always interested in hearing from
                            enthusiastic care workers, disability support officers and nurses who would like to join our team.</p>
                        <p class="mb-20">Though qualifications and experience are desired Caring Approach provides
                            professional training for promising applicants who are eager to extend their skills. Simply
                            fill in the application form to the right or send your resume to <a
                                href="https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=hr2@caringapproach.com.au"
                                class="text-theme"> hr2@caringapproach.com.au</a></p>
                        <h4 class="text-theme">Tell us how we can support you for a fully tailored service !</h4>
                        <h4 class="text-theme"><a href="tel:+1300655026"><b>Call 1300 655 026</b></a></h4>
                        <hr>
                        <h4 class="mb-20">What we offer our staff :</h4>
                        <ul class="mb-20">
                            <li class="mb-15">Flexible hours of work</li>
                            <li class="mb-15">A team-oriented work environment</li>
                            <li class="mb-15">Above Award pay rates</li>
                            <li class="mb-15">Training (experience is preferred, however training will be provided)</li>
                            <li class="mb-15">Variety of work place environments</li>
                            <li class="mb-15">24 hour staff support phone line</li>
                        </ul>
                        <hr>
                        <h4 class="mb-20">What to bring when you want to work with us :</h4>
                        <ul class="mb-20">
                            <li class="mb-15">Relevant certificates</li>
                            <li class="mb-15">References</li>
                            <li class="mb-15">Visa Information</li>
                            <li class="mb-15">Drivers License (including Insurance and Registration papers)</li>
                            <li class="mb-15">Police Check</li>
                            <li class="mb-15">
                                <a href="https://www.service.nsw.gov.au/transaction/apply-working-children-check"
                                    class="text-theme" target="blank">Working with Children Check </a>(NSW) / <a
                                    href="https://www.accesscanberra.act.gov.au/app/answers/detail/a_id/1804/~/working-with-vulnerable-people-%28wwvp%29-registration"
                                    class="text-theme"  target="blank" >Working with Vulnerable People Check </a>(ACT)
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="right-section">
                        <div class="btn-group mb-15" role="group" aria-label="Basic example">
                            <a class="btn theme-outline-btn mr-2"
                                href="https://www.service.nsw.gov.au/transaction/apply-national-police-certificate"
                                target="_blank">Police Check</a>
                            <a class="btn theme-outline-btn mr-2"
                                href="https://www.service.nsw.gov.au/transaction/apply-working-children-check"
                                target="_blank">Working with Children Check</a>
                            <a class="btn theme-outline-btn mr-2"
                                href="https://www.accesscanberra.act.gov.au/app/answers/detail/a_id/1804/~/working-with-vulnerable-people-%28wwvp%29-registration"
                                target="_blank">Working with vulnerable people</a>
                            <a class="btn theme-outline-btn" href="https://www.homeaffairs.gov.au/"
                                target="_blank">Immigration</a>
                        </div>
                        <form [formGroup]="careerForm" (ngSubmit)="careerSubmit()">
                            <div class="bg-wrap">
                                <h3 class="text-black mb-0">Application form</h3>
                                <h5 class="text-black mb-15">Complete this form to apply</h5>
                                <hr>
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="name" class="text-black">Full Name</label>
                                            <input type="text" name="name" value="" class="form-control"
                                                aria-required="true" aria-invalid="false" matInput
                                                formControlName='name'>
                                            <div *ngIf="careerForm.get('name').errors?.required && submitted"
                                                class="error">
                                                Full Name is required.
                                            </div>
                                            <div *ngIf="careerForm.get('name').errors?.pattern" class="error">
                                                Numbers are not allowed.
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="street_address" class="text-black">Street Address</label>
                                            <textarea type="text" name="street_address" value="" class="form-control"
                                                aria-required="true" aria-invalid="false" matInput
                                                formControlName='street_address'></textarea>
                                            <div *ngIf="careerForm.get('street_address').errors?.required && submitted"
                                                class="error">
                                                Street Address is required.
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="address" class="text-black">Address Line 2</label>
                                            <textarea type="text" name="address" value="" class="form-control"
                                                aria-required="true" aria-invalid="false" matInput
                                                formControlName='address'></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="city" class="text-black">City</label>
                                            <input type="text" name="city" value="" class="form-control"
                                                aria-invalid="false" matInput formControlName='city'>
                                            <div *ngIf="careerForm.get('city').errors?.required && submitted"
                                                class="error">
                                                City is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="state" class="text-black">State</label>
                                            <select class="form-control" name="state" id="state" matInput
                                                formControlName='state'>
                                                <option value="NSW">NSW</option>
                                                <option value="VIC">VIC</option>
                                                <option value="SA">SA</option>
                                                <option value="WA">WA</option>
                                                <option value="NT">NT</option>
                                                <option value="ACT">ACT</option>
                                                <option value="TAS">TAS</option>
                                            </select>

                                            <div *ngIf="careerForm.get('state').errors?.required && submitted"
                                                class="error">
                                                State is required.
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="phone_no" class="text-black">Phone</label>
                                            <input type="tel" name="phone_no" value="" size="40" class="form-control"
                                                aria-invalid="false" matInput formControlName='phone_no'>

                                            <div *ngIf="careerForm.get('phone_no').errors?.required && submitted"
                                                class="error">
                                                Phone no. is required.
                                            </div>
                                            <div *ngIf="careerForm.get('phone_no').errors?.pattern" class="error">
                                                Invalid Phone no.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="email" class="text-black">Email</label>
                                            <input type="email" name="email" value="" size="40" class="form-control"
                                                aria-invalid="false" matInput formControlName='email'>
                                            <div *ngIf="careerForm.get('email').errors?.required && submitted"
                                                class="error">
                                                Email id is required.
                                            </div>
                                            <div *ngIf="careerForm.get('email').errors?.pattern && submitted"
                                                class="error">
                                                Enter valid Email id
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="comment" class="text-black">Comments</label>
                                            <textarea name="comment" cols="4" rows="4" class="form-control"
                                                aria-invalid="false" matInput formControlName='comment'></textarea>
                                            <div *ngIf="careerForm.get('comment').errors?.required && submitted"
                                                class="error">
                                                Comments is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <div class="upload-btn-wrapper">
                                            <input type="file" name="resume"
                                                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                (change)="uploadFile($event)" id="resume" hidden required />
                                            <label for="resume"
                                                class="btn btn-block theme-outline-btn custom-file-label pos-rel">Upload
                                                Resume</label>
                                            <div id="file-upload-filename" class="file-name"></div>
                                        </div>
                                        <div *ngIf="fileSizeError && submitted" class="error">
                                            Choose file size below 5MB.
                                        </div>
                                        <div *ngIf="!resume && submitted" class="error">
                                            Please choose a file.
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6 col-12">
                                        <div class="upload-btn-wrapper">
                                            <label for="document" class="btn btn-block theme-outline-btn">Upload Other
                                                Documents</label>
                                            <input type="file" (change)="uploadFile($event)" multiple name="document"
                                                id="document" hidden />
                                        </div>
                                    </div> -->
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <button type="submit" class="btn theme-btn btn-block" [disabled]="isLoading">
                                            <span class="spinner-border spinner-border-md" role="status"
                                                aria-hidden="true" *ngIf="isLoading"></span>
                                            Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>