import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-recovery-care',
  templateUrl: './recovery-care.component.html',
  styleUrls: ['./recovery-care.component.scss']
})
export class RecoveryCareComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(".testimonial-reel").slick({
      centerMode: true,
      autoplay: true,
      centerPadding: "40px",
      dots: false,
      slidesToShow: 3,
      infinite: true,
      arrows: true,
      lazyLoad: "ondemand",
      prevArrow: '<button class="slide-arrow prev-arrow btn-primary" style="border-radius: 50%;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;top: 13%;z-index: 1;left:0px;"><i class="fas fa-chevron-left"></i></button>',
      nextArrow: '<button class="slide-arrow next-arrow btn-primary" style="border-radius: 50%;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;top: 13%;z-index: 1;right:0px;"><i class="fas fa-chevron-right"></i></button>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            centerMode: false
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }
  decreasefontsize(){
    $('.inner-wrap h1').css('font-size', '-=1');
    $('.inner-wrap h2').css('font-size', '-=1');
    $('.inner-wrap h3').css('font-size', '-=1');
    $('.inner-wrap h4').css('font-size', '-=1');
    $('.inner-wrap h5').css('font-size', '-=1');
    $('.inner-wrap h6').css('font-size', '-=1');
    $('.inner-wrap p').css('font-size', '-=1');
    $('.inner-wrap a').css('font-size', '-=1');
    $('.inner-wrap li').css('font-size', '-=1');
    $('.inner-wrap span').css('font-size', '-=1');
  }
  increasefontsize(){
    $('.inner-wrap p').css('font-size', '+=2');
    $('.inner-wrap h1').css('font-size', '+=2');
    $('.inner-wrap h2').css('font-size', '+=2');
    $('.inner-wrap h3').css('font-size', '+=2');
    $('.inner-wrap h4').css('font-size', '+=2');
    $('.inner-wrap h5').css('font-size', '+=2');
    $('.inner-wrap h6').css('font-size', '+=2');
    $('.inner-wrap a').css('font-size', '+=2');
    $('.inner-wrap li').css('font-size', '+=2');
    $('.inner-wrap span').css('font-size', '+=2');
  }
}
