<section class="community-section">  
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Community</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">Community</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="community-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <h3>Blind Speed</h3>
                    <p class="mb-15">Caring Approach is proud to be a supporter and major sponsor of Ben Felten and the Blind Speed team</p>
                </div>
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <p>Caring Approach’s philosophy is “Live Life to The Fullest” and we are proud to support and partner with people and organisations that inspire and promote inclusion for people with a disability. At Caring Approach we believe its important to inspire and create opportunities for people with a disability.</p>  
                            <!--<h4>Blind Speed</h4>-->
                            <p>Caring Approach is proud to be a supporter and major sponsor of Ben Felten and the Blind Speedteam. In March 2019, Ben set a new world land speed record of 272.596km/h establishing a new Guinness World Record for fastest speed of a motorcycle ridden blindfolded on a Salt Lake in outback South Australia</p>
                            <p>
                            The Blind Speed Team includesFox Sports commentator and Former MotoGP racer Kevin Magee (Magoo) who guides Ben down the track using an analogue radio.</p>                  
                            <p>Caring Approach will again support the Blind Speed team compete at Speed Week 2020 and the assault of their own world record at the World Speed Trials Australia event in March 2020.</p>                   
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Ben’s Bio</h4>
                            <hr>
                            <img src="assets/images/blind-speed.jpg" class="img-fluid w-100 mb-15">
                            <p>Ben was born fully sighted and diagnosed with a degenerative eye condition called Retinitis Pigmentosa after a dirt bike accident at the age of 16. As his eyesight faded, Ben’s childhood dreams of racing motorcycles and joining the navy to become a mechanical engineer were put on hold.</p>
                            <p class="text-theme">“After coming to terms with blindness there is no looking back. With the support of my friends and family, I am now a multiple world record holder. Defining, working towards and achieving my goals was critical to my personal wellbeing and to developing my ability to help others.”</p>  
                            <p>Ben initially struggled with his diagnosis. During a low point in his mid-twenties, Ben realised he needed to accept his fading sight and move on with his life. He realised he wanted to make a positive difference in the lives of people with a disability. “The transition period where you are learning to live with a disability can be a dark and lonely place. When I lost sight completely, going from a healthy young man to a blind man… it almost broke me. It was the lowest point in my life,” said Ben.</p>
                            <p>After college, Ben set up the Nepean Blind Sports Club and gave up full time employment to become Coordinator of the Western Sydney – NSW Sports Council for the Disabled. He went on to establish many inclusive sports programs like Sailability Penrith Lakes. In 2016 Ben launched a registered charity called In Sight Of Dreams which is dedicated to inspire and enable people who live with an acquired disability to prepare for life and achieve their own dreams. We aim to achieve positive life change through programs aimed at personal skill development, life planning, personal wellbeing and building resilience.</p> 
                            <h4>For more information about In Sight Of Dreams go to <a href="https://www.caringapproach.com.au/www.insightofdreams.org" target="_blank"> www.insightofdreams.org</a></h4>  
                            <p>Ben is also a former world Para Rowing champion and was a member of the Australian Blind Cricket team. He has a host of medals and records to his name including the Jim Anderson Memorial Award for distinguished service to sport and administration.</p>
                            <p>For more information on Ben, Blind Speed and theirjourney to break further records can be found at</p>                  
                            <ul>
                                <li><a href="https://www.blindspeed.com.au/" target="_blank" class="text-theme">https://www.blindspeed.com.au/</a></li>
                                <li><a href="https://www.facebook.com/blindlandspeedrecord/" target="_blank"  class="text-theme">https://www.facebook.com/blindlandspeedrecord/</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-header bg-white">
                            <h4><img src="assets/images/blow-fly/blow-fly-flag.png" class="img-fluid"> BlowFly</h4>
                            <span>Launched in September 2009</span>
                        </div>
                        <div class="card-body">
                            <h5>Mission</h5>
                            <p>To provide a fun, engaging environment for children with special needs. We also provide a safe, controlled environment for parents to interact with other families every saturday. We currently primarily engage children with autism and down syndrome, however, we are looking to expand our base in the near future.</p>
                            <p>We also endeavour to provide a pathway to elite programs for various areas, including blind cricket, which does not currently cater for under-age participants.</p>
                            <hr>
                            <h4>About BlowFly Cricket</h4>
                            <span>Founding date 2009</span>
                            <p>BlowFly Cricket is a program for children, adolescents & adults (5 to 50 years of age) with special needs and /or intellectual disabilities. We continue to cater for children, adolescents & adults with autism, Down syndrome and other special needs /intellectual disabilities.</p>
                            <p>We are the first program, as far as we know, in Australia to provide such a program and we hope to eventually expand to a national audience.</p>
                            <h5>Awards</h5>
                            <ul>
                                <li>We've been awarded a grant from Dept of Sport and Recreation, which recognises the ongoing benefits that Blowfly Cricket provides to the community.</li>
                                <li>BlowFly Cricket Association has been successful in its application under Volunteer Grants 2012 for funding to assist with the development of our volunteers.</li>
                            </ul>
                            <h5 class="mb-15">Products</h5>
                            <p>Cricket for children / young adults with specail needs and/or intellectual disabilities.</p>
                            <a href="https://www.facebook.com/pg/blowflycricket/photos/?ref=page_internal" class="text-theme"  target="blank"> www.facebook.com / blowflycricket</a>
                            <a href="https://www.facebook.com/pg/blowflycricket/photos/?ref=page_internal" target="blank"></a>
                            <hr>
                            <h4>Contact Details</h4><br>
                            <a href="tel:+0401743747" class="text-theme"><i class="fa fa-phone" aria-hidden="true"></i> 0401 743 747</a><br><hr>
                            <a href="mailto:blowflycricket@y7mail.com" class="text-theme"  target="blank" ><i class="fa fa-envelope" aria-hidden="true"></i> blowflycricket@y7mail.com</a><br><hr>
                            <a href="http://blowflycc.nsw.cricket.com.au" class="text-theme"  target="blank" ><i class="fa fa-globe" aria-hidden="true"></i> http://blowflycc.nsw.cricket.com.au</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-body text-center">
                            <h4 class="mt-15">Tell us how we can support you for a fully tailored service !</h4>
                            <h4><a href="tel:+1300655026"><strong>Call 1300 655 026</strong></a></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
