<section class="ndis-section">  
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">NDIS Checklist</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">NDIS Checklist</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="ndis-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <h4>NDIS Access Checklist</h4>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>1. Do you have Australian residency?</h4>
                            <hr>
                            <p>To access the NDIS you must live in Australia and be:</p>
                            <p>Here you will find:</p>                        
                            <ul>
                                <li>An Australian citizen OR</li>
                                <li>A permanent resident OR</li>
                                <li>Hold a <a href="https://www.ndis.gov.au/about-us/operational-guidelines/access-ndis-operational-guideline/access-ndis-residence-requirements" target="_blank" class="text-theme">Protected Special Category Visa</a></li>
                            </ul>
                            <hr>
                            <p><strong>Yes.</strong> Go to Question 2.</p>
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>2. Are you under 65 years old?</h4>
                            <hr>
                            <p>To access the NDIS you must be aged under 65 years.</p>
                            <p>In some locations, you need to be a certain age to access the NDIS during the trial period</p>                        
                            <hr>
                            <p><strong>Yes.</strong> Go to Question 3.</p>
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>3. Do you live in an area where the NDIS is available?</h4>
                            <hr>
                            <p>The NDIS is available across Australia now. In some of these areas, you also need to be a certain age to access the Scheme.</p>   
                            <p>Click on the map or buttons below to find out about the NDIS in your state or territory</p>
                            <a href="https://www.ndis.gov.au/understanding/ndis-each-state" target="_blank" class="text-theme">NDIS State</a>                    
                         </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>4. Do you usually need support from a person or equipment to do everyday things for yourself because of an impairment or condition that is likely to be permanent?</h4>
                            <hr>
                            <p>To meet the NDIS disability rules you need to have an impairment or condition that is likely to be permanent (lifelong) and that stops you from doing everyday things by yourself.</p>           

                            <P>The following questions may help you decide if your answer is <strong>‘yes’</strong>.</P>         

                            <P>Do you usually need support from a person or assistive equipment so you can:</P>                          
                            <ul>
                                <li>Understand and be understood by other people?</li>
                                <li>Make and keep friends and cope with feelings and emotions?</li>
                                <li>Understand, remember and learn new things?</li>
                                <li>Get out of bed and move around the home and outside the home?</li>
                                <li>Take a bath or shower, dress and eat?</li>
                                <li>Do daily jobs, handle money and make decisions?</li>
                            </ul>                        
                         </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>5. Do you need some supports now to reduce your support needs in the future? </h4>
                            <hr>
                            <p>To meet the NDIS early intervention rules, you need to</p> 
                            <ul>
                                <li>Have an impairment or condition that is likely to be permanent (lifelong); or</li>
                                <li>Be a child under 6 years of age with a developmental delay and the delay means you usually need more help with your self-care, communication, learning or motor skills than another child of the same age.    </li>
                            </ul>
                            <p>Early intervention supports provided by the NDIS are those not provided by any other services such as health and education. </p>
                            <p>The following questions may help you decide if your answer is ‘yes’. </p>    
                            <p>Would early intervention supports: </p> 
                            <ul>
                                <li>Reduce the impact of your impairment or condition or developmental delay?</li>
                                <li>Stop the impact of your impairment or condition from getting worse?</li>
                                <li>Strengthen your informal supports, such as helping a carer to keep supporting you?</li>
                            </ul>               
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body text-center">
                            <h4 class="mt-15">If you have any questions or to ask for an access request form.</h4>
                            <h4><a href="tel:+1300655026"><strong>Call the NDIA on 1800 800 11</strong></a></h4>
                        </div>
                    </div>
                    <!--<h4><strong>Yes. Call the NDIA on 1800 800 110</strong><br> if you have any questions or to ask for an access request form.</h4>-->
                </div>
            </div>
        </div>
    </div>
</section>
