<section class="participants-section">  
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Service Users</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">Service Users</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="participants-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <h3>Service Users</h3>
                </div>
                <div class="col-md-12 col-lg-8">
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>How can we help you?</h4>
                            <hr>
                            <p>Caring Approach provide a range of different services for the elderly, people with disabilities, new mums, and people recovering from illnesses.Our services are fully customisable so that you receive the care you need when you need it. We are also flexible and work closely with our clients so that if your needs change we adapt to you. Click here to see a list of our services or contact us today to find out how we can help you sample</p>
                            <h4>Did you know that you have rights?</h4>  
                            <p>When you need care you need to know that you matter. You have rights that you should be aware of. Here are a list of your rights and if at any time you feel these rights are not being met then please contact us and we will rectify the situation immediately.</p> 
                            <p>You have the right :</p>                     
                            <ul>
                                <li>To receive clear and concise information about the services to be provided</li>
                                <li>To receive a dignified and respectful service</li>
                                <li>To receive a friendly and reliable service</li>
                                <li>To have your confidentiality respected and maintained regarding at all times</li>
                                <li>To receive culturally sensitive and non-discriminatory treatment</li>
                                <li>To make complaints and have them acted upon in a timely manner</li>
                                <li>To receive a fortnightly Tax Invoice for the services received from Caring Approach</li>
                                <li>To suspend or cancel the services provided when you no longer require the services without difficulty</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-4">
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>What are your responsibilities?</h4>
                            <hr>
                            <p>We all want to live in peace and comfort and to be treated with respect and dignity.
                            Therefore as a client you also have responsibilities.</p>  
                            
                            <ul>
                                <li>To treat our staff with dignity and respect </li>
                                <li>To maintain a safe working environment, which complies with current Work Health and Safety legislation (WHS) requirements</li>
                                <li>To answer any relevant questions honestly and to the best of your ability</li>
                                <li>To retain the Care Plan duties as agreed at the initial assessment unless your circumstance/s change. Please note this has to be in writing as per the Client Contract</li>
                                <li>To pay your Caring Approach Account, as agreed at the initial assessment, and to inform Caring Approach in writing if circumstances change, in order for alternative arrangements to be made.</li>
                            </ul>  
                            <hr>
                            <a href="tel:+1300655026"><p>Call 1300 655 026</p> </a>                  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="testimonial-section inner-wrap">
        <div class="container">
            <div class="row">
                <div class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-12 text-center">
                    <h2 class="title">Client Testimonials</h2>
                    <p class="text">What our clients have to say about Caring Approach</p>
                </div>
            </div>
            <div class="row mt-20">
                <!-- Testimonial -->
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="box">
                        <!-- Testimonial Image -->
                        <div class="video-wrap mr-5" style="width:100%;height:300px;border: 1px solid #eeeeee;padding: 5px;background-color: #ffffff;box-shadow: 0 0 15px 0 rgba(41, 61, 88, 0.1);margin-bottom:20px;">
                            <video controls="controls" style="width: 100%; height: 100%;" poster="/assets/images/testi-poster1.png">
                                <source src="assets/video/greg-moran-testimonial.mp4" type="video/mp4"> Your browser does not support the video tag. 
                            </video>
                        </div>
                        <!-- / Testimonial Image -->
                        <div class="test-component">
                            <!-- Title -->
                            <article class="test-title">
                                <h4>
                                    Greg Moran
                                </h4>
                            </article>
                            <!-- / Title -->
                            <article class="test-content">
                                <p>
                                    My name is Greg Moran, I'm a C3/4 quadriplegic as a result of a rugby accident in 1983. In my 30 odd years as a quadriplegic I can categorically say that Caring Approach Agency... <a href="javascript:void(0);" routerLink="/testimonials"><small>Read more >></small></a>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- / Testimonial -->
                <!-- Testimonial -->
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="box">
                        <!-- Testimonial Image -->
                        <div class="video-wrap mr-5" style="width:100%;height:300px;border: 1px solid #eeeeee;padding: 5px;background-color: #ffffff;box-shadow: 0 0 15px 0 rgba(41, 61, 88, 0.1);margin-bottom:20px;">
                            <video controls="controls" style="width: 100%; height: 100%;" poster="assets/images/testi-poster2.png">
                                <source src="assets/video/joanna-willis-testimonial.mp4" type="video/mp4"> Your browser does not support the video tag. 
                            </video>
                        </div>
                        <!-- / Testimonial Image -->
                        <div class="test-component">
                            <!-- Title -->
                            <article class="test-title">
                                <h4>
                                    Farrah
                                </h4>
                            </article>
                            <!-- / Title -->
                            <article class="test-content">
                                <p>
                                    I decided to use Caring Approach for my personal and domestic care needs because Homecare did not provide the quality of staff or the flexibility of care that I needed...
                                    <a href="javascript:void(0);" routerLink="/testimonials"><small>Read more >></small></a>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- / Testimonial -->
                <!-- Testimonial -->
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="box">
                        <!-- Testimonial Image -->
                        <div class="video-wrap mr-5" style="width:100%;height:300px;border: 1px solid #eeeeee;padding: 5px;background-color: #ffffff;box-shadow: 0 0 15px 0 rgba(41, 61, 88, 0.1);margin-bottom:20px;">
                            <video controls="controls" style="width: 100%; height: 100%;" poster="assets/images/testi-poster3.png">
                                <source src="assets/video/mark-testimonial.mp4" type="video/mp4"> Your browser does not support the video tag. 
                            </video>
                        </div>
                        <!-- / Testimonial Image -->
                        <div class="test-component">
                            <!-- Title -->
                            <article class="test-title">
                                <h4>
                                    Mark
                                </h4>
                            </article>
                            <!-- / Title -->
                            <article class="test-content">
                                <p>
                                    I have been receiving home care for approximately nine months. Initially, that care was provided by another agency. It was only after I began using Caring Approach...
                                    <a href="javascript:void(0);" routerLink="/testimonials"><small>Read more >></small></a>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- / Testimonial -->
            </div>
        </div>
    </div>
</section>
