<div class="content-wrapper">
    <section class="reports-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12">
                    <button class="btn btn-theme-blue my-2 br-0 uppercase mb-3" data-toggle="modal" data-target="#uploadreportsModal">Upload Reports&nbsp; <i class="fas fa-plus"></i></button>
                </div>
            </div>
            <!-- Filled Screen Starts-->
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="d-flex align-items-center">
                                <div>
                                    <h4 class="mb-0">My Reports</h4>
                                </div>
                                <div class="ml-auto align-items-center">
                                    <div class="dl d-flex align-items-center">
                                        <div class="btn-group br-0">
                                            <button type="button" class="btn btn-secondary btn-sm br-0" id="latest-btn">Latest</button>
                                            <button type="button" class="btn btn-outline-secondary btn-sm br-0 mr-2" id="archived-btn">Archived</button>
                                            <div class="btn-group br-0" role="group">
                                                <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-secondary dropdown-toggle br-0" data-toggle="dropdown">Select filter</button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0);">Last 7 days</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Last month</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Last 6 months</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div id="latest-data" class="latest">
                                <div class="table-responsive">
                                    <table class="table table-custom spacing5 mb-0 p-0 wrapper">
                                        <tbody>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <div class="d-flex">
                                                        <div class="image"></div>
                                                        <div class="text">
                                                            <div class="text-line"> </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="border-0">
                                                    <div class="text1">
                                                        <div class="text-line1"> </div>
                                                    </div>
                                                </td>
                                                <td class="border-0">
                                                    <div class="text2">
                                                        <div class="text-line2"> </div>
                                                    </div>
                                                </td>
                                                <td class="border-0">
                                                    <div class="text3">
                                                        <div class="text-line3"> </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/pdf.png" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Blood Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/pdf.png" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Diabetes Lab Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/pdf.png" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Blood Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/document.png" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Diabetes Lab Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/pdf.png" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Diabetes Lab Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/pdf.png" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Blood Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/document.png" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Diabetes Lab Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/document.png" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Diabetes Lab Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/pdf.png" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Blood Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div id="archived-data" class="archived">
                                <div class="table-responsive">
                                    <table class="table table-custom spacing5 mb-0 p-0 wrapper">
                                        <tbody>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <div class="d-flex">
                                                        <div class="image"></div>
                                                        <div class="text">
                                                            <div class="text-line"> </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="border-0">
                                                    <div class="text1">
                                                        <div class="text-line1"> </div>
                                                    </div>
                                                </td>
                                                <td class="border-0">
                                                    <div class="text2">
                                                        <div class="text-line2"> </div>
                                                    </div>
                                                </td>
                                                <td class="border-0">
                                                    <div class="text3">
                                                        <div class="text-line3"> </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/pdf.png" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Blood Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/pdf.png" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Blood Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/pdf.png" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Blood Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/document.png" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Blood Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/pdf.png" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Blood Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w60 border-0">
                                                    <img src="assets/images/pdf.png" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" class="w10 rounded mr-3">
                                                    <a href="javascript:void(0);" title="">Blood Report</a>
                                                </td>
                                                <td class="border-0">
                                                    <span>24 Apr, 2020</span>
                                                </td>
                                                <td class="border-0">35KB</td>
                                                <td class="border-0 text-right">
                                                    <button type="button" class="btn btn-outline-secondary br-0">View reports</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Filled Screen Ends-->
            <!-- Empty Screen Starts-->
            <div class="row mt-20">
                <div class="col-xl-12 col-lg-12 col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="d-flex align-items-center">
                                <div>
                                    <h4 class="mb-0">My Reports</h4>
                                </div>
                                <div class="ml-auto align-items-center">
                                    <div class="dl d-flex align-items-center">
                                        <div class="btn-group br-0">
                                            <button type="button" class="btn btn-secondary btn-sm br-0" id="latest-btn">Latest</button>
                                            <button type="button" class="btn btn-outline-secondary btn-sm br-0 mr-2" id="archived-btn">Archived</button>
                                            <div class="btn-group br-0" role="group">
                                                <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-secondary dropdown-toggle br-0" data-toggle="dropdown">Select filter</button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0);">Last 7 days</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Last month</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Last 6 months</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-custom spacing5 mb-0 p-0 wrapper">
                                    <tbody>
                                        <tr>
                                            <td class="w60 border-0">
                                                <div class="d-flex">
                                                    <div class="image"></div>
                                                    <div class="text">
                                                        <div class="text-line"> </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text1">
                                                    <div class="text-line1"> </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text2">
                                                    <div class="text-line2"> </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text3">
                                                    <div class="text-line3"> </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="w60 border-0">
                                                <div class="d-flex">
                                                    <div class="image"></div>
                                                    <div class="text">
                                                        <div class="text-line"> </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text1">
                                                    <div class="text-line1"> </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text2">
                                                    <div class="text-line2"> </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text3">
                                                    <div class="text-line3"> </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="w60 border-0">
                                                <div class="d-flex">
                                                    <div class="image"></div>
                                                    <div class="text">
                                                        <div class="text-line"> </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text1">
                                                    <div class="text-line1"> </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text2">
                                                    <div class="text-line2"> </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text3">
                                                    <div class="text-line3"> </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="w60 border-0">
                                                <div class="d-flex">
                                                    <div class="image"></div>
                                                    <div class="text">
                                                        <div class="text-line"> </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text1">
                                                    <div class="text-line1"> </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text2">
                                                    <div class="text-line2"> </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text3">
                                                    <div class="text-line3"> </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="w60 border-0">
                                                <div class="d-flex">
                                                    <div class="image"></div>
                                                    <div class="text">
                                                        <div class="text-line"> </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text1">
                                                    <div class="text-line1"> </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text2">
                                                    <div class="text-line2"> </div>
                                                </div>
                                            </td>
                                            <td class="border-0">
                                                <div class="text3">
                                                    <div class="text-line3"> </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="empty-report pos-abs">
                            <h4>No Reports Available</h4>
                            <button class="btn theme-btn my-2 br-0 uppercase mb-3" data-toggle="modal" data-target="#uploadreportsModal">Upload Reports <i class="fas fa-plus"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Empty Screen Ends-->
        </div>
    </section>
</div>

