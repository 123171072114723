import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr'
import { GlobalConstants } from '../../app.globalConstants';
import { User } from '../../models/user';

interface Response{
  status:boolean,
  result:any,
  message:string
}

@Injectable()
export class GalleryService {
  public get:Observable<any>;
  public userId;

  constructor(
    private store:Store<User>,
    private http:HttpClient,
    private _toast: ToastrService
  ){
    this.store.select(state=> state['user'])
    .subscribe(data=>{
        this.userId = data.userid;
    });
  }

  getGalleryYear() {
    return this.http.post(GlobalConstants.endPoints.GET_GALLERY_YEAR, {});
  }

  getGalleryEvent(folderId) {
    return this.http.post(GlobalConstants.endPoints.GET_GALLERY_EVENT, { "folderid": folderId });
  }

  getGalleryImages(folderId, eventId) {
    return this.http.post(GlobalConstants.endPoints.GET_GALLERY_IMAGE, { "folderid": folderId, "eventid": eventId });
  }
  
}