import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GalleryService } from '../services/gallery/gallery.service';
declare let $: any;
interface ResponseType{
  result: any,
  status: boolean,
  message: string
}
@Component({
  selector: 'app-gallery-inner',
  templateUrl: './gallery-inner.component.html',
  styleUrls: ['./gallery-inner.component.scss']
})
export class GalleryInnerComponent implements OnInit {

  galleryImagesList = [];
  folderId: string;
  eventId: string;

  constructor(private _galleryService: GalleryService,
  private _route: ActivatedRoute) { }

  ngOnInit(): void {
    this._route.url.subscribe(res => {
      this.folderId = this._route.snapshot.params.folderid;
      this.eventId = this._route.snapshot.params.eventid;
      if (this.folderId) {
        this.getGalleryImages();
      }
    })
  }

  getGalleryImages() {
    this._galleryService.getGalleryImages(this.folderId, this.eventId).subscribe((response: ResponseType) =>{
      if(response.status) {
        this.galleryImagesList = response.result
      }
    })
  }

  openImageModal(image) {
    console.log(image);
    $("#inner-image-src").attr("src", image);
    $("#galleryModal").modal('show');
  }

}
