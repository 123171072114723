import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $("#latest-btn").click(function(){
      $("#latest-data").show();
      $("#archived-data").hide();
    });
    $("#archived-btn").click(function(){
      $("#latest-data").hide();
      $("#archived-data").show();
    });
  }

}
