import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SignaturePadModule } from 'angular2-signaturepad';

// components
import { ModuleLoadComponent } from './module-load/module-load.component'
import { SkeletonComponent } from './skeleton/skeleton.component'
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { StarRating } from './star-rating/star-rating.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';

// Directives
import { FallbackImage } from '../../directive/ImageFallBack.directive';
import { SafeHtmlPipe } from '../../pipes/safeHtml.pipe';
import { TruncateTextPipe } from 'src/pipes/text-truncate.pipe';
import { FormatTimePipe } from 'src/pipes/format-time.pipe';
import { MessageTimePipe } from 'src/pipes/message-time.pipe';
import { ShortTextPipe } from 'src/pipes/short.pipe';
@NgModule({
 imports:[ 
     CommonModule,
     FormsModule,
     SignaturePadModule
    ],
 declarations: [ 
    ModuleLoadComponent,
    SkeletonComponent,
    DeleteConfirmationComponent,
    StarRating,
    InfiniteScrollComponent,
    SignaturePadComponent,

    FallbackImage,
    SafeHtmlPipe,
    TruncateTextPipe,
    FormatTimePipe,
    MessageTimePipe,
    ShortTextPipe
 ],
 exports: [ 
     ModuleLoadComponent,
     SkeletonComponent,
     DeleteConfirmationComponent,
     StarRating,
     InfiniteScrollComponent,
     SignaturePadComponent,
     MessageTimePipe,
     FallbackImage,
    SafeHtmlPipe,
    TruncateTextPipe,
    FormatTimePipe,
    ShortTextPipe
]
})
export class SharedModule { }