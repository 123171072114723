import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { BehaviorSubject } from "rxjs";
import { GlobalConstants } from "src/app/app.globalConstants";
import { catchError, map, take } from "rxjs/operators";


@Injectable()
export class MessageService {

  private currentMessage = new BehaviorSubject(null);
  public readonly currentMessage$ = this.currentMessage.asObservable();

  public messageCount = new BehaviorSubject(0);
  public readonly messageCount$ = this.messageCount.asObservable();

  public notifications = new BehaviorSubject<any[]>([]);
  public readonly notifications$ = this.notifications.asObservable();

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private angularFireAuth: AngularFireAuth,
    private angularFireDB: AngularFireDatabase,
    private http: HttpClient,
  ) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    })
  }

  /**
   *
   * @param userId
   * @param token
   */
  init(userid) {
    this.getMesageCount();
    this.getAllNotifications();
    this.requestPermission(userid);
    this.receiveMessage();
  }

  /**
   * get message count
   */
  getMesageCount() {
    this.http.get(GlobalConstants.endPoints.GET_NOTIFICATION_COUNT).subscribe(
      (response:any) => {
        this.messageCount.next(response.result);
      },
      (err) => {
        console.log("error in message count", err);
      }
    );
  }

  /**
   * get all notifications
   */
  getAllNotifications() {
    this.http.get(GlobalConstants.endPoints.GET_NOTIFICATION).subscribe(
      (response:any) => {
        this.notifications.next(response.result);
      },
      (err) => {
        console.log("error in all notification.", err);
      }
    );
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
   updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }


  /**
   * Mark a message as read
   */
  markAsRead(id) {
    return this.http.put(`${GlobalConstants.endPoints.READ_NOTIFICATION}`,{id}).pipe(map(res => {
      return res;
    }), catchError((err) => {
      return err;
    }))
  }

}
