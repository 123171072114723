import { NotificationService } from "./modules/employee/services/notification.service"
import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Title, Meta } from "@angular/platform-browser"
import { NgwWowService } from "ngx-wow"
import { AuthenticateService } from "./services/authServices/auth.service"
import { MessageService } from "./services/message/message.service"
import { interval } from "rxjs"
declare let $: any
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private wowService: NgwWowService,
    private authService: AuthenticateService,
    private _messageService: MessageService,
    private notificationSvc: NotificationService
  ) {
    this.wowService.init()
  }

  ngOnInit() {
    this.init()
  }

  init() {
    this.authService.checkUserSession()
    const userId =
      this.authService.auth["role"] == "2"
        ? this.authService.auth.userId
        : this.authService.auth.userid
    this.notificationSvc.init(userId)
    console.log(this.authService.auth)
    interval(10000).subscribe((res) => this.notificationSvc.init(userId))
  }
}
