import { environment } from '../environments/environment';
const serverIp = environment.API_URL+'api/';
const hostName = serverIp;

export const GlobalConstants={
    endPoints:{
        LOGIN_USER: `${hostName}authentication/login`,
        CHANGE_PASSWORD:`${hostName}authentication/changePassword`,
        GET_CLIENT_PROFILE: `${hostName}authentication/getProfileUpdate`,
        UPDATE_CLIENT_PROFILE:`${hostName}authentication/profileUpdate`,
        FORGOT_PASSWORD:`${hostName}authentication/forgotPassword`,
        VERIFY_OTP:`${hostName}authentication/matchOTP`,
        RESET_PASSWORD:`${hostName}authentication/resetPassword`,
        GET_COUNTRY : `${hostName}authentication/getCountry`,
        GET_STATE : `${hostName}authentication/getState`,
        GET_CITY : `${hostName}authentication/getCity`,
        UPDATE_USER_IMAGE : `${hostName}authentication/profileImageUpdate`,
        GET_SERVICE_TYPE : `${hostName}general/getServiceType`,
        GET_CLIENT_INVOICES : `${hostName}client/getMyInvoice`,
        // careplans
        GET_CLIENT_CARE_PLANS: `${hostName}client/getMyCarePlan`,
        ADD_CLIENT_AMMENDMENT: `${hostName}client/addRequestAmendment`,
        
        GET_CLIENT_DOCUMENTS: `${hostName}client/getMyDocument`,
        UPDATE_CLIENT_DOCUMENTS: `${hostName}client/updateDocument`,
        DELETE_CLIENT_DOCUMENTS: `${hostName}client/deleteDocument`,
        UPLOAD_CLIENT_DOCUMENT:`${hostName}client/addDocument`,

        GET_CLIENT_SCHEDULES: `${hostName}client/getMyRoster`,
        ADD_CLIENT_ROSTER :`${hostName}addRosterRequestAmmendment`,
        // Appointmens
        GET_CLIENT_APPOINTMENTS: `${hostName}client/getMyAppointment`,
        GET_CLIENT_APPOINTMENT_DETAILS: `${hostName}client/getMyAppointmentDetail`,
        ADD_CLIENT_APPOINTMENTS:`${hostName}client/addAppointment`,
        GET_CLIENT_APPOINTMENT_TIME_SLOT: `${hostName}client/getTimeSlot`,
        EDIT_CLIENT_APPOINTMENT:`${hostName}client/updateAppointment`,
        ADD_AMENDMENT_REQUEST: `${hostName}/client/addRequestAmendment`,

        POST_CLIENT_REVIEW :`${hostName}client/addReview`,
        
        GET_CLIENT_REVIEW_LIST :`${hostName}client/getReviewList`,
        GET_CLIENT_SUPPORTING_STAFF :`${hostName}client/getMySupportStaff`,
        // General health and tips
        GET_HEALTH_NEWS: `${hostName}general/getHealthNews`,
        GET_HEALTH_TIPS:`${hostName}general/getTips`,
        GET_HEALTH_ARTICLE_BY_ID:`${hostName}general/getHealthNewsDetail`,
        GET_HEALTH_TIPS_ARTICLE_BY_ID: `${hostName}general/getTipsDetail`,
        // supporting data
        GET_SUPPORT_DATA:`${hostName}client/getMyTicket`,
        GET_CLIENT_SUPPORT_DEPARTMENT: `${hostName}general/getSupportDepartments`,
        GET_CLIENT_SUPPORT_PRIORITY:`${hostName}general/getTicketPriorities`,
        ADD_CLIENT_SUPPORT_DATA: `${hostName}client/addSupportTicket`,
        GET_CLIENT_SUPPORT_TICKET_DETAILS: `${hostName}client/getMyTicketDetail`,
        ADD_CLIENT_SUPPORT_REPLY: `${hostName}client/addSupportTicketReply`,

        // client service agreement
        GET_CLIENT_SERVICE_AGREEMENTS: `${hostName}client/getMyForm`,
        GET_SUPPORT_FORM_DETAILS:`${hostName}client/getMyFormData`,
        ADD_CLIENT_INTEGRATED_ASSESSMENT_FORM:`${hostName}serviceAgreements/addIntegratedClientAssessment`,
        ADD_CLIENT_AGED_CARE_AGREEMENT:`${hostName}serviceAgreements/addAgedCareServiceAgreement`,
        ADD_NDIS_SUPPLEMENTARY_SERVICE_AGREEMENT:`${hostName}serviceAgreements/addNdisSupplementaryServiceAgreement`,
        ADD_PRIVATE_CLIENTS_SERVICE_AGREEMENT:`${hostName}serviceAgreements/addPrivateClientsServiceAgreement`,
        ADD_NDIS_SERVICE_AGREEMENT:`${hostName}serviceAgreements/addNdisServiceAgreement`,

        // Client available budget
        GET_CLIENT_AVAILABLE_BUDGET: `${hostName}client/getBudgetAmount`,
        
        //HomeSlier
        GET_HEROSLIDES:`${hostName}general/slider`,
        GET_ABOUTDATA:`${hostName}general/homeAbout`,
        GET_YOURLIFE:`${hostName}general/yourLifeYourChoice`,
        GET_TESTIMONIALS:`${hostName}general/testimonials`,

        //About Page
        GET_ABOUT:`${hostName}general/aboutUs`,

        //Gallery 
        GET_GALLERY_YEAR: `${hostName}general/getGalleryYear`,
        GET_GALLERY_EVENT: `${hostName}general/getGalleryEvent`,
        GET_GALLERY_IMAGE: `${hostName}general/getGalleryImages`,

        // message/notification
        GET_NOTIFICATION: `${hostName}client/getNotification`,
        GET_NOTIFICATION_COUNT: `${hostName}client/getNotificationCount`,
        READ_NOTIFICATION: `${hostName}client/readNotification`,
        UPDATE_FCM_TOKEN: `${hostName}client/updateToken`,
        // Contact US
        POST_CONTACT_US: `${hostName}general/contact_us`,
        POST_ADD_TRANSPORT: `${hostName}transportBooking/addTransportBooking`,
        POST_ADD_CAREER: `${hostName}general/addcareer`,
        POST_ADD_CLIENT_REFERRAL: `${hostName}ClientReferral/addClientReferral`,
        POST_FEEDBACK: `${hostName}general/feedback`,
    }
}