import { NotificationService } from "./../modules/employee/services/notification.service"
import { MessageService } from "./../services/message/message.service"
import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { Store } from "@ngrx/store"
import { Observable } from "rxjs"
import { AuthenticateService } from "../services/authServices/auth.service"
import { UserActionTypes } from "../actions/user.actions"
import { map } from "rxjs/operators"
declare let $: any
interface user {
  addresss: String
  email: String
  id: Number
  name: String
  phone: String
  userid: String
  dob?: String
  gender?: String
  image: String
}
@Component({
  selector: "app-header-bar",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [AuthenticateService],
})
export class HeaderComponent implements OnInit {
  messageCount: String
  userMeta: Observable<user>
  headerMenu: Array<{ link: String; menu: string }> = [
    { link: "/client/dashboard", menu: "Dashboard" },
    { link: "/client/care-plans", menu: "My Care Plans" },
    { link: "/client/my-schedule", menu: "My Schedule" },
    { link: "/client/my-service-agreements", menu: "My Service Agreements" },
    { link: "/client/documents", menu: "Documents" },
    { link: "/client/invoices", menu: "Invoices" },
    { link: "/client/support", menu: "Support" },
    { link: "/client/review", menu: "Review" },
    { link: "/client/account", menu: "Account" },
  ]

  empHeaderMenu: Array<{ link: String; menu: string }> = [
    { link: "/employee/employee-dashboard", menu: "Dashboard" },
    { link: "/employee/clients", menu: "Clients" },
    { link: "/employee/schedule", menu: "Roster" },
    { link: "/employee/holiday", menu: "Leave" },
    { link: "/employee/employee-documents", menu: "Documents" },
    { link: "/employee/report-incident", menu: "Report An Incident" },
    { link: "/employee/employee-feedback", menu: "Feedback" },
    { link: "/employee/employee-profile", menu: "Profile" },
  ]

  constructor(
    public router: Router,
    private _store: Store,
    private _authService: AuthenticateService,
    public _messageService: MessageService,
    public _notificationService: NotificationService
  ) {
    this.userMeta = this._store.select((state) => state["user"])
  }

  // Logout button click;
  logout($event) {
    this._authService.logout()
  }

  ndisArray = [
    "/support-coordination",
    "/plan-management",
    "/disability-care",
    "/nursing-care",
    "/dementia-care",
    "/recovery-care",
    "/new-mums",
    "/respite-help",
    "/transport",
    "/autism",
  ]
  ngOnInit(): void {
    $(document).ready(function () {
      var windowHeight = $(window).height()
      var lineHeight = $(".banner-holder .vsat-banner-container").height()
      var desiredBottom = 100
      var newPosition = "0" //windowHeight - (lineHeight + desiredBottom);
      $(".banner-holder .vsat-banner-container").animate(
        { top: newPosition },
        2000,
        function () {
          $(".banner-holder .vsat-banner-container").css({
            //  bottom: desiredBottom,
            //top: 'auto'
            position: "relative",
          })
          complete: callbacks()
        }
      )

      function callbacks() {
        $(".header").css({ top: "0", position: "relative" })
      }

      $(".cross").click(function () {
        $(".banner-holder").hide()
        $(".header").css({ top: 46, position: "absolute" })
      })
    })
    if (!document.getElementById("stick-me")) {
    } else {
      var top = document.getElementById("stick-me").offsetTop
      $(window).scroll(function () {
        $("#stick-me").toggleClass("sticky", $(document).scrollTop() > top)
      })
    }
  }

  readAndRedirect(notificattion) {
    // this._messageService.markAsRead(notificattion.id).pipe(map(res=> {
    //   if(res) {
    notificattion.read_status = 1
    this._messageService.markAsRead(notificattion.id).subscribe((res) => {})
    console.log(notificattion.read_status)
    this._messageService.getMesageCount()
    this.router.navigateByUrl("/client/notifications")
    //   }
    // }))
  }

  readNotifications(notification) {
    this._notificationService.markAsRead(notification.id).subscribe((res) => {
      this._notificationService.redirectNotification(notification)
    })

  }
}
