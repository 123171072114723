import { Action } from '@ngrx/store';
import { ClientActionTypes } from '../actions/client.actions';

const defaultState={
    clientProfile:{
        userid:'',
        firstname:'',
        lastname:'',
        phonenumber:'',
        alternative_mobile:'',
        profile_image:'',
        dob:'',
        address:'',
        state:'',
        city:'',
        country:'',
        postal_code:'',
        address2:'',
        state2:'',
        city2:'',
        country2:'',
        postal_code2:'',
        service_type:'',
        office_location:'',
        caring_plan:'',
    },
    invoices:[],
    invoiceCount: 0, 
    carePlans:[],
    carePlanCount:0,
    documents:[],
    schedules:[],
    appointments:[],
    supporting_staffs:[],
    supportData:[],
    supportsDataCount:0,
    supportStatus:{},
    actionCompleted: false,
    isLoading: false,
    documentDelete: false,
    documentCount:0,
    documentLoading:false,
    priorities:[],
    departments:[],
    serviceAgreements:[],
    availableBudget:{}
} 
export function ClientUserReducer(state = defaultState, action:Action){
	switch (action.type) {
        case ClientActionTypes.GET_CLIENT_PROFILE:
            return Object.assign({}, state, {clientProfile: action['payload']});
        case ClientActionTypes.CLIENT_ACTION_BEGIN:
            return Object.assign({}, state, {actionCompleted: false})
        case ClientActionTypes.UPDATE_CLIENT_PROFILE_SUCCESS:
            return Object.assign({}, state, {clientProfile: Object.assign({}, state.clientProfile, action['payload']), actionCompleted: true})
        case ClientActionTypes.UPDATE_USER_IMAGE:
            return Object.assign({}, state, {clientProfile: Object.assign({}, state.clientProfile, action['payload'])})
        case ClientActionTypes.GET_CLIENT_INVOICES_SUCCESS:
            return Object.assign({}, state,{
                invoices : [...state.invoices, ...action['payload']['result']],
                invoiceCount: action['payload']['count']
            });

        case ClientActionTypes.GET_CLIENT_INVOICES_BEGINS:
            return Object.assign({}, state,{invoices : []});
        case ClientActionTypes.GET_CLIENT_INVOICES_FAILURE:
            return Object.assign({}, state,{invoices : []});

        case ClientActionTypes.GET_CLIENT_CARE_PLANS_BEGINS:
            return Object.assign({}, state, {carePlans: []});
        case ClientActionTypes.GET_CLIENT_CARE_PLANS_SUCCESS:
            return Object.assign({}, state, {
                carePlans: [...state.carePlans, ...action['payload']['result']],
                carePlanCount: action['payload']['count']
            });
        case ClientActionTypes.GET_CLIENT_CARE_PLANS_FAILURE:
            return Object.assign({}, state, {carePlans: []});

        case ClientActionTypes.GET_CLIENT_DOCUMENTS_BEGINS:
            return Object.assign({}, state, {documents:[]});

        case ClientActionTypes.GET_CLIENT_DOCUMENTS_SUCCESS:
            return Object.assign({}, state, { 
                documents: [...state.documents, ...action['payload']['result']],
                documentCount: action['payload']['count'],
                documentLoading: false
            });
        case ClientActionTypes.GET_CLIENT_DOCUMENTS_FAILURE:
            return Object.assign({}, state, { documents: [], documentLoading: false});
            
        case ClientActionTypes.DELETE_CLIENT_DOCUMENT_BEGINS:
            return Object.assign({}, state, {documentDelete: false})
        case ClientActionTypes.DELETE_CLIENT_DOCUMENT_SUCCESS:
            const documents = state.documents.filter(document => (document.id != action['payload']))
            return Object.assign({}, state, {documentDelete: true, documents})
        case ClientActionTypes.UPDATE_CLIENT_DOCUMENT_SUCCESS:
            const _documents = [...state.documents];
            let docIndex = -1;
            _documents.forEach((docs, index) =>{
                if(docs.id == action['payload']['id']){
                    docIndex = index
                }
            })
            if(docIndex > -1){
                _documents[docIndex] = action['payload']
            }
            return Object.assign({}, state, {documents:_documents})

        case ClientActionTypes.ADD_CLIENT_DOCUMENT_SUCCESS:
            return Object.assign({}, state, { documents: [...state.documents, action['payload']]})
        case ClientActionTypes.DELETE_CLIENT_DOCUMENT_FAILURE:
            return Object.assign({}, state, {isLoading: false})    

        case ClientActionTypes.GET_CLIENT_SCHEDULES_SUCCESS:
            return Object.assign({}, state, {schedules: action['payload']});
        case ClientActionTypes.GET_CLIENT_SCHEDULES_FAILURE:
            return Object.assign({}, state, {schedules:[]});
        case ClientActionTypes.GET_CLIENT_APPOINTMENTS_SUCCESS:
            return Object.assign({}, state, { appointments : action['payload']});
        case ClientActionTypes.GET_CLIENT_APPOINTMENTS_FAILURE:
            return Object.assign({}, state, { appointments : []});
        case ClientActionTypes.UPDATE_CLIENT_APPOINTMENT:
            let appointments = [...state.appointments]
            let _index = -1;
            appointments.forEach((appointment, index)=>{
                if(appointment.id == action['payload']['id']){
                    _index = index
                }
            })
            if(_index != -1){
                appointments[_index] = action['payload']
            }
            return Object.assign({}, state, {appointments})

        case ClientActionTypes.GET_CLIENT_SUPPORTING_STAFF_SUCCESS:
            return Object.assign({}, state, {supporting_staffs:action['payload']})
        case ClientActionTypes.GET_CLIENT_SUPPORTING_STAFF_FAILURE:
            return Object.assign({}, state, {supporting_staffs:[]});
        case ClientActionTypes.ADD_CLIENT_APPOINTMENT_BEGIN:
            return Object.assign({}, state,{isLoading: true})
        case ClientActionTypes.ADD_CLIENT_APPOINTMENT_SUCCESS:
            const newAppointment = {...action['payload']};
            newAppointment['created_date'] = new Date().toISOString();
            const _appointments = [...state.appointments, newAppointment];
            return Object.assign({}, state,{isLoading: false, appointments: _appointments})
        case ClientActionTypes.ADD_CLIENT_APPOINTMENT_FAILURE:
            return Object.assign({}, state,{isLoading: false})
        
        case ClientActionTypes.GET_CLIENT_SUPPORT_DETAILS_BEGIN:
            return Object.assign({}, state, {supportData:[], supportsDataCount:0})
        case ClientActionTypes.GET_CLIENT_SUPPORT_DETAILS_SUCCESS:
            return Object.assign({}, state, 
                { 
                    supportData:[...state.supportData, ...action['payload']['result']], 
                    supportsDataCount: action['payload']['count'], 
                    supportStatus: action['payload']['status']
                })
        case ClientActionTypes.GET_INITIAL_STATUS_FILTER:
            return Object.assign({}, state, 
                { 
                    supportData:[], 
                
                })
        case ClientActionTypes.GET_CLIENT_SUPPORT_DETAILS_FAILURE:
            return Object.assign({}, state)

        case ClientActionTypes.GET_CLIENT_SERVICE_AGREEMENT_SUCCESS:
            return Object.assign({}, state, { serviceAgreements:action['payload'] } )
        case ClientActionTypes.GET_CLIENT_SERVICE_AGREEMENT_FAILED:
            return Object.assign({}, state );
        case ClientActionTypes.GET_CLIENT_AVAILABLE_BUDGET_SUCCESS:
            return Object.assign({}, state,{ availableBudget: action['payload']})
        case ClientActionTypes.GET_CLIENT_AVAILABLE_BUDGET_FAILURE:
            return Object.assign({}, state,{ availableBudget: {}})
        default:
			return state;

	}
}
