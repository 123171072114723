import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: "messageTime"
})
export class MessageTimePipe implements PipeTransform {
    transform(date: string): string {
        return moment(date).fromNow();
    }
}