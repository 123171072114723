import { Component, OnInit } from '@angular/core';
import { GalleryService } from '../services/gallery/gallery.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  providers: [GalleryService]
})
export class GalleryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

}
