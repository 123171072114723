<section class="accomodation-section">  
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Aged Care</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">Aged Care</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="accomodation-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <div class="img-wrap float-left">
                                <img src="assets/images/aged-care.jpg" class="img-fluid">
                            </div>
                            <div class="content float-right">
                                <h3>Aged Care</h3>
                                <h4 class="mt-15">At Caring Approach we offer a range of services to suit our client's needs</h4>
                                <hr>
                                <p>Whatever your situation we understand that you are an individual with an unique lifestyle. This is why we work closely with you to ensure that our services give you the freedom to live the life you choose. We have a range of services from high-level personal care to domestic assistance or community outings. Each care plan is carefully tailored to the individual needs of each client; it's your life – your choice.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card border-0 mb-50">
                        <div class="card-body text-center">
                            <h4 class="mt-15">Tell us how we can support you for a fully tailored service !</h4>
                            <h4><a href="tel:+1300655026"><strong>Call 1300 655 026</strong></a></h4>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-md-12 col-12">
                    <h3 class="text-center mb-15">Home Care <strong>Package level</strong></h3>
                </div>
                <div class="col-md-12 col-12">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <tr>
                                <th width="20%"></th>
                                <th width="20%">Basic</th>
                                <th width="20%">Low Level</th>
                                <th width="20%">Intermediate</th>
                                <th width="20%">High Level</th>
                            </tr>
                            <tr>
                                <td colspan="5" class="plan">Maximum Home Care Package hours per week</td>
                            </tr>
                            <tr>
                                <th>Estimation of Maximum hours*</th>
                                <td>2</td>
                                <td>4</td>
                                <td>8</td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td colspan="5" class="plan">Maximum Home Care Package hours per week <br> (with dementia supplement)</td>
                            </tr>
                            <tr>
                                <th>Estimation of Maximum hours*</th>
                                <td>3</td>
                                <td>4.5</td>
                                <td>9</td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <td colspan="5" class="plan">Maximum Home Care Package hours per week offered at no extra charge <br>  (with dementia supplement and up to 2 hours care with registered nurse)</td>
                            </tr>
                            <tr>
                                <th>Estimation of Maximum hours*</th>
                                <td>5</td>
                                <td>6.5</td>
                                <td>11</td>
                                <td>15</td>
                            </tr>
                        </table>
                    </div>
                </div>-->
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4 class="mb-15">My Aged Care Price Guide</h4>
                            <a href="assets/pdf/Aged Care Price Guide 2024.pdf" class="btn theme-btn" target="_blank">Click Here</a>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    <!-- </div> -->
</section>



