<section class="hero-bg news">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12 p-0 ">
                <app-hero-slider></app-hero-slider>
            </div>
        </div>
    </div>
</section>
<div class="content-wrapper">
    <section class="about-section">
        <div class="container" >
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12 text-center">
                    <h2 class="title wow fadeInUp" data-wow-delay="0.5s" animation-duration="2s">{{aboutData[0].title}}</h2>
                    <p class="text">{{aboutData[0].description}}</p>
                </div>
            </div>
            <div class="row mt-70">
                <div class="col-xl-4 col-lg-4 col-12 text-center">
                    <h5 class="sub-title mt-100">{{aboutData[1].title}}</h5>
                    <p class="content">{{aboutData[1].description}}</p>
                    <div class="img-wrap text-right">
                        <img src="assets/images/about/system.svg" class="img-fluid"> 
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12 text-center">
                    <h5 class="sub-title">{{aboutData[2].title}}</h5>
                    <p class="content">{{aboutData[2].description}}</p>
                    <div class="img-wrap text-center">
                        <img src="assets/images/about/check.png" class="img-fluid">
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12 text-center">
                    <h5 class="sub-title mt-100">{{aboutData[3].title}}</h5>
                    <p class="content">{{aboutData[3].description}}</p>
                    <div class="img-wrap text-left">
                        <img src="assets/images/about/shield.svg" class="img-fluid">
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-12 text-center">
                    <div class="img-wrap text-center d-none d-sm-none d-md-none d-lg-block d-xl-block pos-abs w-100">
                        <img src="assets/images/about/about-arc.png" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="row mt-sm-50 mt-200">
                <div class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-12 text-center">
                    <h2 class="tag-title">{{aboutData[4].title}}</h2>
                    <a href="javascript:void(0);" routerLink="/contact-us" tabindex="0" class="btn btn-primary theme-btn">CONTACT NOW</a>
                </div>
            </div>
        </div>
    </section>
    <section class="services-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12 text-center">
                    <h2 class="title">Our Services</h2>
                    <p class="text">Get well sooner with Caring Approach health care solutions</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12">
                    <div class='content'>
                        <div class='slider single-item'>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content mb-35">
                                        <img src="assets/images/services/our-services.png" class="img-fluid">
                                    </div>
                                    <a href="javascript:void(0);" routerLink="/disability-care">
                                        <span>Disability Care</span>
                                    </a>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content mb-35">
                                        <img src="assets/images/nursing.png" class="img-fluid">
                                    </div>
                                    <a href="javascript:void(0);" routerLink="/nursing-care"><span>Nursing Care</span></a>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content mb-35">
                                        <img src="assets/images/dementia.png" class="img-fluid">
                                    </div>
                                    <a href="javascript:void(0);" routerLink="/dementia-care"><span>Dementia Care</span></a>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content mb-35">
                                        <img src="assets/images/recovery.png" class="img-fluid">
                                    </div>
                                    <a href="javascript:void(0);" routerLink="/recovery-care"><span>Recovery Care</span></a>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content mb-35">
                                        <img src="assets/images/transport.png" class="img-fluid">
                                    </div>
                                    <a href="javascript:void(0);" routerLink="/transport"><span>Transport</span></a>
                                    <br><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="our-feature-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12 text-center">
                    <h2 class="title">{{yourlifeData[0].title}}</h2>
                    <p class="sub-title">{{yourlifeData[0].description}}</p>
                </div>
            </div>
            <div class="row mt-70">
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="feature-item text-center">
                        <h3>{{yourlifeData[1].title}}</h3>
                        <p>{{yourlifeData[1].description}}</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12 mt-md-20">
                    <div class="feature-item text-center">
                        <h3>{{yourlifeData[2].title}}</h3>
                        <p>{{yourlifeData[2].description}}</p>                  
                      </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="feature-item text-center">
                        <h3>{{yourlifeData[3].title}}</h3>
                     <p><a href="mailto:admin@caringapproach.com.au" class="text-white">{{yourlifeData[3].description}}</a></p>  
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-12 mt-70">
                    <div class="feature-item text-center">
                        <h3> Telephone: <a class="text-white" href="tel:+1300655026">1300 655 026</a>, <a class="text-white" href="tel:+0431744321">0431 744 321</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="testimonial-section">
        <div class="container">
            <div class="row">
                <div class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-12 text-center">
                    <h2 class="title">Client Testimonials</h2>
                    <p class="text">What our clients have to say about Caring Approach</p>
                </div>
            </div>
            <div class="row mt-20">
                <!-- Testimonial -->
                <div class="col-xl-4 col-lg-4 col-12" *ngFor="let testimonials of testimonialsData">
                    <div class="box">
                        <!-- Testimonial Image -->
                        <div class="video-wrap mr-5">
                            <video controls="controls" style="width: 100%; height: 100%;" poster="assets/images/testi-poster1.png">
                                <source [src]='"https://caringapproach.com.au/superadmin/"+testimonials.video' type="video/mp4"> Your browser does not support the video tag. 
                            </video>
                        </div>
                        <!-- / Testimonial Image -->
                        <div class="test-component">
                            <!-- Title -->
                            <article class="test-title">
                                <h4>
                                   {{testimonials.client_name}}
                                </h4>
                            </article>
                            <!-- / Title -->
                            <article class="test-content">
                                <p>
                                   {{testimonials.description}}.... <a href="javascript:void(0);" routerLink="/testimonials"><small>Read more >></small></a>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- / Testimonial -->
         
            </div>
        </div>
    </section>
    <section class="blog-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12 text-center">
                    <h2 class="title">Top Pick Articles</h2>
                    <p class="text">We explore, we research, and we share valuable information and tech updates -abiding by the industry leading software practices -via our blogs penned by our industry experts and sometimes you.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl4 col-lg-4 col-12">
                    <div class="blog-post">
                        <div class="media-materials">
                            <img alt="" src="assets/images/blog/blog1.png" class="img-fluid">  
                        </div>
                        <div class="post-content">
                            <div class="post-title-container">
                                <h3><a href="https://www.ndis.gov.au/participants/independent-assessments/independent-assessment-q-and" target="_blank"> Independent assessment Q and A </a></h3>
                            </div>
                            <div class="post-content-blog">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <h5>16 Dec, 2020</h5>
                                    </div>
                                    <div class="ml-auto align-items-center">
                                        <div class="dl">
                                            <a href="https://www.ndis.gov.au/participants/independent-assessments/independent-assessment-q-and" target="_blank" class="card-link read">Read Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="blog-post">
                        <div class="media-materials">
                            <img alt="" src="assets/images/blog/blog.png" class="img-fluid">  
                        </div>
                        <div class="post-content">
                            <div class="post-title-container">
                                <h3><a href="https://www.myagedcare.gov.au/news-and-updates/disability-gateway-now-live" target="_blank" > Disability Gateway now live </a></h3>
                            </div>
                            <div class="post-content-blog">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <h5>10 Feb, 2021</h5>
                                    </div>
                                    <div class="ml-auto align-items-center">
                                        <div class="dl">
                                            <a href="https://www.myagedcare.gov.au/news-and-updates/disability-gateway-now-live" target="_blank" class="card-link read">Read Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="blog-post">
                        <div class="media-materials">
                            <img alt="" src="assets/images/blog/blog2.png" class="img-fluid">  
                        </div>
                        <div class="post-content">
                            <div class="post-title-container">
                                <h3><a href="https://www.health.gov.au/ministers/the-hon-greg-hunt-mp/media/additional-covid-19-mental-health-support" target="_blank"> Additional covid-19 mental health support </a></h3>
                            </div>
                            <div class="post-content-blog">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <h5>02 Aug, 2020</h5>
                                    </div>
                                    <div class="ml-auto align-items-center">
                                        <div class="dl">
                                            <a href="https://www.health.gov.au/ministers/the-hon-greg-hunt-mp/media/additional-covid-19-mental-health-support"  target="_blank" class="card-link read">Read Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="col-xl-12 col-lg-12 col-12 text-center mt-30">
                    <a href="javascript:void(0);" class="btn btn-primary theme-btn" tabindex="0">VIEW ALL</a>
                </div>-->
            </div>
        </div>
    </section>
</div>

