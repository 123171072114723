import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-accomodation',
  templateUrl: './accomodation.component.html',
  styleUrls: ['./accomodation.component.scss']
})
export class AccomodationComponent implements OnInit {

  accomodationSlides = [
    {img: 'assets/images/accomodation/accomodation22.png'},
    {img: 'assets/images/accomodation/accomodation11.png'}
 ];
 accomodationSliderConfig = {
      dots: false,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: true,
      vertical: false,
      centerPadding: '10px',
      slidesToShow: 1,
      slidesToScroll: 1,
      verticalSwiping: false,
      prevArrow: '',
      nextArrow: '',
      responsive: [
        {
          breakpoint: 812,
          settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: '',
          nextArrow: '',
          }
        }
      ]
  };

  constructor() { }

  ngOnInit(): void {
  }
  decreasefontsize(){
    $('.inner-wrap h1').css('font-size', '-=1');
    $('.inner-wrap h2').css('font-size', '-=1');
    $('.inner-wrap h3').css('font-size', '-=1');
    $('.inner-wrap h4').css('font-size', '-=1');
    $('.inner-wrap h5').css('font-size', '-=1');
    $('.inner-wrap h6').css('font-size', '-=1');
    $('.inner-wrap p').css('font-size', '-=1');
    $('.inner-wrap a').css('font-size', '-=1');
    $('.inner-wrap li').css('font-size', '-=1');
    $('.inner-wrap span').css('font-size', '-=1');
  }
  increasefontsize(){
    $('.inner-wrap p').css('font-size', '+=2');
    $('.inner-wrap h1').css('font-size', '+=2');
    $('.inner-wrap h2').css('font-size', '+=2');
    $('.inner-wrap h3').css('font-size', '+=2');
    $('.inner-wrap h4').css('font-size', '+=2');
    $('.inner-wrap h5').css('font-size', '+=2');
    $('.inner-wrap h6').css('font-size', '+=2');
    $('.inner-wrap a').css('font-size', '+=2');
    $('.inner-wrap li').css('font-size', '+=2');
    $('.inner-wrap span').css('font-size', '+=2');
  }
}
