export const ClientActionTypes = {
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  GET_CLIENT_PROFILE:'GET_CLIENT_PROFILE',
  UPDATE_CLIENT_PROFILE_SUCCESS:'UPDATE_CLIENT_PROFILE_SUCCESS',
  CLIENT_ACTION_BEGIN:'CLIENT_ACTION_BEGIN',
  UPDATE_USER_IMAGE:'UPDATE_USER_IMAGE',

  GET_CLIENT_INVOICES_BEGINS:'GET_CLIENT_INVOICES_BEGINS',
  GET_CLIENT_INVOICES_SUCCESS:'GET_CLIENT_INVOICES_SUCCESS',
  GET_CLIENT_INVOICES_FAILURE:'GET_CLIENT_INVOICES_FAILURE',

  GET_CLIENT_CARE_PLANS_BEGINS:'GET_CLIENT_CARE_PLANS_BEGINS',
  GET_CLIENT_CARE_PLANS_SUCCESS:'GET_CLIENT_CARE_PLANS_SUCCESS',
  GET_CLIENT_CARE_PLANS_FAILURE:'GET_CLIENT_CARE_PLANS_FAILURE',

  GET_CLIENT_DOCUMENTS_BEGINS:'GET_CLIENT_DOCUMENTS_BEGINS',
  GET_CLIENT_DOCUMENTS_SUCCESS:'GET_CLIENT_DOCUMENTS_SUCCESS',
  GET_CLIENT_DOCUMENTS_FAILURE:'GET_CLIENT_DOCUMENTS_FAILURE',
  ADD_CLIENT_DOCUMENT_SUCCESS:'ADD_CLIENT_DOCUMENT_SUCCESS',
  DELETE_CLIENT_DOCUMENT_BEGINS : 'DELETE_CLIENT_DOCUMENT_BEGINS',
  DELETE_CLIENT_DOCUMENT_SUCCESS : 'DELETE_CLIENT_DOCUMENT_SUCCESS',
  DELETE_CLIENT_DOCUMENT_FAILURE : 'DELETE_CLIENT_DOCUMENT_FAILURE',
  UPDATE_CLIENT_DOCUMENT_SUCCESS:'UPDATE_CLIENT_DOCUMENT_SUCCESS',

  GET_CLIENT_SCHEDULES_SUCCESS:'GET_CLIENT_SCHEDULES_SUCCESS',
  GET_CLIENT_SCHEDULES_FAILURE:'GET_CLIENT_SCHEDULES_FAILURE',
  GET_CLIENT_APPOINTMENTS_SUCCESS:'GET_CLIENT_APPOINTMENTS_SUCCESS',
  GET_CLIENT_APPOINTMENTS_FAILURE:'GET_CLIENT_APPOINTMENTS_FAILURE',
  GET_CLIENT_SUPPORTING_STAFF_SUCCESS:'GET_CLIENT_SUPPORTING_STAFF_SUCCESS',
  GET_CLIENT_SUPPORTING_STAFF_FAILURE:'GET_CLIENT_SUPPORTING_STAFF_FAILURE',

  ADD_CLIENT_APPOINTMENT_BEGIN:'ADD_CLIENT_APPOINTMENT_BEGIN',
  ADD_CLIENT_APPOINTMENT_SUCCESS:'ADD_CLIENT_APPOINTMENT_SUCCESS',
  ADD_CLIENT_APPOINTMENT_FAILURE:'ADD_CLIENT_APPOINTMENT_FAILURE',

  UPDATE_CLIENT_APPOINTMENT:'UPDATE_CLIENT_APPOINTMENT',

  GET_CLIENT_SUPPORT_DETAILS_BEGIN:'GET_CLIENT_SUPPORT_DETAILS_BEGIN',
  GET_CLIENT_SUPPORT_DETAILS_SUCCESS:'GET_CLIENT_SUPPORT_DETAILS_SUCCESS',
  GET_CLIENT_SUPPORT_DETAILS_FAILURE:'GET_CLIENT_SUPPORT_DETAILS_FAILURE',
  GET_INITIAL_STATUS_FILTER:'GET_INITIAL_STATUS_FILTER',

  GET_CLIENT_SERVICE_AGREEMENT_SUCCESS : 'GET_CLIENT_SERVICE_AGREEMENT_SUCCESS',
  GET_CLIENT_SERVICE_AGREEMENT_FAILED : 'GET_CLIENT_SERVICE_AGREEMENT_FAILED',

  GET_CLIENT_AVAILABLE_BUDGET_SUCCESS: 'GET_CLIENT_AVAILABLE_BUDGET_SUCCESS',
  GET_CLIENT_AVAILABLE_BUDGET_FAILURE: 'GET_CLIENT_AVAILABLE_BUDGET_FAILURE'
};