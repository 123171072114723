<section class="fxt-template-animation fxt-template-layout18">      
    <div class="fxt-content">
        <div class="fxt-form"> 
            <p>Create account</p>
            <hr>
            <form method="POST">
                <div class="row">
                    <div class="form-group col-md-6"> 
                        <div class="fxt-transformY-50 fxt-transition-delay-1">    
                            <label for="f-name">First name</label>                                             
                            <input type="text" id="f-name" class="form-control" name="name" equired="required">
                        </div>
                    </div>
                    <div class="form-group col-md-6">  
                        <div class="fxt-transformY-50 fxt-transition-delay-2">    
                            <label for="l-name">Last name</label>                                             
                            <input type="text" id="l-name" class="form-control" name="name" required="required">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6"> 
                        <div class="fxt-transformY-50 fxt-transition-delay-1">   
                            <label for="email">Email address<sup class="required" title="required">*</sup></label>                                                
                            <input type="email" id="email" class="form-control" name="email" required="required">
                        </div>
                    </div>
                    <div class="form-group col-md-6">  
                        <div class="fxt-transformY-50 fxt-transition-delay-2">    
                            <label for="username">Username<sup class="required" title="required">*</sup></label>                                             
                            <input type="text" id="username" class="form-control" name="username" required="required">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12"> 
                        <div class="fxt-transformY-50 fxt-transition-delay-1">  
                            <label for="c-name">Company name</label>                                               
                            <input type="text" id="c-name" class="form-control" name="c-name" required="required">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12">  
                        <div class="fxt-transformY-50 fxt-transition-delay-2">      
                            <label for="address">Company address</label>                                           
                            <input type="text" id="address" class="form-control" name="address" required="required">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-4"> 
                        <div class="fxt-transformY-50 fxt-transition-delay-1"> 
                            <label for="email">Country</label>   
                            <select id="country" class="form-control" name="country" required="required">
                                <option>Select country</option>
                                <option>Country 1</option>
                                <option>Country 2</option>
                                <option>Country 3</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group col-md-4">  
                        <div class="fxt-transformY-50 fxt-transition-delay-2">  
                            <label for="email">State</label>                                               
                            <select id="state" class="form-control" name="state" required="required">
                                <option>Select state</option>
                                <option>State 1</option>
                                <option>State 2</option>
                                <option>State 3</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group col-md-4">  
                        <div class="fxt-transformY-50 fxt-transition-delay-2"> 
                            <label for="email">City</label>                                                
                            <select id="city" class="form-control" name="city" required="required">
                                <option>Select city</option>
                                <option>City 1</option>
                                <option>City 2</option>
                                <option>City 3</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6"> 
                        <div class="fxt-transformY-50 fxt-transition-delay-1">    
                            <label for="zipcode">Zipcode</label>                                             
                            <input type="text" id="zipcode" class="form-control" name="zipcode" required="required">
                        </div>
                    </div>
                    <div class="form-group col-md-6">  
                        <div class="fxt-transformY-50 fxt-transition-delay-2">   
                            <label for="telephone">Contact number</label>                                              
                            <input type="text" id="telephone" class="form-control" name="telephone" required="required">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">  
                        <div class="fxt-transformY-50 fxt-transition-delay-2">   
                            <label for="password">Password<sup class="required" title="required">*</sup></label>                                              
                            <input id="password" type="password" class="form-control" name="password" required="required">
                            <i toggle="#password" class="fa fa-fw fa-eye toggle-password field-icon"></i>
                        </div>
                    </div>
                    <div class="form-group col-md-6">  
                        <div class="fxt-transformY-50 fxt-transition-delay-2"> 
                            <label for="c-password">Confirm password<sup class="required" title="required">*</sup></label>                                                
                            <input id="c-password" type="password" class="form-control" name="c-password" required="required">
                            <i toggle="#password" class="fa fa-fw fa-eye toggle-password1 field-icon"></i>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12">
                        <div class="fxt-transformY-50 fxt-transition-delay-4">  
                            <button type="submit" class="form-btn">Register</button>
                        </div>
                    </div>
                </div>
            </form>                
        </div> 
        <div class="fxt-footer">
            <div class="fxt-transformY-50 fxt-transition-delay-9">  
                <p>Already have an account? <a href="javascript:void(0);" routerLink="/signin" class="switcher-text2">Signin</a></p>
            </div> 
        </div> 
    </div>
</section>    