import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { GlobalConstants } from '../app.globalConstants'; 

@Injectable({
  providedIn: 'root'
})
export class CareerService {

  constructor(
    private http: HttpClient,
  ) { }
  careerData(data){
    const formData = new FormData();
    Object.keys(data).forEach(form =>{
      if(form == 'other_document'){
        for(let i=0; i < data[form].length; i++){
          formData.append('other_document[]', data[form][i])
        }
      }else{
        formData.append(form, data[form])
      }
    })
    return this.http.post(GlobalConstants.endPoints.POST_ADD_CAREER, formData);  
  }
}
