import { Action, ActionReducer } from '@ngrx/store';
import { User } from '../models/user';
import { UserActionTypes } from '../actions/user.actions';

export function UserReducer(state = false,action:Action){
	switch (action.type) {
		case UserActionTypes.LOGIN_SUCCESS:
			// window[window['storageOption']].setItem('auth',JSON.stringify(action['payload']));
			return Object.assign({},state,action['payload']);

		case UserActionTypes.LOGIN_FAILURE:
			return state;
		case UserActionTypes.LOGOUT:
			// window.localStorage.removeItem('remember');
			return false;
		case UserActionTypes.UPDATE_USER:
			return Object.assign({},state,action['payload']);

		case UserActionTypes.UPDATE_USER_META:
			return Object.assign({}, state, action['payload'])

		case UserActionTypes.UPDATE_USER_IMAGE:
			return Object.assign({}, state, {profile_image: action['payload']})
		default:
			return state;

	}
}
