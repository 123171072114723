<div class="gallery-wrap">
  <div class="container">
      <div class="row">
        <div class="col-md-12 col-12">
            <div class="d-flex align-items-center mb-20">
                <div><h3>Gallery</h3></div>
                <div class="ml-auto align-items-center">
                    <div class="dl">
                        <a href="javascript:void(0);" [routerLink]="['/gallery/year']">Back</a>
                    </div>
                </div>
            </div>
        </div>
          <div class="col-md-3 col-lg-2 col-12" *ngFor="let event of galleryEventList">
            <div class="card" [routerLink]="['/gallery/gallery-inner', folderId, event?.eventid]">
                <div class="card-body text-center image_wrap">
                  <div class="image">
                    <img [src]="event?.event_image" alt="event?.name" onerror="this.onerror=null;this.src='assets/images/no_image.jpg'">
                  </div>
                  <div class="text">
                      <span>{{event?.name}}</span>
                  </div>
                </div>
          </div>
      </div>
  </div>
</div>