import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr'
import { FeedbackService } from './feedback.service';
declare let $: any;
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  constructor(
    private FeedbackService: FeedbackService,
    private route: ActivatedRoute,
    private router: Router,
    private toast:ToastrService
  ) { }

  public FeedbackForm: FormGroup;
  isLoading:boolean = false;
  submitted = false;
  returnUrl: string;
  ngOnInit(): void {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.FeedbackForm = new FormGroup({
      firstname: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]+')]),
      lastname: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]+')]),
      email: new FormControl('', [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])(\.[a-z0-9]([a-z0-9-]*[a-z0-9]))*$/i)]),
      message: new FormControl('', [Validators.required]),
      phonenumber:new FormControl('', [Validators.required,  Validators.pattern('[- +()0-9]{9,12}')])
    })
   } 

   feedbackSubmit() {
    
      if (this.FeedbackForm.invalid) {
          this.submitted = true;
          return;
      }else{
        this.submitted = false;
        this.isLoading = true;
        this.FeedbackService.addFeedbackData(this.FeedbackForm.value)
        .subscribe(
          (response) => {
            this.isLoading = false;
            this.toast.success('Thanks for contacting Caring Approach, Our team will get back to you soon.')
            this.FeedbackForm.reset();
         }, err => {
      
        })
      }
  }  

  decreasefontsize(){
    $('.inner-wrap h1').css('font-size', '-=1');
    $('.inner-wrap h2').css('font-size', '-=1');
    $('.inner-wrap h3').css('font-size', '-=1');
    $('.inner-wrap h4').css('font-size', '-=1');
    $('.inner-wrap h5').css('font-size', '-=1');
    $('.inner-wrap h6').css('font-size', '-=1');
    $('.inner-wrap p').css('font-size', '-=1');
    $('.inner-wrap a').css('font-size', '-=1');
    $('.inner-wrap li').css('font-size', '-=1');
    $('.inner-wrap span').css('font-size', '-=1');
    $('.inner-wrap form').css('font-size', '-=1');
  }
  increasefontsize(){
    $('.inner-wrap p').css('font-size', '+=2');
    $('.inner-wrap h1').css('font-size', '+=2');
    $('.inner-wrap h2').css('font-size', '+=2');
    $('.inner-wrap h3').css('font-size', '+=2');
    $('.inner-wrap h4').css('font-size', '+=2');
    $('.inner-wrap h5').css('font-size', '+=2');
    $('.inner-wrap h6').css('font-size', '+=2');
    $('.inner-wrap a').css('font-size', '+=2');
    $('.inner-wrap li').css('font-size', '+=2');
    $('.inner-wrap span').css('font-size', '+=2');
    $('.inner-wrap form').css('font-size', '+=2');
  }
}
