<!-- Signature Modal -->
<div class="modal fade" [id]="id" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Signature</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()"
                    (onEndEvent)="drawComplete()">
                </signature-pad>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-danger" (click)="clear()">Clear</button>
                <button type="button" class="btn btn-primary" (click)="updateSignature()">Add Signature</button>
            </div>
        </div>
    </div>
</div>