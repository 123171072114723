import { MessageService } from './../message/message.service';
import { Injectable }     from '@angular/core';
import { CanActivate,Router,ActivatedRouteSnapshot,RouterStateSnapshot }    from '@angular/router';
import { Store } from '@ngrx/store';
import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable, of } from 'rxjs';
import { GlobalConstants } from '../../app.globalConstants';
import { User } from '../../models/user';
import { map, catchError } from 'rxjs/operators';
import { UserActionTypes } from 'src/app/actions/user.actions';

@Injectable()
export class AuthenticateService implements CanActivate  {

  public get:Observable<any>;
  public auth;
  public username;

  private backendHnadler: HttpClient 
  
  constructor(
    private router:Router ,
    private store:Store<User>,
    private http:HttpClient,
    private messageService:MessageService,
    private handler: HttpBackend
  ){
    this.backendHnadler = new HttpClient(handler);
    this.store.select(state=> state['user'])
    .subscribe(data=>{
      this.auth = data;
      if(data){
        this.username = data.email;
      }
    });
    this.checkUser();
  }
  /* check for user session after authentication
   function will trigger as soon as activate function call made and listen for store.
   if there is any changes in store user then checkUser session will invoke removing previous
   interval if any. Store values session data get update whenever there is a call made
   through form action methods through app.httpredirects dispatchCallTime function.
  */ 
  checkUserSession(){
    // this.createSession = setInterval(() => {
    //   this.checkSessionStatus()
    //     .map(res=>res.json())
    //     .subscribe(response=>{
    //       if(response.status!='Success' || response.active=='false'){
    //         clearInterval(this.createSession);
    //         this.redirectSessionLogout();
    //       }
    //     })
    // },this.sessionInterval);
  }

  redirectSessionLogout(){
    // this.router.navigate(['/login',{redirect:this.router['location']['_platformStrategy']['_platformLocation']['_location.hash'] || '/'}]);
  }

  login(payLoad){
    this.username = payLoad.email;
    return this.http
      .post(GlobalConstants.endPoints.LOGIN_USER, payLoad);
  }

  canActivate(route:ActivatedRouteSnapshot,state:RouterStateSnapshot) :boolean | Promise<boolean> | Observable<boolean> {
    let params:Object = route.params;
    if(params && Object.keys(params).length > 0 && params.hasOwnProperty('token')) {
      var token = (params as any)?.token;
      var id = (params as any)?.id;
      let header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      return this.backendHnadler.get(GlobalConstants.endPoints.GET_CLIENT_PROFILE, {headers:header} ).pipe((map(r => {
        let response = r as any;
        if(!response.status) {
          this.router.navigate(['/signin']);
          return false;
        }else{
          this.store.dispatch({ type: UserActionTypes.LOGIN_SUCCESS, payload: response.result });
          window['storageOption'] = 'sessionStorage';
          localStorage.setItem('remember', 'false');
          let result = {...response.result, 'token':token};

          console.log(result);
          (window[window['storageOption']] as any)?.setItem('auth', JSON.stringify(result));
          if(!id){
            this.router.navigate(['/client/dashboard']);
          }else{
            this.router.navigate(['/client/my-service-agreements']);
         }
         
          return true;
        }
      })),catchError(err=>{
        this.router.navigate(['/signin']);
        console.error(err);
        return of(false);
      }))
    } else if(this.auth.role == '2') {
      return true
    } else {
      this.router.navigate(['/signin']);
      // this.router.navigate(['/signin',{redirect:decodeURIComponent(state.url)}]);
      return false;  
    }
  }

  checkUser(){
    window['storageOption'] = localStorage.getItem('remember') == "true" ? 'localStorage': 'sessionStorage';
    let userData:any = (window[window['storageOption']] as any)?.getItem('auth') || false;
    let userObj = JSON.parse(userData);
    if (!userObj) return false;
    if(userObj){
      userObj.session = Date.now();
      this.username = userObj.name;
      this.store.dispatch({type:'LOGIN_SUCCESS', payload:userObj});
      return true
    }else{
      this.logout();
      return false;
    }
  }

  getUsername(){
    return this.auth.username;
  }

  logout(){
    this.store.dispatch({type:'USER_LOGOUT'});
    const remember = localStorage.getItem('remember');
    if(remember){
      if(remember === 'false'){
        sessionStorage.removeItem('auth')
      }else{
        localStorage.removeItem('auth');
      }
    }
    this.router.navigate(['/signin']);
  } 

  forgetPwd(data) {
    const postData = {
      role:'2',
      type:'email',
      value:data['email']
    }
    return this.http.post(GlobalConstants.endPoints.FORGOT_PASSWORD, postData)
  }

  verifyOtp(payload){
    const postData = {
        type: 'email', 
        otp: payload.otp, 
        role:'2',
        value: payload.email
      }
      return this.http.post(GlobalConstants.endPoints.VERIFY_OTP, postData)
  }

  // Reset password with otp
  resetNewPassword(payload){
    const postData={
        type: 'email',  
        role: '2', 
        password: payload.password, 
        value: payload.email
      }
    return this.http.post(GlobalConstants.endPoints.RESET_PASSWORD, postData)
  }

}

