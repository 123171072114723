import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { GalleryService } from '../services/gallery/gallery.service';


interface ResponseType{
  result: any,
  status: boolean,
  message: string
}

@Component({
  selector: 'app-gallery-event',
  templateUrl: './gallery-event.component.html',
  styleUrls: ['./gallery-event.component.scss']
})
export class GalleryEventComponent implements OnInit {

  galleryEventList = [];
  folderId: string;

  constructor(private _galleryService: GalleryService,
  private _route: ActivatedRoute) { }

  ngOnInit(): void {
    this._route.url.subscribe(res => {
      this.folderId = this._route.snapshot.params.folderid;
      if (this.folderId) {
        this.getGalleryEvent();
      }
    })
  }

  getGalleryEvent() {
    this._galleryService.getGalleryEvent(this.folderId).subscribe((response: ResponseType) =>{
      if(response.status) {
        this.galleryEventList = response.result
      }
    })
  }

}

