<section class="services-section inner-wrap"> 
    <div class="page-banner pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">New Mums</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">New Mums</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="services-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="offset-md-2 col-md-8 col-12 text-center">
                    <h3 class="fw-600 mb-20">New Mums Or Mums On The Go</h3>
                    <h4 class="subheading">At Caring Approach we offer a range of services to suit our client's needs </h4>
                    <p class="details mb-10">If you're a new mother we appreciate how exhausting and stressful adjusting to your new life can be. Even if you are a seasoned expert we can help you with the extra chores you simply don't get time for.</p>
                    <hr>
                    <h4 class="mt-15 announce mt-10">Tell us how we can support you for a fully tailored service !</h4>
                    <h4 class="text-theme"><a href="tel:+1300655026"><strong>Call 1300 655 026</strong></a></h4>
                </div>
            </div>
        </div>
    </div>
    <div class="our-service-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">01</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/disability-care">
                                        <p class="content mb-0">Disability</p>
                                        <h3 class="title mb-0 mt-0 fw-500">Care </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">02</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/nursing-care">
                                        <p class="content mb-0">Nursing</p>
                                        <h3 class="title mb-0 mt-0 fw-500">Care </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">03</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/dementia-care">
                                        <p class="content mb-0">Dementia</p>
                                        <h3 class="title mb-0 mt-0 fw-500">Care </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">04</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/recovery-care">
                                        <p class="content mb-0">Recovery</p>
                                        <h3 class="title mb-0 mt-0 fw-500">Care</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">05</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/new-mums">
                                        <p class="content mb-0">New Mums Or</p>
                                        <h3 class="title mb-0 mt-0 fw-500">Mums On The Go</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">06</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/respite-help">
                                        <p class="content mb-0"> Respite And Help</p>
                                        <h3 class="title mb-0 mt-0 fw-500">For Carers </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">07</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/transport">
                                        <h3 class="title mb-0 mt-0 fw-500">Transport </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">08</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/autism">
                                        <h3 class="title mb-0 mt-0 fw-500">Autism </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="gov-funded pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Government funded?  We’ve got you covered.</h1>
            <p class="text-white">Caring Approach is registered with NDIS & Myagedcare <br> to help you avail their help and benefits</p>
        </div>
    </div>
    <div class="testimonial-section">
        <div class="container">
            <div class="row">
                <div class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-12 text-center">
                    <h2 class="title">Client Testimonials</h2>
                    <p class="text">What our clients have to say about Caring Approach</p>
                </div>
            </div>
            <div class="row mt-20">
                <!-- Testimonial -->
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="box">
                        <!-- Testimonial Image -->
                        <div class="video-wrap mr-5">
                            <video controls="controls" poster="assets/images/testi-poster1.png">
                                <source src="assets/video/greg-moran-testimonial.mp4" type="video/mp4"> Your browser does not support the video tag. 
                            </video>
                        </div>
                        <!-- / Testimonial Image -->
                        <div class="test-component">
                            <!-- Title -->
                            <article class="test-title">
                                <h4>
                                    Greg Moran
                                </h4>
                            </article>
                            <!-- / Title -->
                            <article class="test-content">
                                <p>
                                    My name is Greg Moran, I'm a C3/4 quadriplegic as a result of a rugby accident in 1983. In my 30 odd years as a quadriplegic I can categorically say that Caring Approach Agency... <a href="javascript:void(0);" routerLink="/testimonials" class="text-theme"><small>Read more >></small></a>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- / Testimonial -->
                <!-- Testimonial -->
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="box">
                        <!-- Testimonial Image -->
                        <div class="video-wrap mr-5">
                            <video controls="controls" poster="assets/images/testi-poster2.png">
                                <source src="assets/video/joanna-willis-testimonial.mp4" type="video/mp4"> Your browser does not support the video tag. 
                            </video>
                        </div>
                        <!-- / Testimonial Image -->
                        <div class="test-component">
                            <!-- Title -->
                            <article class="test-title">
                                <h4>
                                    Farah
                                </h4>
                            </article>
                            <!-- / Title -->
                            <article class="test-content">
                                <p>
                                    I decided to use Caring Approach for my personal and domestic care needs because Homecare did not provide the quality of staff or the flexibility of care that I needed...
                                    <a href="javascript:void(0);" routerLink="/testimonials" class="text-theme"><small>Read more >></small></a>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- / Testimonial -->
                <!-- Testimonial -->
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="box">
                        <!-- Testimonial Image -->
                        <div class="video-wrap mr-5" >
                            <video controls="controls" poster="assets/images/testi-poster3.png">
                                <source src="assets/video/mark-testimonial.mp4" type="video/mp4"> Your browser does not support the video tag. 
                            </video>
                        </div>
                        <!-- / Testimonial Image -->
                        <div class="test-component">
                            <!-- Title -->
                            <article class="test-title">
                                <h4>
                                    Mark
                                </h4>
                            </article>
                            <!-- / Title -->
                            <article class="test-content">
                                <p>
                                    I have been receiving home care for approximately nine months. Initially, that care was provided by another agency. It was only after I began using Caring Approach...
                                    <a href="javascript:void(0);" routerLink="/testimonials" class="text-theme"><small>Read more >></small></a>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- / Testimonial -->
            </div>
        </div>
    </div>
    <div class="social-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="theme-box p-20">
                                <h4 class="text-white">After Hours Support</h4>
                                <p class="text-white">Our contracted clients are assured that after hours support is available for both clients and staff if urgent matters arise.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="blue-box p-20">
                                <h4 class="text-white">Easy transfer of services</h4>
                                <p class="text-white">Invoices are provided fortnightly by post or email. We can also work with your government provider to arrange a simple and easy transfer of services.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="box p-20">
                                <h4>Easy Referral</h4>
                                <span>
                                    <i class="fas fa-envelope mr-5"></i>
                                    <a  href="mailto:admin@caringapproach.com.au" class="text-theme" target="_blank"> <span>admin@caringapproach.com.au</span></a>
                                </span><br>
                                <span>
                                   <i class="fas fa-phone mr-5"></i>
                                   <a href="tel:+1300655026"><span>1300 655 026</span></a>
                                </span><br>
                                <span>
                                   
                                    <i class="fas fa-phone mr-5"></i>
                                    <a href="tel:+0431744321"><span>0431 744 321</span>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    