import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.single-item').slick({
      infinite: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 1500,
      speed: 1500,
      cssEase: 'ease-in-out',
      slidesToShow: 1,
      prevArrow: '<button class="slide-arrow prev-arrow btn-primary" style="border-radius: 0;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;;bottom: 0;z-index: 1;right: 40px;"><i class="fas fa-chevron-left"></i></button>',
      nextArrow: '<button class="slide-arrow next-arrow btn-primary" style="border-radius: 0;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;bottom:0;z-index: 1;right:0;"><i class="fas fa-chevron-right"></i></button>',
      responsive: [
        {
          breakpoint: 640,
          settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: '<button class="slide-arrow prev-arrow btn-primary" style="border-radius: 50%;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;;bottom: -30px;z-index: 1;left: 15%;"><i class="fas fa-chevron-left"></i></button>',
          nextArrow: '<button class="slide-arrow next-arrow btn-primary" style="border-radius: 50%;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;bottom: -30px;z-index: 1;right:15%;"><i class="fas fa-chevron-right"></i></button>',
          }
        }
      ]      
    });
  }
  decreasefontsize(){
    $('.inner-wrap h1').css('font-size', '-=1');
    $('.inner-wrap h2').css('font-size', '-=1');
    $('.inner-wrap h3').css('font-size', '-=1');
    $('.inner-wrap h4').css('font-size', '-=1');
    $('.inner-wrap h5').css('font-size', '-=1');
    $('.inner-wrap h6').css('font-size', '-=1');
    $('.inner-wrap p').css('font-size', '-=1');
    $('.inner-wrap a').css('font-size', '-=1');
    $('.inner-wrap li').css('font-size', '-=1');
    $('.inner-wrap span').css('font-size', '-=1');
  }
  increasefontsize(){
    $('.inner-wrap p').css('font-size', '+=2');
    $('.inner-wrap h1').css('font-size', '+=2');
    $('.inner-wrap h2').css('font-size', '+=2');
    $('.inner-wrap h3').css('font-size', '+=2');
    $('.inner-wrap h4').css('font-size', '+=2');
    $('.inner-wrap h5').css('font-size', '+=2');
    $('.inner-wrap h6').css('font-size', '+=2');
    $('.inner-wrap a').css('font-size', '+=2');
    $('.inner-wrap li').css('font-size', '+=2');
    $('.inner-wrap span').css('font-size', '+=2');
  }
}
