import { Component, OnInit } from '@angular/core'; 
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { CareerService } from './career.service';
import { ToastrService } from 'ngx-toastr'
declare let $: any;
@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {
  file: File = null; // Variable to store file 
  constructor(
    private CareerService: CareerService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastrService
  ) { }

  public careerForm: FormGroup;
  submitted = false;
  returnUrl: string;
  resume:File;
  document = [];
  isLoading:boolean = false;
  fileSizeError:boolean = false;

  

  ngOnInit(): void { 
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
	
    this.careerForm = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.pattern('[a-zA-Z ]+')]),
      street_address: new FormControl(null, [Validators.required]),
      address: new FormControl(null, []),
      city: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      phone_no: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]{9,12}')]),
      email: new FormControl(null, [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])(\.[a-z0-9]([a-z0-9-]*[a-z0-9]))*$/i)]),
      comment: new FormControl(null, [Validators.required])
     
    })

    var input = document.getElementById( 'resume' );
    var infoArea = document.getElementById( 'file-upload-filename' );

    input.addEventListener( 'change', showFileName );

    function showFileName( event ) {
      
      // the change event gives us the input it occurred in 
      var input = event.srcElement;
      
      // the input has an array of files in the `files` property, each one has a name that you can use. We're just using the name here.
      var fileName = input.files[0].name;
      
      // use fileName however fits your app best, i.e. add it into a div
      infoArea.textContent = 'File name: ' + fileName;
    }

    
    
  }
   
   

   uploadFile(event){
    this.fileSizeError = false;
    if(event.target.name == 'resume'){
      this.resume = event.target.files[0];
      if(this.resume.size > 5240000){
        this.fileSizeError = true;
      }
    }else{
      this.document = event.target.files;
    }
   }
 
  // On file Select 
  onChange(event) { 
      this.file = event.target.files[0]; 
      this.careerForm.get('profile').setValue(this.file);
      // if (event.target.files.length > 0) {
      //   const file = event.target.files[0];
      //   this.careerForm.get('profile').setValue(file);
      // }
  }
  handleResume(files: FileList) {
    // this.category.category_banner = '/categories/download/' + files[0].name;
    // this.careerForm.append('category_banner', files[0], files[0].name);
    // this.categoryContainersService.uploadFile(this.formData).subscribe(filename => console.log(files[0].name));
  }
   careerSubmit() {
      if (this.careerForm.invalid || this.fileSizeError || !this.resume) {
          this.submitted = true;
          return;
      }else{	 
        this.submitted = false;
        this.isLoading = true;
        const payload = {...this.careerForm.value, other_document :this.document, resume_image: this.resume}
        this.CareerService.careerData(payload)
        .subscribe(
          (response:any) => {
            this.isLoading = false;
            this.toast.success(response?.message)
            this.router.navigate([this.returnUrl]);
        }, err => {
          
        })
      }
  }  
  decreasefontsize(){
    $('.inner-wrap h1').css('font-size', '-=1');
    $('.inner-wrap h2').css('font-size', '-=1');
    $('.inner-wrap h3').css('font-size', '-=1');
    $('.inner-wrap h4').css('font-size', '-=1');
    $('.inner-wrap h5').css('font-size', '-=1');
    $('.inner-wrap h6').css('font-size', '-=1');
    $('.inner-wrap p').css('font-size', '-=1');
    $('.inner-wrap a').css('font-size', '-=1');
    $('.inner-wrap li').css('font-size', '-=1');
    $('.inner-wrap span').css('font-size', '-=1');
  }
  increasefontsize(){
    $('.inner-wrap p').css('font-size', '+=2');
    $('.inner-wrap h1').css('font-size', '+=2');
    $('.inner-wrap h2').css('font-size', '+=2');
    $('.inner-wrap h3').css('font-size', '+=2');
    $('.inner-wrap h4').css('font-size', '+=2');
    $('.inner-wrap h5').css('font-size', '+=2');
    $('.inner-wrap h6').css('font-size', '+=2');
    $('.inner-wrap a').css('font-size', '+=2');
    $('.inner-wrap li').css('font-size', '+=2');
    $('.inner-wrap span').css('font-size', '+=2');
  }
  
    
  
}
