import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.scss']
})
export class PrescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $("#latest-btn").click(function(){
      $("#latest-data").show();
      $("#archived-data").hide();
    });
    $("#archived-btn").click(function(){
      $("#latest-data").hide();
      $("#archived-data").show();
    });
  }

}
