import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { DynamicapiService } from '../services/frontapi/dynamicapi.service';

@Component({
  selector: 'app-hero-slider',
  templateUrl:'./hero-slider.component.html',
  styleUrls: ['./hero-slider.component.scss']
})
export class HeroSliderComponent implements OnInit {
  heroSlides:any=[];
  constructor( private DynamicapiService: DynamicapiService) { }


  heroSliderConfig = {
      dots: false,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: true,
      vertical: false,
      centerPadding: '10px',
      slidesToShow: 1,
      slidesToScroll: 1,
      verticalSwiping: false,
      prevArrow: '<button class="slide-arrow prev-arrow btn-primary" style="border: 1px solid #fff;padding: 5px 12px;background-color: transparent;position: absolute;bottom: 22%;z-index: 1;left: 1%;"><i class="fas fa-chevron-left"></i></button>',
      nextArrow: '<button class="slide-arrow next-arrow btn-primary" style="border: 1px solid #fff;padding: 5px 12px;background-color: transparent;position: absolute;bottom: 22%;z-index: 1;right:1%;"><i class="fas fa-chevron-right"></i></button>',
      responsive: [
        {
          breakpoint: 812,
          settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: '<button class="slide-arrow prev-arrow btn-primary" style="border: 1px solid #fff;padding: 5px 5px;background-color: transparent;position: absolute;bottom: 40%;z-index: 1;left: 0%;"><i class="fas fa-chevron-left"></i></button>',
          nextArrow: '<button class="slide-arrow next-arrow btn-primary" style="border: 1px solid #fff;padding: 5px 5px;background-color: transparent;position: absolute;bottom: 40%;z-index: 1;right:0%;"><i class="fas fa-chevron-right"></i></button>',
          }
        }
      ]
  };
  ngOnInit(): void {
    
    this.DynamicapiService.heroSlides().subscribe(
      (response:any) => {
        this.heroSlides = response.result;
         console.log(response);
    }, (_err:any) => {
      
    })
  }

}
