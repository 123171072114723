<section class="testimonial-section">
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Testimonials</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">Testimonials</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="testimonial-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12">
                    <div class='content'>
                        <div class='slider single-item'>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="video-wrap mr-5">
                                        <video controls="controls">
                                            <source src="assets/video/greg-moran-testimonial.mp4" type="video/mp4"> Your browser does not support the video tag. 
                                        </video>
                                    </div>
                                    <div class="content text-left">
                                        <p>My name is Greg Moran, I'm a C3/4 quadriplegic as a result of a rugby accident in 1983. In my 30 odd years as a quadriplegic I can categorically say that Caring Approach Agency is so far the best agency that I have every used. The carefully vetted and handpicked staff that they use are genuinely there to provide the best possible service for the clients, and in my experience they do. I am very happy with their services and have recommended them to others.
                                        Yours faithfully,</p>
                                        <div class="details">
                                            <h4 class="text-theme">Greg Moran</h4>
                                            <h5 class="text-muted">0410581306</h5>
                                            <a href="mailto:gregmoran@me.com" class="text-theme">gregmoran@me.com</a><br>
                                            <a href="http://www.gregmoran.com.au/" target="_blank" class="text-theme">www.gregmoran.com.au</a>
                                        </div>
                                    </div>
                                </div>
                                <br><br>
                            </div>
                            <div class='quote-container'>
                                <div class='quote text-left'>
                                    <div class="video-wrap mr-5">
                                        <video controls="controls">
                                            <source src="assets/video/joanna-willis-testimonial.mp4" type="video/mp4"> Your browser does not support the video tag. 
                                        </video>
                                    </div>
                                    <p>I decided to use Caring Approach for my personal and domestic care needs because Homecare did not provide the quality of staff or the flexibility of care that I needed. From the first week I started using Caring Approach I found a remarkable improvement in the level of care I received. Not only are the staff highly qualified in nursing care, they are also very personable and understand the need to be flexible and to listen to me. I'd recommend the agency to anyone who needs any kind of complex to simple personal or domestic care.</p>
                                    <div class="details">
                                        <h4 class="text-theme">Farah</h4>
                                        <h5 class="text-muted">0432 830 050</h5>
                                    </div>
                                </div>
                                <br><br>
                            </div>
                            <div class='quote-container'>
                                <div class='quote text-left'>
                                    <div class="video-wrap mr-5">
                                        <video controls="controls">
                                            <source src="assets/video/mark-testimonial.mp4" type="video/mp4"> Your browser does not support the video tag. 
                                        </video>
                                    </div>
                                    <p>I have been receiving home care for approximately nine months. Initially, that care was provided by another agency. It was only after I began using Caring Approach, approximately four months ago, that I realised the difference between Caring Approach and the first agency that I used. The Caring Approach staff are extremely professional, always punctual and very pleasant. They make an uncomfortable situation far less uncomfortable. This was not the case with the other agency.</p>

                                    <p>In addition, the staff from Caring Approach always go the extra mile when providing personal care and assistance.They often perform housework and other tasks that I've not asked them to do simply because they want to help me as much is possible. I know that all of the carers at Caring Approach are dedicated to their job, but I also feel that they’re dedicated to helping me as much as possible because they genuinely care about their clients.</p>
                                            
                                    <p>I cannot recommend a Caring Approach highly enough.</p>
                                    <div class="details">
                                        <h4 class="text-theme">Mark</h4>
                                        <h5 class="text-muted">0407 928 026</h5>
                                        <a href="mailto:mr.lawson@bigpond.com" class="text-theme">mr.lawson@bigpond.com</a><br>
                                    </div>
                                </div>
                                <br><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>