<div class="gallery-wrap">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="d-flex align-items-center mb-20">
                    <div><h3>Gallery</h3></div>
                    <div class="ml-auto align-items-center">
                        <div class="dl">
                            <a href="javascript:void(0);" [routerLink]="['/gallery/event', folderId]">Back</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-12">
                <div class="row">
                    <div class="col-md-3 col-12" *ngFor="let image of galleryImagesList">
                        <div class="card border-0 mb-15">
                            <div class="card-body">
                                <div class="img-wrap">
                                    <img [src]="image" class="img-fluid"> 
                                </div>
                                <div class="middle">
                                    <a href="javascript:void(0;)" (click)="openImageModal(image)" class="text" data-toggle="modal" ><i class="fas fa-eye"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
