<div class="banner-holder"
    *ngIf="router.url !== '/signin' && router.url !== '/register' && router.url !== '/change-password' && router.url !== '/forgot-password' && router.url != '/forgot-password/confirmation-otp' && router.url !== '/new-password' && router.url !== '/employee/employee-dashboard' && router.url !== '/employee/clients' && router.url !== '/employee/employee-documents' && router.url !== '/employee/invite' && router.url !== '/employee/employee-profile' && router.url !== '/employee/schedule' && router.url !== '/employee/holiday' && router.url !== '/employee/policy' && router.url !== '/employee/message' && router.url !== '/employee/report-incident' && router.url !== '/employee/incident-report-form' && router.url !== '/employee/employee-feedback' && router.url !== '/employee/support' && router.url !== '/employee/open-ticket' && router.url !== '/employee/new-employee-form' && router.url !== '/employee/upcoming-training' && router.url !== '/client/private-clients-service-agreement' && router.url !== '/client/ndis-supplementary-service-agreement' && router.url !== '/client/ndis-service-agreement-updated' && router.url !== '/client/my-aged-care-service-agreement-form' && router.url !== '/client/integrated-client-assessment-form' && router.url !== '/client/dashboard' && router.url !== '/client/account' && router.url !== '/client/care-plans' && router.url !== '/client/documents' && router.url !== '/client/my-schedule' && router.url !== '/client/my-service-agreements' && router.url !== '/client/open-ticket' && router.url !== '/client/review' && router.url !== '/client/support' && router.url !== '/client/health-news' && router.url !== '/client/tips' && router.url !== '/client/invoices' && router.url !== '/client/appointment'">
    <div class="vsat-banner-container show" style="top: 0px; position: relative;">
        <div class="cross" id="banner-close"><i class="fa fa-times"
                style="position: relative;left: 7px;top: 12px;color: #FF9300;"></i></div>
        <!-- <div class="webinar-banner">
            <div class="banner-text">If you are outside home and worried about the health of your elderly parents in
                Australia, especially in times of this crisis and lockdown we can help you! Call <a
                    href="tel:+1300655026" class="dark-blue-bg">&nbsp; 1300 655 026</a></div>
        </div> -->
    </div>
</div>
<section class="navbar-wrap" id="stick-me"
    *ngIf="router.url !== '/signin' && router.url !== '/register' && router.url !== '/change-password' && router.url !== '/forgot-password' && router.url != '/forgot-password/confirmation-otp' && router.url !== '/new-password'">
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="container">
            <a class="navbar-brand" href="javascript:void(0);" routerLink="/home">
                <img src="assets/images/logo/logo.png" class="img-fluid">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav mr-auto">
                    <!--<li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Our Services
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/disability-care">Disability Care</a>
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/nursing-care">Nursing Care</a>
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/dementia-care">Dementia Care</a>
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/recovery-care">Recovery Care </a>
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/new-mums">New Mums Or Mums On The Go </a>
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/respite-help">Respite And Help For Carers </a>
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/transport">Transport</a>
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/autism">Autism</a>
                            </div>
                        </li>-->
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0);" routerLink="/about-us">About Us</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0);">Blogs</a>
                    </li> -->
                    <div class="mobile-menu"
                        *ngIf="router.url !== '/employee/employee-dashboard' && router.url !== '/employee/clients' && router.url !== '/employee/employee-documents' && router.url !== '/employee/invite' && router.url !== '/employee/employee-profile' && router.url !== '/employee/schedule' && router.url !== '/employee/holiday' && router.url !== '/employee/policy' && router.url !== '/employee/message' && router.url !== '/employee/report-incident' && router.url !== '/employee/incident-report-form' && router.url !== '/employee/employee-feedback' && router.url !== '/employee/support' && router.url !== '/employee/open-ticket' && router.url !== '/employee/new-employee-form' && router.url !== '/employee/upcoming-training' && router.url !== '/account' && router.url !== '/appointment' && router.url !== '/prescription' && router.url !== '/reports' && router.url !== '/client/private-clients-service-agreement' && router.url !== '/client/dashboard' && router.url !== '/client/ndis-supplementary-service-agreement' && router.url !== '/client/ndis-service-agreement-updated' && router.url !== '/client/my-aged-care-service-agreement-form' && router.url !== '/client/integrated-client-assessment-form' && router.url !== '/client-referral-form' && router.url !== '/client/dashboard' && router.url !== '/client/account' && router.url !== '/client/care-plans' && router.url !== '/client/documents' && router.url !== '/client/my-schedule' && router.url !== '/client/my-service-agreements' && router.url !== '/client/open-ticket' && router.url !== '/client/review' && router.url !== '/client/support' && router.url !== '/client/health-news' && router.url !== '/client/tips' && router.url !== '/client/invoices' && router.url !== '/client/appointment'">
                        <li class="nav-item dropdown pos-rel">
                            <a class="nav-link dropdown-toggle active" id="dropdownMenu1" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                [routerLinkActive]="ndisArray.includes(router.url) ? 'active':''">
                                NDIS
                            </a>
                            <ul class="dropdown-menu multi-level pos-abs" role="menu" aria-labelledby="dropdownMenu">
                                <li class="dropdown-item"><a href="javascript:void(0);"
                                        routerLink="/support-coordination">Support Coordination</a></li>
                                <li class="dropdown-item"><a href="javascript:void(0);"
                                        routerLink="/plan-management">Plan Management</a></li>
                                <li class="dropdown-divider"></li>
                                <li>
                                    <h5 class="ml-4"><b>Our Services</b></h5>
                                </li>
                                <li class="dropdown-divider"></li>
                                <li class="dropdown-item"><a href="javascript:void(0);"
                                        routerLink="/disability-care">Disability Care</a>
                                <li class="dropdown-item"><a href="javascript:void(0);"
                                        routerLink="/nursing-care">Nursing Care</a>
                                <li class="dropdown-item"><a href="javascript:void(0);"
                                        routerLink="/dementia-care">Dementia Care</a>
                                <li class="dropdown-item"><a href="javascript:void(0);"
                                        routerLink="/recovery-care">Recovery Care </a>
                                <li class="dropdown-item"> <a href="javascript:void(0);" routerLink="/new-mums">New Mums
                                        Or Mums On The Go </a>
                                <li class="dropdown-item"> <a href="javascript:void(0);"
                                        routerLink="/respite-help">Respite And Help For Carers </a>
                                <li class="dropdown-item"><a href="javascript:void(0);"
                                        routerLink="/transport">Transport</a>
                                <li class="dropdown-item"><a href="javascript:void(0);" routerLink="/autism">Autism</a>
                                </li>
                                <li class="dropdown-divider"></li>
                                <li class="dropdown-item"><a
                                        href="assets/pdf/NDIS_Pricing_Arrangements_Price_Limit_2021-22(1) (002).pdf"
                                        target="_blank">Pricing</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/aged-care" class="nav-link active">
                                My Aged Care
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/accomodation" class="nav-link">
                                 Accommodation
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/community" class="nav-link">
                                Community
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/participants" class="nav-link">
                                Participants
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/career" class="nav-link">
                                Careers
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/ndis-checklist" class="nav-link">
                                NDIS Checklist
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/resources" class="nav-link">
                                Resources
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/client-referral-form" class="nav-link">
                                Client Referral Form
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/contact" class="nav-link">
                                Contact
                            </a>
                        </li>
                    </div>
                    <div class="mobile-menu"
                        *ngIf="router.url !== '/home' && router.url !== '/employee/employee-dashboard' && router.url !== '/employee/clients' && router.url !== '/employee/employee-documents' && router.url !== '/employee/invite' && router.url !== '/employee/employee-profile' && router.url !== '/employee/schedule' && router.url !== '/employee/holiday' && router.url !== '/employee/policy' && router.url !== '/employee/message' && router.url !== '/employee/report-incident' && router.url !== '/employee/incident-report-form' && router.url !== '/employee/employee-feedback' && router.url !== '/employee/support' && router.url !== '/employee/open-ticket' && router.url !== '/employee/new-employee-form' && router.url !== '/employee/upcoming-training' && router.url !== '/contact-us' && router.url !== '/about-us' && router.url !== '/our-services' && router.url !== '/policy' && router.url !== '/career' && router.url !== '/testimonials' && router.url !== '/feedback' && router.url !== '/resources' && router.url !== '/ndis-checklist' && router.url !== '/participants' && router.url !== '/accreditation' && router.url !== '/gallery' && router.url !== '/support-coordination' && router.url !== '/accomodation' && router.url !== '/aged-care' && router.url !== '/plan-management' && router.url !== '/community' && router.url !== '/disability-care' && router.url !== '/nursing-care' && router.url !== '/dementia-care' && router.url !== '/recovery-care' && router.url !== '/transport' && router.url !== '/autism' && router.url !== '/new-mums' && router.url !== '/respite-help' && router.url !== '/client-referral-form'">
                        <li class="nav-item" *ngFor="let item of headerMenu;">
                            <a href="javascript:void(0);" routerLink="{{item.link}}" class="nav-link"
                                routerLinkActive="active">
                                {{item.menu}}
                            </a>
                        </li>

                    </div>
                    <!-- *ngIf="router.url !== '/appointment' && router.url !== '/prescription' && router.url !== '/reports' && router.url !== '/home' && router.url !== '/contact-us' && router.url !== '/about-us' && router.url !== '/our-services' && router.url !== '/policy' && router.url !== '/career' && router.url !== '/testimonials' && router.url !== '/feedback' && router.url !== '/resources' && router.url !== '/ndis-checklist' && router.url !== '/participants' && router.url !== '/accreditation' && router.url !== '/gallery' && router.url !== '/support-coordination' && router.url !== '/accomodation' && router.url !== '/aged-care' && router.url !== '/plan-management' && router.url !== '/community' && router.url !== '/disability-care' && router.url !== '/nursing-care' && router.url !== '/dementia-care' && router.url !== '/recovery-care' && router.url !== '/transport' && router.url !== '/autism' && router.url !== '/new-mums' && router.url !== '/respite-help' && router.url !== '/client-referral-form' && router.url !== '/client/private-clients-service-agreement' && router.url !== '/client/ndis-supplementary-service-agreement' && router.url !== '/client/ndis-service-agreement-updated' && router.url !== '/client/my-aged-care-service-agreement-form' && router.url !== '/client/integrated-client-assessment-form' && router.url !== '/client-referral-form' && router.url !== '/client/dashboard' && router.url !== '/client/account' && router.url !== '/client/care-plans' && router.url !== '/client/documents' && router.url !== '/client/my-schedule' && router.url !== '/client/my-service-agreements' && router.url !== '/client/open-ticket' &&router.url !== '/client/review' && router.url !== '/client/support' && router.url !== '/client/health-news' && router.url !== '/client/tips' && router.url !== '/client/invoices' && router.url !== '/client/appointment'" -->

                    <div class="mobile-menu" *ngIf="router.url !== '/' &&  router.url.includes('/employee/')">
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/employee/employee-dashboard"
                                class="nav-link active">
                                Dashboard
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/employee/clients" class="nav-link">
                                Clients
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/employee/schedule" class="nav-link">
                                Roster
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);" routerLink="/employee/holiday">
                                Leave
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/employee/employee-documents" class="nav-link">
                                Documents
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/employee/report-incident" class="nav-link">
                                Report an Incident
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/employee/employee-feedback" class="nav-link">
                                Feedback
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" routerLink="/employee/employee-profile" class="nav-link">
                                Profile
                            </a>
                        </li>
                    </div>
                </ul>
                <ul class="navbar-nav navbar-nav ml-auto">
                    <li class="nav-item">
                        <span class="mr-4 my-sm-0">
                            <a href="https://www.ndis.gov.au/" target="_blank">
                                <img src="assets/images/ndis_img.jpg" class="img-fluid" style="height:57px;">
                            </a>
                        </span>
                    </li>
                    <li class="nav-item">
                        <span class="mr-4 my-sm-0">
                            <a href="https://www.myagedcare.gov.au/" target="_blank">
                                <img src="assets/images/mgc.png" class="img-fluid" style="height:57px;">
                            </a>
                        </span>
                    </li>
                    <li class="nav-item mt-10">
                        <a href="tel:+1300655026" class="btn btn-outline-secondary mr-4 my-sm-0" type="button">
                            <i class="fas fa-phone-alt"></i> 1300 655 026
                        </a>
                    </li>
                    <li class="nav-item mt-10" *ngIf="!(userMeta | async)?.email">
                        <a class="btn btn-success btn-theme my-2 mr-4 my-sm-0" routerLink="/signin">Login</a>
                    </li>
                    <li class="nav-item dropdown d-none">
                        <a class="nav-link dropdown-toggle mr-4" href="javascript:void(0);" id="navbarDropdown"
                            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="user-name">En</span>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" href="javascript:void(0);">Spa</a>
                            <a class="dropdown-item" href="javascript:void(0);">Ger</a>
                        </div>
                    </li>

                    <!-- client -->
                    <li class="nav-item dropdown dropdown-list-toggle notification-wrap"
                        *ngIf="(userMeta | async)?.email && (userMeta | async)?.role === '2'">
                        <a href="#" data-toggle="dropdown" class="nav-link notification-toggle mr-4">
                            <i class="fas fa-bell ring"></i>
                            <span *ngIf="(_notificationService.messageCount$ | async) > 0" class="badge badge-theme">{{
                                _notificationService.messageCount$ | async }}</span>
                        </a>
                        <div class="dropdown-menu dropdown-list dropdown-menu-right pullDown">
                            <div class="dropdown-header">
                                <h5 class="notify-label text-black">Notifications</h5>
                                <a class="text-danger" [routerLink]="['/client/notifications']"
                                    routerLinkActive="router-link-active">View All</a>
                            </div>
                            <div class="dropdown-list-content dropdown-list-icons">
                                <a class="dropdown-item dropdown-item-unread"
                                    *ngFor="let notificattion of _notificationService.notifications$ | async"
                                    (click)="readNotifications(notificattion)">
                                    <span class="dropdown-item-icon bg-theme text-white">
                                        <i class="fas fa-envelope"></i>
                                    </span>
                                    <span class="dropdown-item-desc">
                                        <span class="desc">{{ notificattion.message }}</span>
                                        <span class="time">2 Min Ago</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </li>
                    <!-- employee -->
                    <li class="nav-item dropdown dropdown-list-toggle notification-wrap"
                        *ngIf="(userMeta | async)?.email && (userMeta | async)?.role === '1'">
                        <a href="#" data-toggle="dropdown" class="nav-link notification-toggle mr-4">
                            <i class="fas fa-bell ring"></i>
                            <span *ngIf="(_notificationService.messageCount$ | async) > 0" class="badge badge-theme">{{
                                _notificationService.messageCount$ | async }}</span>
                        </a>
                        <div class="dropdown-menu dropdown-list dropdown-menu-right pullDown">
                            <div class="dropdown-header">
                                <h5 class="notify-label text-black">Notifications</h5>
                                <a class="text-danger" [routerLink]="['/employee/notification']"
                                    routerLinkActive="router-link-active">View All</a>
                            </div>
                            <div class="dropdown-list-content dropdown-list-icons">
                                <a class="dropdown-item dropdown-item-unread"
                                    *ngFor="let notificattion of _notificationService.notifications$ | async"
                                    (click)="readNotifications(notificattion)">
                                    <span class="dropdown-item-icon bg-theme text-white">
                                        <i class="fas fa-envelope"></i>
                                    </span>
                                    <span class="dropdown-item-desc">
                                        <span class="desc">{{ notificattion.message }}</span>
                                        <span class="time">{{ notificattion.created_date | messageTime}}</span>
                                    </span>
                                </a>
                                
                            </div>
                        </div>
                    </li>

                    <li class="nav-item dropdown user-panel" *ngIf="(userMeta | async)?.role == '2'">
                        <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img [src]="(userMeta | async)?.profile_image"
                                [default]="(userMeta | async)?.gender == 'Male' ? 'assets/images/testimonial-1.jpeg' : 'assets/images/testimonial-2.png'"
                                alt="profile" class="user-image">
                            <p class="user-name">Hi, {{(userMeta | async)?.name}}</p>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" href="javascript:void(0);" routerLink="/client/account"><i
                                    class="fas fa-user"></i>&nbsp; Profile</a>
                            <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-cog"></i>&nbsp;
                                Settings</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="logout($event)"><i
                                    class="fas fa-power-off"></i>&nbsp; Logout</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown user-panel" *ngIf="(userMeta | async)?.role == '1'">
                        <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img [src]="(userMeta | async)?.profile_image" height="25px" width="25px"
                                [default]="(userMeta | async)?.gender == 'Male' ? 'assets/images/testimonial-1.jpeg' : 'assets/images/testimonial-2.png'"
                                alt="profile" class="user-image">
                            <p class="user-name">Hi, {{(userMeta | async)?.firstname}}</p>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" href="javascript:void(0);"
                                routerLink="/employee/employee-profile"><i class="fas fa-user"></i>&nbsp; Profile</a>
                            <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-cog"></i>&nbsp;
                                Settings</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="logout($event)"><i
                                    class="fas fa-power-off"></i>&nbsp; Logout</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- <div class="navbar nav-bottom"
        *ngIf="router.url !== '/' && router.url !== '/employee/employee-dashboard' && router.url !== '/employee/clients' && router.url !== '/employee/employee-documents' && router.url !== '/employee/invite' && router.url !== '/employee/employee-profile' && router.url !== '/employee/schedule' && router.url !== '/employee/holiday' && router.url !== '/employee/policy' && router.url !== '/employee/message' && router.url !== '/employee/report-incident' && router.url !== '/employee/incident-report-form' && router.url !== '/employee/employee-feedback' && router.url !== '/employee/support' && router.url !== '/employee/open-ticket' && router.url !== '/employee/new-employee-form' && router.url !== '/employee/upcoming-training' && router.url !== '/appointment' && router.url !== '/prescription' && router.url !== '/reports' && router.url !== '/client/private-clients-service-agreement' && router.url !== '/client/ndis-supplementary-service-agreement' && router.url !== '/client/ndis-service-agreement-updated' && router.url !== '/client/my-aged-care-service-agreement-form' && router.url !== '/client/integrated-client-assessment-form' && router.url !== '/client/dashboard' && router.url !== '/client/account' && router.url !== '/client/care-plans' && router.url !== '/client/documents' && router.url !== '/client/my-schedule' && router.url !== '/client/my-service-agreements' && router.url !== '/client/open-ticket' && router.url !== '/client/review' && router.url !== '/client/support' && router.url !== '/client/health-news' && router.url !== '/client/tips' && router.url !== '/client/invoices' && router.url !== '/client/appointment'"> -->
    <div class="navbar nav-bottom"
        *ngIf="router.url !== '/' &&  !router.url.includes('/client/') &&  !router.url.includes('/employee/')">
        <div class="container">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a href="tel:+1300655026" class="dark-blue-bg nav-link"><i class="fas fa-phone-alt"></i> 1300 655
                        026</a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown pos-rel">
                    <a class="nav-link dropdown-toggle" id="dropdownMenu1" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" [class.active]="ndisArray.includes(router.url)">
                        NDIS
                    </a>
                    <ul class="dropdown-menu multi-level pos-abs" role="menu" aria-labelledby="dropdownMenu">
                        <li class="dropdown-item"><a href="javascript:void(0);"
                                routerLink="/support-coordination">Support Coordination</a></li>
                        <li class="dropdown-item"><a href="javascript:void(0);" routerLink="/plan-management">Plan
                                Management</a></li>
                        <li class="dropdown-submenu">
                            <a class="dropdown-item" tabindex="-1" href="javascript:void(0);">Our Services</a>
                            <ul class="dropdown-menu">
                                <a class="dropdown-item" tabindex="-1" href="javascript:void(0);"
                                    routerLink="/disability-care">Disability Care</a>
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/nursing-care">Nursing
                                    Care</a>
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/dementia-care">Dementia
                                    Care</a>
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/recovery-care">Recovery
                                    Care </a>
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/new-mums">New Mums Or
                                    Mums On The Go </a>
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/respite-help">Respite
                                    And Help For Carers </a>
                                <a class="dropdown-item" href="javascript:void(0);"
                                    routerLink="/transport">Transport</a>
                                <a class="dropdown-item" href="javascript:void(0);" routerLink="/autism">Autism</a>
                            </ul>
                        </li>
                        <li class="dropdown-item"><a href="javascript:void(0);"
                                href="assets/pdf/NDIS_Pricing_Arrangements_Price_Limit_2021-22(1) (002).pdf"
                                target="_blank">Pricing</a></li>
                    </ul>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/aged-care" class="nav-link" routerLinkActive="active">
                        My Aged Care
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/accomodation" class="nav-link" routerLinkActive="active">
                        Accommodation
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/community" class="nav-link" routerLinkActive="active">
                        Community
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/participants" class="nav-link" routerLinkActive="active">
                        Participants
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/career" class="nav-link" routerLinkActive="active">
                        Careers
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/ndis-checklist" class="nav-link"
                        routerLinkActive="active">
                        NDIS Checklist
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/resources" class="nav-link" routerLinkActive="active">
                        Resources
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/client-referral-form" class="nav-link"
                        routerLinkActive="active">
                        Client Referral Form
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/contact-us" class="nav-link" routerLinkActive="active">
                        Contact
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <!-- <div class="navbar nav-bottom"
        *ngIf="router.url !== '/home' && router.url !== '/employee/employee-dashboard' && router.url !== '/employee/clients' && router.url !== '/employee/employee-documents' && router.url !== '/employee/invite' && router.url !== '/employee/employee-profile' && router.url !== '/employee/schedule' && router.url !== '/employee/holiday' && router.url !== '/employee/policy' && router.url !== '/employee/message' && router.url !== '/employee/report-incident' && router.url !== '/employee/incident-report-form' && router.url !== '/employee/employee-feedback' && router.url !== '/employee/support' && router.url !== '/employee/open-ticket' && router.url !== '/employee/new-employee-form' && router.url !== '/employee/upcoming-training' && router.url !== '/contact-us' && router.url !== '/about-us' && router.url !== '/our-services' && router.url !== '/policy' && router.url !== '/career' && router.url !== '/testimonials' && router.url !== '/feedback' && router.url !== '/resources' && router.url !== '/ndis-checklist' && router.url !== '/participants' && router.url !== '/accreditation' && router.url !== '/gallery' && router.url !== '/support-coordination' && router.url !== '/accomodation' && router.url !== '/aged-care' && router.url !== '/plan-management' && router.url !== '/community' && router.url !== '/disability-care' && router.url !== '/nursing-care' && router.url !== '/dementia-care' && router.url !== '/recovery-care' && router.url !== '/transport' && router.url !== '/autism' && router.url !== '/new-mums' && router.url !== '/respite-help' && router.url !== '/client-referral-form'"> -->
    <div class="navbar nav-bottom" *ngIf="router.url.includes('/client/')">
        <div class="container">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a href="tel:+1300655026" class="dark-blue-bg nav-link"><i class="fas fa-phone-alt"></i> 1300 655
                        026</a>
                </li>
            </ul>

            <ul class="navbar-nav ml-auto">
                <li class="nav-item" *ngFor="let item of headerMenu;">
                    <a href="javascript:void(0);" routerLink="{{item.link}}" class="nav-link" routerLinkActive="active">
                        {{item.menu}}
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/client/dashboard" class="nav-link"
                        routerLinkActive="active">
                        Dashboard
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/client/care-plans" class="nav-link">
                        My Care Plans
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/client/my-schedule" class="nav-link">
                        My Schedule
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/client/my-service-agreements" class="nav-link">
                        My Service Agreements
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/client/documents" class="nav-link">
                        Documents
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/client/invoices" class="nav-link">
                        Invoices
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/client/support" class="nav-link">
                        Support
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/client/review" class="nav-link">
                        Review
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0);" routerLink="/client/account" class="nav-link">
                        Account
                    </a>
                </li> -->
            </ul>
        </div>
    </div>

    <div class="navbar nav-bottom" *ngIf="router.url.includes('/employee/')">
        <div class="container">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a href="tel:+1300655026" class="dark-blue-bg nav-link"><i class="fas fa-phone-alt"></i> 1300 655
                        026</a>
                </li>
            </ul>

            <ul class="navbar-nav ml-auto">
                <li class="nav-item" *ngFor="let item of empHeaderMenu;">
                    <a href="javascript:void(0);" routerLink="{{item.link}}" class="nav-link" routerLinkActive="active">
                        {{item.menu}}
                    </a>
                </li>
            </ul>
        </div>
    </div>

</section>