<section class="contact-section">
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Contact Us</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home"
                            title="Home">Home</a>
                    </li>
                    <li class="separator separator-home">
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i>
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">Contact Us</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="btn-group float-right mb-20 mt-20" role="group" aria-label="Basic example">
                    <button type="button" id="button1" value="larger" class="btn btn-info mr-10"
                        (click)="increasefontsize()">Increase Font</button>
                    <button type="button" id="button2" vale="smaller" class="btn btn-secondary"
                        (click)="decreasefontsize()">Decrease Font</button>
                </div>
            </div>
        </div>
        <div class="box inner-wrap" style="box-shadow: 0 5px 30px 0 rgba(0,0,0,.06);margin:40px 0;">
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="left-content">
                        <h2 class="heading-title">Get In Touch</h2>
                        <p class="mb-10">Questions, comments, or suggestions? Simply fill in the form and we’ll be in
                            touch shortly.</p>
                        <h4>Sydney / NSW Office </h4>
                        <hr>
                        <div class="icon-box-section">
                            <div class="widget-container">
                                <div class="icon-box-wrapper d-flex align-items-center">
                                    <div class="icon-box-icon">
                                        <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div class="icon-box-content">
                                        <span class="icon-box-title">
                                            <span>Address:</span>
                                        </span>
                                        <p class="icon-box-description">Level 2, Suite 201 to 205, 22 Edgeworth David Avenue, Hornsby NSW 2077
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="icon-box-section">
                            <div class="widget-container">
                                <div class="icon-box-wrapper d-flex align-items-center">
                                    <div class="icon-box-icon">
                                        <i aria-hidden="true" class="fas fa-mobile-alt"></i>
                                    </div>
                                    <div class="icon-box-content">
                                        <span class="icon-box-title">
                                            <span>Phone:</span>
                                        </span>
                                        <p class="icon-box-description"><a
                                            href="tel:82125659" class="text-theme">8212 5659</a> / <a
                                            href="tel:90718971" class="text-theme">9071 8971</a> / <a
                                            href="tel:0431744321" class="text-theme">0431 744 321</a> (After
                                            hours)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="icon-box-section">
                            <div class="widget-container">
                                <div class="icon-box-wrapper d-flex align-items-center">
                                    <div class="icon-box-icon">
                                        <i aria-hidden="true" class="far fa-envelope"></i>
                                    </div>
                                    <div class="icon-box-content">
                                        <span class="icon-box-title">
                                            <span>Email:</span>
                                        </span>
                                        <p class="icon-box-description"><a
                                                href="mailto:admin@caringapproach.com.au" class="text-theme" target="_blank">admin@caringapproach.com.au</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4>Canberra / Goulburn Office</h4>
                        <hr>
                        <div class="icon-box-section">
                            <div class="widget-container">
                                <div class="icon-box-wrapper d-flex align-items-center">
                                    <div class="icon-box-icon">
                                        <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div class="icon-box-content">
                                        <span class="icon-box-title">
                                            <span>Address:</span>
                                        </span>
                                        <p class="icon-box-description">42/21 Wiseman Street, Macquarie ACT 2614</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="icon-box-section">
                            <div class="widget-container">
                                <div class="icon-box-wrapper d-flex align-items-center">
                                    <div class="icon-box-icon">
                                        <i aria-hidden="true" class="fas fa-mobile-alt"></i>
                                    </div>
                                    <div class="icon-box-content">
                                        <span class="icon-box-title">
                                            <span>Phone:</span>
                                        </span>
                                        <p class="icon-box-description"><a
                                            href="tel:62531017" class="text-theme">6253 1017</a> / <a
                                            href="tel:62531357" class="text-theme">6253 1357</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="icon-box-section">
                            <div class="widget-container">
                                <div class="icon-box-wrapper d-flex align-items-center">
                                    <div class="icon-box-icon">
                                        <i aria-hidden="true" class="far fa-envelope"></i>
                                    </div>
                                    <div class="icon-box-content">
                                        <span class="icon-box-title">
                                            <span>Email:</span>
                                        </span>
                                        <p class="icon-box-description"><a
                                                href="mailto:adminACT@caringapproach.com.au" class="text-theme" target="_blank">adminACT@caringapproach.com.au</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
 
                        
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="right-form">
                        <form [formGroup]="contactForm" (ngSubmit)="contactSubmit()">
                            <!-- <mat-form-field>   -->
                            <div class="bg-wrap">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="firstname" class="text-black">First Name</label>
                                            <input type="text" name="firstname" value="" size="40" class="form-control"
                                                aria-required="true" aria-invalid="false" matInput
                                                formControlName='firstname' required>

                                            <div *ngIf="contactForm.get('firstname').errors?.required && submitted"
                                                class="error">
                                                First Name is required.
                                            </div>
                                            <div *ngIf="contactForm.get('firstname').errors?.pattern" class="error">
                                                Numbers are not allowed.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="lastname" class="text-black">Last Name</label>
                                            <input type="text" name="lastname" value="" size="40" class="form-control"
                                                aria-required="true" aria-invalid="false" formControlName='lastname'>

                                            <div *ngIf="contactForm.get('lastname').errors?.required && submitted"
                                                class="error">
                                                Last Name is required.
                                            </div>
                                            <div *ngIf="contactForm.get('lastname').errors?.patterd" class="error">
                                                Numbers are not allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="phonenumber" class="text-black">Phone Number</label>
                                            <input type="tel" name="phonenumber" value="" size="40" class="form-control"
                                                aria-invalid="false" formControlName="phonenumber">

                                            <div *ngIf="contactForm.get('phonenumber').errors?.required && submitted"
                                                class="error">
                                                Phone number is required.
                                            </div>
                                            <div *ngIf="contactForm.get('phonenumber').errors?.pattern" class="error">
                                                Enter a valid phone number.
                                            </div>

                                            <!-- <mat-error *ngIf="!forgetForm.get('phone_number').valid" style="font-size:10px;">
                                                Please enter your phone number
                                            </mat-error> -->
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="email" class="text-black">Email</label>
                                            <input type="email" name="email" value="" size="40" class="form-control"
                                                aria-invalid="false" formControlName="email">
                                            <div *ngIf="contactForm.get('email').errors?.required && submitted"
                                                class="error">
                                                Email id is required.
                                            </div>
                                            <div *ngIf="contactForm.get('email').errors?.pattern" class="error">
                                                Enter valid Email id
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="address" class="text-black">Address</label>
                                            <textarea name="address" cols="4" rows="4" class="form-control"
                                                aria-invalid="false" formControlName="address"></textarea>
                                            <div *ngIf="contactForm.get('address').errors?.required && submitted"
                                                class="error">
                                                Address is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="message" class="text-black">Message</label>
                                            <textarea name="message" cols="4" rows="4" class="form-control"
                                                aria-invalid="false" formControlName="message"></textarea>
                                            <div *ngIf="contactForm.get('message').errors?.required && submitted"
                                                class="error">
                                                Message is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="row"> 
                                    <div class="col-sm-12"> 
                                        <div class="form-group">
                                            <div class="captcha_main">
                                                <div class="captcha_image_main">
                                                    <img src="assets/images/code-image.png" class="img-fluid" style="margin-bottom: 10px;margin-right: 10px;;" alt="img">
                                                </div>
                                                <div class=" form_captcha_field" style="margin:0;">
                                                    <input type="text" class="form-control" placeholder="Enter Image Code" style="width:150px;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>-->
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <button type="submit" class="btn theme-btn btn-block"
                                            [disabled]="isLoading">Submit</button>
                                    </div>
                                </div>
                            </div>
                            <!-- </mat-form-field> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>