<div class="content-wrapper inner-wrap">
    <section class="client-referral-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10"
                            (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary"
                            (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12">
                    <div class="logo-div text-center mb-20">
                        <img src="assets/images/logo/logo.png" class="img-fluid">
                    </div>
                    <div class="card card-primary">
                        <div class="card-header">
                            <h4 class="mb-0" style="text-align:center;font-weight: 600;">Client Referral Form</h4>
                        </div>
                        <div class="card-body">

                            <form [formGroup]="clientReferral" (ngSubmit)="submit()">
                                <!-- <form novalidate #referralForm="ngForm" (submit)="submit(referralForm)"> -->
                                <div class="table-responsive">
                                    <table class="table table-hover table-bordered results table-sorter"
                                        style="width:100%;">
                                        <tbody>
                                            <tr style="background:#2480be;" class="form-title">
                                                <th colspan="12"
                                                    style="text-align:center;width:75%;font-weight: 600;color:#ffffff;border: 1px solid #2480be;">
                                                    Client Referral From ACT And NSW</th>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="vertical-align: middle;">
                                                    <label>Full name</label>
                                                    <input type="text" class="form-control" placeholder="Full Name"
                                                        name="name" formControlName="name" required>
                                                    <div *ngIf="clientReferral.get('name').errors?.required && submitted"
                                                        class="error">
                                                        Name is required.
                                                    </div>
                                                    <div *ngIf="clientReferral.get('name').errors?.pattern"
                                                        class="error">
                                                        Numbers are not allowed.
                                                    </div>
                                                </td>
                                                <td colspan="6" style="vertical-align: middle;">
                                                    <label>Gender</label>
                                                    <select class="form-control" name="gender" formControlName="gender">
                                                        <option value="" selected>Select gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="vertical-align: middle;">
                                                    <label>DOB</label>
                                                    <div class="form-group mb-0">
                                                        <div class="datepicker date input-group p-0">
                                                            <input class="form-control mt-7" placeholder="Choose a date"
                                                                name="dob" formControlName="dob" ngbDatepicker
                                                                #d="ngbDatepicker" [showWeekNumbers]="false"
                                                                id="datepicker" onfocus="this.blur()"
                                                                (click)="d.toggle()" 
                                                                [minDate]="{year: 1900, month: 1, day: 1}"
                                                                [maxDate]="maxDate">
                                                            <div class="input-group-append mt-7">
                                                                <span class="input-group-text" (click)="d.toggle()">
                                                                    <i class="fas fa-calendar-alt"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td colspan="4" style="vertical-align: middle;">
                                                    <label>NDIS/My aged care plan Start date</label>
                                                    <div class="form-group mb-0">
                                                        <div class="datepicker date input-group p-0">
                                                            <input class="form-control mt-7" placeholder="Choose a date"
                                                                name="start_date" formControlName="start_date"
                                                                ngbDatepicker #d1="ngbDatepicker"
                                                                [showWeekNumbers]="false" id="datepicker"
                                                                onfocus="this.blur()" [minDate]="{year: 1900, month: 1, day: 1}"
                                                                [maxDate]="{year: 2048, month: 12, day: 31}" (click)="d1.toggle()">
                                                            <div class="input-group-append mt-7">
                                                                <span class="input-group-text" (click)="d1.toggle()">
                                                                    <i class="fas fa-calendar-alt"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td colspan="4" style="vertical-align: middle;">
                                                    <label>NDIS/My aged care plan End date</label>
                                                    <div class="form-group mb-0">
                                                        <div class="datepicker date input-group p-0">
                                                            <input class="form-control mt-7" placeholder="Choose a date"
                                                                name="end_date" formControlName="end_date" ngbDatepicker
                                                                #d2="ngbDatepicker" [showWeekNumbers]="false"
                                                                [disabled]="!clientReferral.get('start_date').value"
                                                                id="datepicker" [minDate]="clientReferral.get('start_date').value"
                                                                [maxDate]="{year: 2048, month: 12, day: 31}" onfocus="this.blur()"
                                                                (click)="d2.toggle()">
                                                            <div class="input-group-append mt-7">
                                                                <span class="input-group-text" (click)="d2.toggle()">
                                                                    <i class="fas fa-calendar-alt"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="12" style="vertical-align: middle;">
                                                    <label>Address</label>
                                                    <textarea class="form-control" row="4" placeholder="Address"
                                                        name="address" formControlName="address"></textarea>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="12" style="vertical-align: middle;">
                                                    <label>Email</label>
                                                    <input type="email" class="form-control" placeholder="Email"
                                                        name="email" formControlName="email" required>
                                                    <div *ngIf="clientReferral.get('email').errors?.required && submitted"
                                                        class="error">
                                                        Email is required.
                                                    </div>
                                                    <div *ngIf="clientReferral.get('email').errors?.pattern"
                                                        class="error">
                                                        Enter a valid email.
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="vertical-align: middle;">
                                                    <label>Phone (Home)</label>
                                                    <input type="text" class="form-control" placeholder="Phone (Home)"
                                                        name="phonenumber" formControlName="phonenumber">
                                                    <div *ngIf="clientReferral.get('phonenumber').errors?.pattern"
                                                        class="error">
                                                        Enter a vaid phone number.
                                                    </div>
                                                </td>
                                                <td colspan="6" style="vertical-align: middle;">
                                                    <label>Mobile</label>
                                                    <input type="text" class="form-control" placeholder="Mobile"
                                                        name="mobile" formControlName="mobile">
                                                    <div *ngIf="clientReferral.get('mobile').errors?.pattern"
                                                        class="error">
                                                        Enter a vaid mobile number.
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="vertical-align: middle;">
                                                    <label>Self Managed</label>
                                                    <select class="form-control" name="self_managed"
                                                        formControlName="self_managed">
                                                        <option value="" selected>Choose option</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </td>
                                                <td colspan="6" style="vertical-align: middle;">
                                                    <label>Funding Type</label>
                                                    <input type="text" class="form-control" placeholder="Other"
                                                        name="funding_type" formControlName="funding_type">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="vertical-align: middle;">
                                                    <label>NDIS/My aged care Number</label>
                                                    <input type="text" class="form-control"
                                                        placeholder="Reference Number" name="reference_number"
                                                        formControlName="reference_number">
                                                </td>
                                                <td colspan="6" style="vertical-align: middle;">
                                                    <label>Coordinator</label>
                                                    <input type="text" class="form-control" placeholder="Coordinator"
                                                        name="coordinator" formControlName="coordinator">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="vertical-align: middle;">
                                                    <label>Plan manager</label>
                                                    <input type="text" class="form-control" placeholder="Plan Manager"
                                                        name="plan_manager" formControlName="plan_manager">
                                                </td>
                                                <td colspan="6" style="vertical-align: middle;">
                                                    <label>Invoice Email</label>
                                                    <input type="email" class="form-control" placeholder="Invoice Email"
                                                        name="invoice_email" formControlName="invoice_email">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="vertical-align: middle;">
                                                    <label>Referring Organization</label>
                                                    <input type="text" class="form-control"
                                                        placeholder="Referring Organization"
                                                        name="referring_organization"
                                                        formControlName="referring_organization">
                                                </td>
                                                <td colspan="6" style="vertical-align: middle;">
                                                    <label>Primary Diagnosis</label>
                                                    <input type="text" class="form-control"
                                                        placeholder="Primary Diagnosis" name="primary_diagnosis"
                                                        formControlName="primary_diagnosis">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="12" style="vertical-align: middle;">
                                                    <label class="mr-2">Specific Requirements</label>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label">
                                                            <input type="checkbox" class="form-check-input"
                                                                (click)="handleRequirement($event)" value="Peg"
                                                                [checked]="itemChecked('Peg')">
                                                            Peg
                                                        </label>
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label">
                                                            <input type="checkbox" class="form-check-input"
                                                                (click)="handleRequirement($event)" value="Bowel Care"
                                                                [checked]="itemChecked('Bowel Care')">
                                                            Bowel Care
                                                        </label>
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label">
                                                            <input type="checkbox" class="form-check-input"
                                                                (click)="handleRequirement($event)"
                                                                value="Catheter Care"
                                                                [checked]="itemChecked('Catheter Care')">
                                                            Catheter Care
                                                        </label>
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label">
                                                            <input type="checkbox" class="form-check-input"
                                                                (click)="handleRequirement($event)"
                                                                value="Insulin Delivery"
                                                                [checked]="itemChecked('Insulin Delivery')">
                                                            Insulin Delivery
                                                        </label>
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label">
                                                            <input type="checkbox" class="form-check-input"
                                                                (click)="handleRequirement($event)" value="Behavior"
                                                                [checked]="itemChecked('Behavior')">
                                                            Behavior
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="table-responsive schedule-of-support-div">
                                    <table class="table table-hover table-bordered results table-sorter"
                                        style="width:100%;">
                                        <tbody>
                                            <tr style="background:#eee;">
                                                <th colspan="12" style="font-weight: 600;vertical-align: middle;" class="subtitle">
                                                    Required Schedule of support</th>
                                            </tr>
                                            <tr>
                                                <td colspan="1" style="vertical-align: middle;"></td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    Morning</td>
                                                <td colspan="2" style="vertical-align: middle;">Midday
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    Afternoon</td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    Evening</td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" style="vertical-align: middle;">Monday
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="monday_morning"
                                                        formControlName="monday_morning">
                                                </td>
                                                <td colspan="2" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="monday_midday"
                                                        formControlName="monday_midday">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="monday_afternoon"
                                                        formControlName="monday_afternoon">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="monday_evening"
                                                        formControlName="monday_evening">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" style="vertical-align: middle;">
                                                    Tuesday</td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="tuesday_morning"
                                                        formControlName="tuesday_morning">
                                                </td>
                                                <td colspan="2" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="tuesday_midday"
                                                        formControlName="tuesday_midday">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="tuesday_afternoon"
                                                        formControlName="tuesday_afternoon">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="tuesday_evening"
                                                        formControlName="tuesday_evening">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" style="vertical-align: middle;">
                                                    Wednesday</td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="wednesday_morning"
                                                        formControlName="wednesday_morning">
                                                </td>
                                                <td colspan="2" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="wednesday_midday"
                                                        formControlName="wednesday_midday">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="wednesday_afternoon"
                                                        formControlName="wednesday_afternoon">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="wednesday_evening"
                                                        formControlName="wednesday_evening">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" style="vertical-align: middle;">
                                                    Thursday</td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="thursday_morning"
                                                        formControlName="thursday_morning">
                                                </td>
                                                <td colspan="2" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="thursday_midday"
                                                        formControlName="thursday_midday">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="thursday_afternoon"
                                                        formControlName="thursday_afternoon">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="thursday_evening"
                                                        formControlName="thursday_evening">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" style="vertical-align: middle;">Friday
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="friday_morning"
                                                        formControlName="friday_morning">
                                                </td>
                                                <td colspan="2" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="friday_midday"
                                                        formControlName="friday_midday">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="friday_afternoon"
                                                        formControlName="friday_afternoon">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="friday_evening"
                                                        formControlName="friday_evening">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" style="vertical-align: middle;">
                                                    Saturday</td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="saturday_morning"
                                                        formControlName="saturday_morning">
                                                </td>
                                                <td colspan="2" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="saturday_midday"
                                                        formControlName="saturday_midday">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="saturday_afternoon"
                                                        formControlName="saturday_afternoon">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="saturday_evening"
                                                        formControlName="saturday_evening">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" style="vertical-align: middle;">Sunday
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="sunday_morning"
                                                        formControlName="sunday_morning">
                                                </td>
                                                <td colspan="2" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="sunday_midday"
                                                        formControlName="sunday_midday">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="sunday_afternoon"
                                                        formControlName="sunday_afternoon">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="sunday_evening"
                                                        formControlName="sunday_evening">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" style="vertical-align: middle;">24/7
                                                    care</td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="care_247_morning"
                                                        formControlName="care_247_morning">
                                                </td>
                                                <td colspan="2" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="care_247_midday"
                                                        formControlName="care_247_midday">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="care_247_afternoon"
                                                        formControlName="care_247_afternoon">
                                                </td>
                                                <td colspan="3" style="vertical-align: middle;">
                                                    <input type="text" class="form-control" name="care_247_evening"
                                                        formControlName="care_247_evening">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-hover table-bordered results table-sorter"
                                        style="width:100%;">
                                        <tbody>
                                            <tr style="background:#eee;">
                                                <th colspan="12" style="font-weight: 600;vertical-align: middle;" class="subtitle">
                                                    Primary Carer/ NOK /Guardian </th>
                                            </tr>
                                            <tr>
                                                <td colspan="4" style="vertical-align: middle;">Name</td>
                                                <td colspan="8" style="vertical-align: middle;font-weight:400;">
                                                    <input type="text" class="form-control" placeholder="Name"
                                                        name="guardian_name" formControlName="guardian_name">
                                                    <div *ngIf="clientReferral.get('guardian_name').errors?.required && submitted"
                                                        class="error">
                                                        Guardian Name is required.
                                                    </div>
                                                    <div *ngIf="clientReferral.get('guardian_name').errors?.pattern"
                                                        class="error">
                                                        Numbers are not allowed.
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" style="vertical-align: middle;">Phone</td>
                                                <td colspan="8" style="vertical-align: middle;font-weight:400;">
                                                    <input type="text" class="form-control" placeholder="Phone"
                                                        name="guardian_phonenumber"
                                                        formControlName="guardian_phonenumber">
                                                    <div *ngIf="clientReferral.get('guardian_phonenumber').errors?.required && submitted"
                                                        class="error">
                                                        Guardian Phone is required.
                                                    </div>
                                                    <div *ngIf="clientReferral.get('guardian_phonenumber').errors?.pattern"
                                                        class="error">
                                                        Enter a valid number.
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" style="vertical-align: middle;">Email</td>
                                                <td colspan="8" style="vertical-align: middle;font-weight:400;">
                                                    <input type="email" class="form-control" placeholder="Email"
                                                        name="guardian_email" formControlName="guardian_email">
                                                    <div *ngIf="clientReferral.get('guardian_email').errors?.required && submitted"
                                                        class="error">
                                                        Guardian Email is required.
                                                    </div>
                                                    <div *ngIf="clientReferral.get('guardian_email').errors?.pattern"
                                                        class="error">
                                                        Enter a valid email.
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" style="vertical-align: middle;">Relationship to
                                                    Participant</td>
                                                <td colspan="8" style="vertical-align: middle;font-weight:400;">
                                                    <input type="text" class="form-control" placeholder="Relationship"
                                                        name="guardian_relationship"
                                                        formControlName="guardian_relationship">
                                                    <div *ngIf="clientReferral.get('guardian_relationship').errors?.required && submitted"
                                                        class="error">
                                                        Guardian relationship is required.
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="12" style="font-weight: 600;vertical-align: middle;">
                                                    <p style="color:#000000;"><strong>Note:</strong> Please complete to
                                                        ensure swift action on your referral. If sections fail to be
                                                        completed in full, we will be unable to process your referral at
                                                        that point in time. If you have any questions or require
                                                        assistance, please contact Caring Approach on <a href="tel:+1300655026">1300 655 026 </a>or 02
                                                        6253 1017. </p>
                                                </td>
                                            </tr>
                                            <tr class="btn-row">
                                                <td colspan="11"></td>
                                                <td colspan="1" style="font-weight: 300; vertical-align: middle;">
                                                    <div class="btn-group action-btns float-right" role="group"
                                                        aria-label="Basic example">
                                                        <button type="submit" class="btn theme-btn mr-10"
                                                            [disabled]="isLoading">
                                                            <span class="spinner-border spinner-border-md" role="status"
                                                                aria-hidden="true"
                                                                *ngIf="isLoading"></span>Submit</button>
                                                        <button type="button" class="btn btn-info mr-10" onclick="window.print()">Print</button>
                                                        <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>