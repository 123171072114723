import { environment } from "src/environments/environment";

const baseUrl = environment.API_URL + 'api/';
const notificationBaseUrl = environment.API_URL;

export const GlobalConstants = {
  endPoints: {
    // auth apis
    LOGIN_USER: `${baseUrl}employee/login`,
    FORGOT_PASSWORD: `${baseUrl}employee/forgotPassword`,
    VERIFY_OTP: `${baseUrl}employee/matchOTP`,
    RESET_PASSWORD: `${baseUrl}employee/resetPassword`,
    CHANGE_PASSWORD: `${baseUrl}employee/changePassword`,
    //user profile
    GET_PROFILE: `${baseUrl}employee/getProfileData`,
    UPDATE_PROFILE: `${baseUrl}employee/profileUpdate`,
    UPDATE_PROFILE_IMAGE: `${baseUrl}employee/profileImageUpdate`,
    // add data apis
    GET_COUNTRIES: `${baseUrl}general/getCountry`,
    GET_STATES: `${baseUrl}general/getState`,
    GET_CITIES: `${baseUrl}general/getCity`,
    // motivational quotes
    QUOTE_OF_THE_DAY: `${baseUrl}general/getQuotesOfTheDay`,
    //new emp
    ADD_NEW_EMP: `${baseUrl}employee/addNewEmployeeForm`,
    GET_EMP_FORM: `${baseUrl}employee/getEmployeeForm`,
    // raoster apis
    GET_MY_ROASTER: `${baseUrl}employee/getMyRoster`,
    //DOCUMENT APIS
    DOCUMENT_LIST: `${baseUrl}employee/getMyDocument`,
    ADD_DOCUMENT: `${baseUrl}employee/addDocument`,
    UPDATE_DOCUMENT: `${baseUrl}employee/updateDocument`,
    DELETE_DOCUMENT: `${baseUrl}employee/deleteDocument`,
    DOCUMENT_DETAIL: `${baseUrl}employee/getMyDocumentDetail`,
    // police, wwpc, firstaid check
    ADD_POLICE_CHECK: `${baseUrl}employee/addPoliceCheck`,
    ADD_WWCC_CHECK: `${baseUrl}employee/addwwcc`,
    ADD_FIRSTAID_CHECK: `${baseUrl}employee/addFirstaid`,
    //upcomming tranings
    GET_UPCOMMING_TRANINGS: `${baseUrl}employee/getUpcomingTraning`,
    //policies
    GET_POLICY: `${baseUrl}employee/getPolicy`,
    // stress profile
    GET_STRESS_PROFILE: `${baseUrl}employee/getMyStressProfile`,
    // client list
    GET_CLIENT_LIST: `${baseUrl}employee/getMyClients`,
    //leaves
    GET_ALL_LEAVES: `${baseUrl}employee/getMyLeave`,
    GET_LEAVE_TYPE: `${baseUrl}general/getLeaveType`,
    ADD_LEAVE: `${baseUrl}employee/addLeave`,
    LEAVE_DETAIL: `${baseUrl}employee/getLeaveDetail`,
    DELETE_LEAVE: `${baseUrl}employee/deleteLeave`,
    UPDATE_LEAVE: `${baseUrl}employee/updateLeave`,
    // incident apis
    GET_REPORT_INCIDENT_LIST: `${baseUrl}employee/getReportIncidentList`,
    ADD_REPORT_INCIDENT:  `${baseUrl}employee/addReportIncident`,
    //review
    ADD_REVIEW: `${baseUrl}employee/addReview`,
    //support
    GET_TICKET_PRIORITY: `${baseUrl}general/getTicketPriorities`,
    GET_SUPPORT_DEPARTMENT: `${baseUrl}general/getSupportDepartments`,
    ADD_SUPPORT_TICKET: `${baseUrl}employee/addSupportTicket`,
    GET_MY_TICKET: `${baseUrl}employee/getMyTicket`,
    // notice 
    GET_NOTICE: `${baseUrl}employee/getNotice`,
    GET_NOTICE_DETAIL: `${baseUrl}employee/getNoticeDetail`,
    //notifications
    GET_NOTIFICATION: `${notificationBaseUrl}getNotification`,
    READ_NOTIFICATION: `${notificationBaseUrl}notificationRead`,
  },
  errorMessages: {
    INVALID_DETAILS: 'Please enter valid details',
    EMP_CHECKS_INVALID: 'Employee ID checks should be greater than 100',
    SIGNATURE_REQUIRED: 'Signature required',
    BOTH_CHECKBOX:'Please select both checkboxes',
    DOCUMENT_REQUIRED: 'Document is required',
    INVALID_FILE: 'Invalid file',
    VALID_OTP: 'Please enter the valid OTP',
    FILE_SIZE: 'Please select a file below 2MB',
    FILE_TYPE: 'Please select valid file type'
  }
}