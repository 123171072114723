import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicapiService } from '../services/frontapi/dynamicapi.service';
declare let $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  aboutData:any=[];
  yourlifeData:any=[];
  testimonialsData:any=[];
  constructor(private _store:Store, private DynamicapiService: DynamicapiService) {
    this.isUserLoggedIn();
  }


  isUserLoggedIn(){
    const storage = localStorage.getItem('remember') === 'true' ? 'localStorage' : 'sessionStorage';
    const userMeta = (window[storage] as any)?.getItem('auth');
    if(userMeta){
      this._store.dispatch({ type:'LOGIN_SUCCESS', payload: JSON.parse(userMeta) });
    }
  }
  ngOnInit(): void {

    //About
    this.DynamicapiService.aboutData().subscribe(
      (response:any) => {
        this.aboutData = response.result;
         console.log(response);
    }, (_err:any) => {
      
    })

       //Your Life 
    this.DynamicapiService.yourlifeData().subscribe(
      (response:any) => {
        this.yourlifeData = response.result;
         console.log(response);
    }, (_err:any) => {
      
    })

     //Testimonials
    this.DynamicapiService.testimonialsData().subscribe(
      (response:any) => {
        this.testimonialsData = response.result;
         console.log(response);
    }, (_err:any) => {
      
    })

    $(function() {

      $(".nav-link").click(function() {
        // remove classes from all
        console.log(this);
        $(".nav-link").find(".active").removeClass("active");
        $(".nav-link").removeClass("active");
        // add class to the one we clicked
        $(this).addClass("active");
      });

    });
    
    $('.single-item').slick({
      infinite: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 1500,
      speed: 1500,
      cssEase: 'ease-in-out',
      slidesToShow: 4,
      prevArrow: '<button class="slide-arrow prev-arrow btn-primary" style="border-radius: 50%;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;;bottom: -30px;z-index: 1;left: 42%;"><i class="fas fa-chevron-left"></i></button>',
      nextArrow: '<button class="slide-arrow next-arrow btn-primary" style="border-radius: 50%;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;bottom: -30px;z-index: 1;right:42%;"><i class="fas fa-chevron-right"></i></button>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          prevArrow: '<button class="slide-arrow prev-arrow btn-primary" style="border-radius: 50%;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;;bottom: -30px;z-index: 1;left: 15%;"><i class="fas fa-chevron-left"></i></button>',
          nextArrow: '<button class="slide-arrow next-arrow btn-primary" style="border-radius: 50%;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;bottom: -30px;z-index: 1;right:15%;"><i class="fas fa-chevron-right"></i></button>',
          }
        },
        {
          breakpoint: 667,
          settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: '<button class="slide-arrow prev-arrow btn-primary" style="border-radius: 50%;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;;bottom: -30px;z-index: 1;left: 15%;"><i class="fas fa-chevron-left"></i></button>',
          nextArrow: '<button class="slide-arrow next-arrow btn-primary" style="border-radius: 50%;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;bottom: -30px;z-index: 1;right:15%;"><i class="fas fa-chevron-right"></i></button>',
          }
        }
      ]      
    });

    $('.quote-container').mousedown(function(){
      $('.single-item').addClass('dragging');
    });
    $('.quote-container').mouseup(function(){
      $('.single-item').removeClass('dragging');
    }); 
  }

  
}
