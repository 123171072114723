import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
// import { SigninComponent } from './modules/signin/signin.component';
// import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegisterComponent } from './register/register.component';
// import { ChangePasswordComponent } from './change-password/change-password.component';
import { ReportsComponent } from './reports/reports.component';
import { PrescriptionComponent } from './prescription/prescription.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { PolicyComponent } from './policy/policy.component';
import { CareerComponent } from './career/career.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { ResourcesComponent } from './resources/resources.component';
import { NdisChecklistComponent } from './ndis-checklist/ndis-checklist.component';
import { ParticipantsComponent } from './participants/participants.component';
import { AccreditationComponent } from './accreditation/accreditation.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SupportCoordinationComponent } from './support-coordination/support-coordination.component';
import { AccomodationComponent } from './accomodation/accomodation.component';
import { AgedCareComponent } from './aged-care/aged-care.component';
import { PlanManagementComponent } from './plan-management/plan-management.component';
import { CommunityComponent } from './community/community.component';
import { DisabilityCareComponent } from './disability-care/disability-care.component';
import { DementiaCareComponent } from './dementia-care/dementia-care.component';
import { RecoveryCareComponent } from './recovery-care/recovery-care.component';
import { TransportComponent } from './transport/transport.component';
import { AutismComponent } from './autism/autism.component';
import { NewMumsComponent } from './new-mums/new-mums.component';
import { RespiteHelpComponent } from './respite-help/respite-help.component';
import { NursingCareComponent } from './nursing-care/nursing-care.component';
import { ClientReferralFormComponent } from './client-referral-form/client-referral-form.component';
import { GalleryInnerComponent } from './gallery-inner/gallery-inner.component'
import { AuthenticateService } from './services/authServices/auth.service'
import { GalleryYearComponent } from './gallery-year/gallery-year.component';
// import { GalleryEventComponent } from './gallery-event/gallery-event.component';
import { AuthGuard } from './services/guards/auth-guard.service';
import { GalleryEventComponent } from './gallery-event/gallery-event.component';
import { AuthService } from './services/auth.service';

const routes: Routes = [{
  path: 'home',
  component: HomeComponent,
  data: {
  title: 'caring-approach - Home',
  description: '',
  ogUrl: 'your og url1'}
}, {
  path: 'signin',
  loadChildren: () => import('./modules/signin/signin.module').then(m => m.SigninModule),
  data: {
  title: 'caring-approach - Signin',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'forgot-password',
  loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  
}, {
  path: 'register',
  component: RegisterComponent,
  data: {
  title: 'caring-approach - Register',
  description: '',
  ogUrl: 'your og url'}
}, 
// {
//   path: 'change-password',
//   component: ChangePasswordComponent,
//   data: {
//   title: 'caring-approach - Change Password',
//   description: '',
//   ogUrl: 'your og url'}
// }, 
{
  path: 'reports',
  component: ReportsComponent,
  data: {
  title: 'caring-approach - Reports',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'prescription',
  component: PrescriptionComponent,
  data: {
  title: 'caring-approach - Prescription',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'appointment',
  component: AppointmentComponent,
  data: {
  title: 'caring-approach - Appointment',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'contact-us',
  component: ContactUsComponent,
  data: {
  title: 'caring-approach - Contact Us',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'about-us',
  component: AboutUsComponent,
  data: {
  title: 'caring-approach - About Us',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'our-services',
  component: OurServicesComponent,
  data: {
  title: 'caring-approach - Our Services',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'policy',
  component: PolicyComponent,
  data: {
  title: 'caring-approach - Policy',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'career',
  component: CareerComponent,
  data: {
  title: 'caring-approach - Career',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'testimonials',
  component: TestimonialsComponent,
  data: {
  title: 'caring-approach - Testimonials',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'feedback',
  component: FeedbackComponent,
  data: {
  title: 'caring-approach - Feedback',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'resources',
  component: ResourcesComponent,
  data: {
  title: 'caring-approach - Resources',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'ndis-checklist',
  component: NdisChecklistComponent,
  data: {
  title: 'caring-approach - Ndis Checklist',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'participants',
  component: ParticipantsComponent,
  data: {
  title: 'caring-approach - Participants',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'accreditation',
  component: AccreditationComponent,
  data: {
  title: 'caring-approach - Accreditation',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'gallery',
  component: GalleryComponent,
  data: {
  title: 'caring-approach - Gallery',
  description: '',
    ogUrl: 'your og url'
  },
  children: [
    {path: '', redirectTo: 'year', pathMatch: 'full'},
    {
      path: 'year',
      component: GalleryYearComponent,
      data: {
        title: 'caring-approach - Gallery',
        description: '',
        ogUrl: 'your og url'
      }
    },
    {
      path: 'event/:folderid',
      component: GalleryEventComponent,
      data: {
        title: 'caring-approach - Gallery',
        description: '',
        ogUrl: 'your og url'
      }
    },
    {
      path: 'gallery-inner/:folderid/:eventid',
      component: GalleryInnerComponent,
      data: {
        title: 'caring-approach - Gallery',
        description: '',
        ogUrl: 'your og url'
      }
    }

  ]
}, {
  path: 'support-coordination',
  component: SupportCoordinationComponent,
  data: {
  title: 'caring-approach - Support Coordination',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'accomodation',
  component: AccomodationComponent,
  data: {
  title: 'caring-approach - Accomodation',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'aged-care',
  component: AgedCareComponent,
  data: {
  title: 'caring-approach - Aged Care',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'plan-management',
  component: PlanManagementComponent,
  data: {
  title: 'caring-approach - Plan Management',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'community',
  component: CommunityComponent,
  data: {
  title: 'caring-approach - Community',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'disability-care',
  component: DisabilityCareComponent,
  data: {
  title: 'caring-approach - Disability Care',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'dementia-care',
  component: DementiaCareComponent,
  data: {
  title: 'caring-approach - Dementia Care',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'recovery-care',
  component: RecoveryCareComponent,
  data: {
  title: 'caring-approach - Recovery Care',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'transport',
  component: TransportComponent,
  data: {
  title: 'caring-approach - Transport',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'autism',
  component: AutismComponent,
  data: {
  title: 'caring-approach - Autism',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'new-mums',
  component: NewMumsComponent,
  data: {
  title: 'caring-approach - New Mums',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'respite-help',
  component: RespiteHelpComponent,
  data: {
  title: 'caring-approach - Respite Help',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'nursing-care',
  component: NursingCareComponent,
  data: {
  title: 'caring-approach - Nursing Care',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'gallery-inner',
  component: GalleryInnerComponent,
  data: {
  title: 'caring-approach - Gallery Inner',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'client-referral-form',
  component: ClientReferralFormComponent,
  data: {
  title: 'caring-approach - Client Referral Form',
  description: '',
  ogUrl: 'your og url'}
}, {
  path: 'new-password',
  loadChildren: ()=> import('./modules/new-password/new-password.module').then(m => m.NewPasswordModule)
}, 
// {
//   path: 'otp-confirmation',
//   component: OtpConfirmationComponent,
//   data: {
//   title: 'caring-approach - Otp Confirmation',
//   description: '',
//   ogUrl: 'your og url'}
// }, 
{
  path: 'client',
  canActivate:[AuthenticateService],
  loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule),
  },
  {
  path: 'client/:token',
  canActivate:[AuthenticateService],
  loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule),
  },
  {
    path: 'client/:token/:id',
    canActivate:[AuthenticateService],
    loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule),
  },
  {
  path: 'employee',
  loadChildren: () => import('./modules/employee/employee.module').then(m => m.EmployeeModule),
  canActivate: [AuthService]
  // loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
}, {
  path: '',
  redirectTo: 'home',
  pathMatch: 'full'
}, { path: '**', component: PageNotFoundComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
  enableTracing: false,
  useHash: false,
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload',
  preloadingStrategy: PreloadAllModules
})],
exports: [RouterModule],
providers:[AuthenticateService]
})
export class AppRoutingModule { }
