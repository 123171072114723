<section class="accreditation-section">  
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Accreditation</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">Accreditation</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="accreditation-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <h3>Accreditation</h3>
                </div>
            </div>
            <!--<div class="col-md-12 col-12">
                <div class="card border-0 mb-15">
                    <div class="card-body">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-md-3">
                                <div class="img-wrap">
                                    <img src="assets/images/accreditation.jpg" class="img-fluid">
                                </div>
                            </div>
                            <div class="col-md-9">
                                <h4>Did you know that you have rights?</h4>
                                <hr>
                                <p>Caring Approach is regularly assessed by external organisations to ensure it provides a superior level of service and exceeds the quality standards of the NDIS Quality & Safeguards Commission and the My Aged Care Quality & Safety Commission.</p>
                                <p>Caring Approach is committed to continuous improvement and providing service excellence, value for money and a person-centred approach. To find out more about Caring Approach’s commitment to the standards please go to the “about us” page on this website.</p>                     
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <div class="row align-items-center justify-content-center">
                                <!--<div class="col-md-3">
                                    <div class="img-wrap" style="width:250px;margin: 0 auto;">
                                        <img src="assets/images/bsi-iso.jpg" class="img-fluid">
                                    </div>
                                </div>-->
                                <div class="col-md-12">
                                    <p>Caring Approach knows the critical importance of being accredited and having the right quality management system in place to provide confidence in the services for the people we support and we provide a service that exceeds expectations. Caring Approach`s quality management system has been certified to meet ISO 9001:2015 Quality Management standards by BSI (British Standards Institute)  for our outstanding services, systems , policies, procedures and our ability to consistently meet and exceed customer expectations and relevant standards.</p> 
                                    <p>With regular third-party audits for our NDIS and Aged Care services and with a quality management system certification that meets international standards, you can be rest assured the service you receive through Caring Approach is of the highest quality for you and / or your loved one.</p> 
                                    <p>Caring Approach has been voted by the community in the Hornsby region Local Business awards,  winners in 2019, finalists in 2020 and a finalist in 2020 for the Canberra region within the Health Improvement Category.</p>
                                    <p>We work with you to provide confidence and assurance in our services and your care.</p>
                                    <!--s<p>Caring Approach knows the critical importance of having the right quality management system in place in order to provide confidence in the services for the people we support and we provide a service that exceeds expectations. Caring Approach`s quality management system has been certified to meet ISO 9001:2015 Quality Management standards by BSI (British Standards Institute) for our outstanding services, systems , policies, procedures and our ability to consistently meet and exceed customer expectations and relevant standards.</p>

                                    <p>With regular third party audits and a quality management system certification that meets international standards, you can be rest assured the service you receive through Caring Approach is of the highest quality for you and / or your loved one.</p> 
                                    
                                    <p>Caring Approach has been voted by the community in the Hornsby region Local Business awards, winners in 2019, finalists in 2020 and a finalist in 2020 for the Canberra region within the Health Improvement Category.</p>
                                    
                                    <p>We work with you to provide confidence and assurance in our services and your care.</p>-->     
                                    <hr>
                                    <a class="btn theme-btn my-2 mr-4 my-sm-0" href="assets/pdf/CA-BSI-ISO.pdf" target="_blank">View Certificate</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-body text-center">
                            <h4 class="mt-15">Tell us how we can support you for a fully tailored service !</h4>
                            <h4><strong><a href="tel:+1300655026">Call 1300 655 026</a></strong></h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-body text-center">
                            <div class="row">
                                <div class="col-lg-3 col-sm-6 col-12">      
                                    <div class="logo-wrapper">
                                        <img src="assets/images/bsi-iso.jpg" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <div class="logo-wrapper">
                                        <img src="assets/images/canberra-finalist.png" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <div class="logo-wrapper">
                                    <img src="assets/images/winner-ku-ring.png" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <div class="logo-wrapper">
                                        <img src="assets/images/finalist-ku-ring.png" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
