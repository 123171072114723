import { GalleryService } from './../services/gallery/gallery.service';
import { Component, OnInit } from '@angular/core';

interface ResponseType{
  result: any,
  status: boolean,
  message: string
}

@Component({
  selector: 'app-gallery-year',
  templateUrl: './gallery-year.component.html',
  styleUrls: ['./gallery-year.component.scss']
})
export class GalleryYearComponent implements OnInit {

  galleryYearList = [];
  
  constructor(private _galleryService: GalleryService) { }

  ngOnInit(): void {
    this.getGalleryYear();
  }

  getGalleryYear() {
    this._galleryService.getGalleryYear().subscribe((response: ResponseType) =>{
      if(response.status) {
        this.galleryYearList = response.result
      }
    })
  }

}
