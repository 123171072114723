import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { TransportService } from './transport.service';
import { ToastrService } from 'ngx-toastr'

declare let $: any;
@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss']
})
export class TransportComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: TransportService,
    private toast:ToastrService,
    private el: ElementRef
  ) { }

  transportForm: FormGroup;
  toggleBooking:boolean = false;
  readonly DT_FORMAT = 'MM/DD/YYYY';
  submitted = false;
  returnUrl: string;
  maxDate= {year: new Date().getFullYear(), month: new Date().getMonth() +1 , day:new Date().getDay()}
  transportMinDate= {year: new Date().getFullYear(), month: new Date().getMonth() +1 , day:new Date().getDay()}
  ngOnInit(): void {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';


    $(".testimonial-reel").slick({
      centerMode: true,
      autoplay: true,
      centerPadding: "40px",
      dots: false,
      slidesToShow: 3,
      infinite: true,
      arrows: true,
      lazyLoad: "ondemand",
      prevArrow: '<button class="slide-arrow prev-arrow btn-primary" style="border-radius: 50%;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;top: 13%;z-index: 1;left:0px;"><i class="fas fa-chevron-left"></i></button>',
      nextArrow: '<button class="slide-arrow next-arrow btn-primary" style="border-radius: 50%;border: 0;padding: 5px 12px;background-color: #2480be;position: absolute;top: 13%;z-index: 1;right:0px;"><i class="fas fa-chevron-right"></i></button>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            centerMode: false
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
   }

   initiateForm(){
      this.transportForm = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]+')]),
        phonenumber: new FormControl('', [Validators.required,  Validators.pattern('[- +()0-9]{9,12}')]),
        email: new FormControl('', [Validators.required, Validators.email]),
        date_of_transport: new FormControl(null),
        booking_pickup_time: new FormControl('', [Validators.pattern('(1[0-2]|0?[1-9]):([0-5][0-9]) ?([ AaPp][Mm])')]),
        no_of_passengers:new FormControl('', [Validators.pattern('[0-9]{1,3}')]),
        pickup_location: new FormControl(''),
        destination: new FormControl(''),
        return_trip: new FormControl(''),
        pickup_time: new FormControl(''),
        is_driver_required_to_wait: new FormControl(''),
        wheelchair_accesible_transport: new FormControl(''),
        note: new FormControl(''),
        ndis_number: new FormControl(''),
        partcipant_dob:new FormControl(null),
        coordinator: new FormControl(''),
        plan_manager: new FormControl(''),
        claim_ndis: new FormControl(''),
        invoices_to: new FormControl(''),
        location:new FormControl(''),
        office_date: new FormControl(''),
        booking_completed_by:new FormControl(''),
        signature:new FormControl('')
    })
   }

   toggleBookingForm(){
     this.initiateForm()
     this.toggleBooking = !this.toggleBooking;
   }
    // convenience getter for easy access to form fields
    get f() { return this.transportForm.controls}; 

  isLoading:boolean = false;

  addSignaturePadFile(image){
    // this.formModel.signature = image;
  }
  parseDate(date){
    if(date?.year){
      return `${date.day}-${date.month}-${date.year}`
    }else{
      return "";
    }
  }

  transportSubmit(){
    if (this.transportForm.invalid) {
      this.submitted = true;
      this.transportForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      return;
    }else{
      this.submitted = false;
      const payLoad = JSON.parse(JSON.stringify(this.transportForm.value));
      payLoad.date_of_transport = this.parseDate(this.transportForm.get('date_of_transport').value)
      payLoad.partcipant_dob = this.parseDate(this.transportForm.get('partcipant_dob').value)
      payLoad.office_date = this.parseDate(this.transportForm.get('office_date').value)
      this.isLoading = true;
      this.service.addTransport(payLoad)
      .subscribe((response:any)=>{
        this.isLoading = false;
        if(response.status){
          // form.resetForm();
          this.toggleBookingForm()
          this.toast.success(response.message)
        }else{
          this.toast.error(response.message)
        }
      })

    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  decreasefontsize(){
    $('.inner-wrap h1').css('font-size', '-=1');
    $('.inner-wrap h2').css('font-size', '-=1');
    $('.inner-wrap h3').css('font-size', '-=1');
    $('.inner-wrap h4').css('font-size', '-=1');
    $('.inner-wrap h5').css('font-size', '-=1');
    $('.inner-wrap h6').css('font-size', '-=1');
    $('.inner-wrap p').css('font-size', '-=1');
    $('.inner-wrap a').css('font-size', '-=1');
    $('.inner-wrap li').css('font-size', '-=1');
    $('.inner-wrap span').css('font-size', '-=1');
  }
  increasefontsize(){
    $('.inner-wrap p').css('font-size', '+=2');
    $('.inner-wrap h1').css('font-size', '+=2');
    $('.inner-wrap h2').css('font-size', '+=2');
    $('.inner-wrap h3').css('font-size', '+=2');
    $('.inner-wrap h4').css('font-size', '+=2'); 
    $('.inner-wrap h5').css('font-size', '+=2');
    $('.inner-wrap h6').css('font-size', '+=2');
    $('.inner-wrap a').css('font-size', '+=2');
    $('.inner-wrap li').css('font-size', '+=2'); 
    $('.inner-wrap span').css('font-size', '+=2');
  }
}
