<section class="plan-management-section">  
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Plan Management</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">Plan Management</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="plan-management-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <h3>Plan Management</h3>
                </div>
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <p><strong>Plan Management helps and assists you to manage your NDIS funds. Caring Approach takes the burden out of managing this process and can make the process a lot simpler. Plan management With Caring Approach has the following advantages:</strong></p>
                            <ul>
                                <li><i class="fas fa-check"></i> You can buy supports from non-NDIS registered providers (like a lawn mowing and garden maintenance company, local community members or organisations and Therapists who have chosen not to register).</li>
                                <li><i class="fas fa-check"></i> You don't have to use the NDIS Portal. </li>
                                <li><i class="fas fa-check"></i> You have a partner in navigating the NDIS and someone to help you make the most of your Plan.</li>
                                <li><i class="fas fa-check"></i> You have most of the choice and control of Self-Management without the administrative burden. </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <p><strong>When Caring Approach is engaged to provide plan management, we can assist the following ways.</strong></p>
                            <ul>
                                <li>
                                    <i class="fas fa-check"></i> Receiving invoices
                                    <ul class="inner-list">
                                        <li>Directly from provider</li>
                                        <li>Via participant</li>                                    
                                    </ul>
                                </li>
                                <li><i class="fas fa-check"></i> Claiming from NDIS Portal</li>
                                <li><i class="fas fa-check"></i> Paying Invoices </li>
                                 <li>
                                    <i class="fas fa-check"></i> Providing monthly statement to participant including
                                    <ul class="inner-list">
                                        <li>Summary of expenditure</li>
                                        <li>Via participant</li>                                    
                                    </ul>
                                </li> 
                            </ul>  
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div  class="card-body text-center">
                            <h4  class="mt-15">Tell us how we can support you for a fully tailored service !</h4>
                            <h4><a href="tel:+1300655026"><strong>Call 1300 655 026</strong></a></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
