<div class="gallery-wrap">
  <div class="container">
      <div class="row">
          <div class="col-md-12 col-12">
              <div class="mb-20">
                  <div><h3>Gallery</h3></div>
              </div>
          </div>
          <div *ngFor="let year of galleryYearList" class="col-md-3 col-lg-2 col-12">
              <div class="card" [routerLink]="['/gallery/event', year?.folderid]">
                  <div class="card-body text-center">
                    <div class="text">
                        <span>{{year?.name}}</span>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>