import { AuthService } from '../auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from 'src/app/api.constants';
import { UserActionTypes } from 'src/app/actions/user.actions';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  [x: string]: any;
  public get:Observable<any>;
  public auth;
  public username;

  private backendHnadler: HttpClient

  constructor(private router: Router,
  private _authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const user = this._authService.getUser();
    // if (user && user.token) return true;
    // this.router.navigate(['/signin']);
    // return false;


    let params:Object = route.params;
    if(params && Object.keys(params).length > 0 && params.hasOwnProperty('token')) {
      var token = (params as any)?.token;
      let header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      return this.backendHnadler.get(GlobalConstants.endPoints.GET_PROFILE, {headers:header} ).pipe((map(r => {
        let response = r as any;
        if(!response.status) {
          this.router.navigate(['/signin']);
          return false;
        }else{
          this.store.dispatch({ type: UserActionTypes.LOGIN_SUCCESS, payload: response.result });
          window['storageOption'] = 'sessionStorage';
          localStorage.setItem('remember', 'false');
          let result = {...response.result, 'token':token};
          (window[window['storageOption']] as any)?.setItem('auth', JSON.stringify(result));
          this.router.navigate(['/employee/employee-dashboard']);
          return true;
        }
      })),catchError(err=>{
        this.router.navigate(['/signin']);
        console.error(err);
        return of(false);
      }))
    } else if(this.auth.role == '1') {
      return true
    } else {
      this.router.navigate(['/signin']);
      // this.router.navigate(['/signin',{redirect:decodeURIComponent(state.url)}]);
      return false;  
    }
  }

  checkUser(){
    window['storageOption'] = localStorage.getItem('remember') == "true" ? 'localStorage': 'sessionStorage';
    let userData:any = (window[window['storageOption']] as any)?.getItem('auth') || false;
    let userObj = JSON.parse(userData);
    if (!userObj) return false;
    if(userObj){
      userObj.session = Date.now();
      this.username = userObj.name;
      this.store.dispatch({type:'LOGIN_SUCCESS', payload:userObj});
      return true
    }else{
      this.logout();
      return false;
    }
  }

  getUsername(){
    return this.auth.username;
  }

}

