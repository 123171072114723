<section class="feedback-section">  
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Feedback</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">Feedback</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="feedback-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <form [formGroup]="FeedbackForm" (ngSubmit)="feedbackSubmit()">
                        <h4> Do you have a problem, how can we help?</h4>
                        <p>We would love to hear from you. Please fill in the field below and send it to us. We will be in touch with you soon.</p>
                        <div class="row mb-15">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>First Name *</label>
                                    <input type="text" name="firstname" value="" size="40" class="form-control"
                                    aria-required="true" aria-invalid="false" matInput
                                    formControlName='firstname' required>
                                    <div *ngIf="FeedbackForm.get('firstname').errors?.required && submitted"
                                    class="error">
                                        First Name is required.
                                    </div>
                                    <div *ngIf="FeedbackForm.get('firstname').errors?.pattern" class="error">
                                        Numbers are not allowed.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Last Name *</label>
                                    <input type="text" name="lastname" value="" size="40" class="form-control"
                                                aria-required="true" aria-invalid="false" formControlName='lastname'>

                                    <div *ngIf="FeedbackForm.get('lastname').errors?.required && submitted"
                                        class="error">
                                        Last Name is required.
                                    </div>
                                    <div *ngIf="FeedbackForm.get('lastname').errors?.patterd" class="error">
                                        Numbers are not allowed.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Your email *</label>
                                    <input type="email" name="email" value="" size="40" class="form-control"
                                                aria-invalid="false" formControlName="email">
                                    <div *ngIf="FeedbackForm.get('email').errors?.required && submitted"
                                        class="error">
                                        Email id is required.
                                    </div>
                                    <div *ngIf="FeedbackForm.get('email').errors?.pattern" class="error">
                                        Enter valid Email id
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Your Phone *</label>
                                    <input type="tel" name="phonenumber" value="" size="40" class="form-control"
                                                aria-invalid="false" formControlName="phonenumber">

                                    <div *ngIf="FeedbackForm.get('phonenumber').errors?.required && submitted"
                                        class="error">
                                        Phone number is required.
                                    </div>
                                    <div *ngIf="FeedbackForm.get('phonenumber').errors?.pattern" class="error">
                                        Enter a valid phone number.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Your Message *</label>
                                    <textarea name="message" cols="4" rows="6" class="form-control"
                                                aria-invalid="false" formControlName="message"></textarea>
                                    <div *ngIf="FeedbackForm.get('message').errors?.required && submitted"
                                        class="error">
                                        Message is required.
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">    
                                    <p>To know more about our Complaints Procedures & Guidelines, click here For EASY READ version, <a href="assets/pdf/complaints-feedback-easyread-June19.pdf" target="_blank">click here</a>
                                    </p>
                                </div>
                            </div>
                        </div> 
                        <button type="submit" class="btn theme-outline-btn"
                        [disabled]="isLoading">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>