<section class="support-section">  
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Support Coordination</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">Support Coordination</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="support-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <h3>Support Coordination</h3>
                    <p class="mb-15">The NDIS offers people with a disability more freedom and control over their supports and lives than ever before. But for some participants, a little bit of extra support is needed to help them get the most out of the NDIS. The NDIS can include Support Coordination in your NDIS plan to help you find and connect with the service providers that are the best fit for your situation. Caring Approach is registered to provide Support Coordination.</p>
                </div>
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>With Caring Approach as your support coordinator, our team can help you.</h4>
                            <hr>
                            <ul>
                                <li>Find and connect to services </li>
                                <li>Understand the details of your plan and what supports you can access</li>
                                <li>Guide you throughout your NDIS journey, and help resolve any issues with providers.</li>
                                <li>Relieve the admin burden and simplify the process</li>
                            </ul>
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>What makes Support Coordination with Caring Approach different?</h4>
                            <hr>
                            <ul>
                                <li>We have local knowledge to help you access the services you need</li>
                                <li>We will always advocate for what you want and need  </li>
                                <li>We are independent</li>
                                <li>We will work closely with you, listen and work in your best interest.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-12">
                    <p>If you have any questions about Caring Approach`s Support Coordination services, please contact us on<a href="tel:+1300655026"><strong> 1300 655 026 </strong></a> or through the          <a routerLink="/contact-us"><strong> contact us portal </strong></a> on this website.</p>
                </div>
            </div>
        </div>
    </div>
</section>

