<section class="policy-section">  
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Privacy Policy</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">Privacy Policy</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="policy-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm-12 mb-30">
                    <p class="fz-16">This Privacy Policy sets out how Caring Approach complies with our obligations under Australian privacy law. Caring Approach is bound by the Australian Privacy Principles (APPs) which regulate how organisations may collect, use, disclose and store personal information and how individuals may access and correct personal information held about them. The terms in this Privacy Policy have the same meaning as in the APPs. Personal information (as defined in the Privacy Act) means: information or an opinion about an identifiable or reasonably identifiable individual, whether the information or opinion is true or not and whether the information or opinion is recorded in a material form or not.
                    </p>
                    <p class="fz-16">Please read this Privacy Policy carefully and contact us at <a href="mailto:admin@caringapproach.com.au" target="_blank"> admin@caringapproach.com.au </a> if you have any questions. Supply of your personal information whether through our website at www.caringapproach.com.au (Site) or otherwise indicates that you have had sufficient opportunity to access, read, understand, and accept the Privacy Policy. If you do not wish to provide personal information to us then you do not have to do so, however it may affect your use of our website or any products and services offered.  </p>
                </div>
                <div class="col-md-12 col-sm-12">
                    <div class="accordion">
                        <h4 class="fz-16">Our Information Handling Practices</h4>
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title"> 1.1 Why we collect personal information?</div>
                                    </a>
                            
                                    <div class="content">
                                        <h6>Caring Approach collects personal information to enable us to:</h6>
                                        <ul>
                                            <li>Provide services or health support or help people access the services they need;</li>
                                            <li>Invoice you or other service providers for services rendered;</li>
                                            <li>Assess, investigate, conciliate, determine, or monitor privacy or other complaints; </li>
                                            <li>Respond to enquiries;</li>
                                            <li>Distribute information about our activities or publications to people who may have an interest in Caring Approach services;</li>
                                            <li>Employ and pay staff;</li>
                                            <li>Engage with organisational members;</li>
                                            <li>Send you promotional information for additional services that we think may be of interest to you.</li>
                                            
                                        </ul>
                                        <p>Caring Approach collects this information on a 'need to know' basis - we collect only what we need to enable us to do these things.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title">1.2 What type of personal information do we collect?</div>
                                    </a>
                            
                                    <div class="content">
                                        <h6>Caring Approach collects personal information to enable us to:</h6>
                                        <ul>
                                            <li>Images and photographs;</li>
                                            <li>Date of birth;</li>
                                            <li>Contact details;</li>
                                            <li>Bank account details;</li>
                                            <li>Family details including family contact details; </li>
                                            <li>Disability Pension Number; </li>
                                            <li>Medication information;</li>
                                            <li>Information needed to form a client’s Support Plan or Person-Centred Plan;             </li>
                                            <li>Driver’s license numbers;</li>
                                        </ul>
                                        <p>The type of information we collect includes health and other sensitive information, as well as government identifiers as per clause 1.6..Your opinion and feedback: We may contact you to voluntarily repond to questionnaires, surveys, or market research to seek your opinion and feedback. Providing this information is optional to you. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title">1.3 Who do we collect information from?</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>In most circumstances, we collect personal information from the individual to whom the information relates. Information may be collected from clients, their family, guardian or carer; Caring Approach staff and Government and non-Government stakeholders. Sometimes we collect personal information from a third party or a publicly available source, but only if it is unreasonable or impracticable to collect it from the individual. In those circumstances, Caring Approach will take reasonable steps, where practicable, to inform the individual that the information is being or has been collected. In some limited circumstances, we collect or obtain consent for the use or disclosure of personal information about an individual from an authorised representative (e.g. where the individual is incapable of providing the information or consent due to an illness or disability). </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title">1.4 Use and Disclosure</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>Caring Approach only uses personal information for the purposes for which the information was collected (e.g. to provide services or support to a person), related purposes where the individual would reasonably expect us to use the information for that purpose, other purposes for which we received consent or otherwise as permitted by the Australian Privacy Principles (APPs). Caring Approach does not give information about a person to other organisations, government agencies or anyone else unless one of the following applies:</p>
                                        <ul>
                                                <li>The person has consented;             </li>
                                                <li>The person reasonably expects us;             </li>
                                                <li>It is required or authorised by law;             </li>
                                                <li>We believe the disclosure is necessary to prevent or lessen a serious threat to life, health or safety and it is unreasonable to obtain the persons consent; or             </li>
                                                <li>In other circumstances permitted by the APPs (e.g. if the disclosure is reasonably necessary for law enforcement purposes).             </li>
                                                <li>The disclosure is to a service provider or advisor retained by Caring Approach and is necessary to assist Caring Approach to do any of the things set out in clause 1.1.</li>
                                            </ul>
                                        <p>Where we disclose your personal information to third parties for these purposes, we will request that the third party follow this Privacy Policy regarding handling of your personal information. Such third parties may include parties that store data outside of Australia. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title">1.5 Unsolicited Personal Information</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>When Caring Approach receives unsolicited personal information we will decide within a reasonable time whether we could have received such information legitimately. If not, we will destroy or de-identify the information unless the information contained involves a Commonwealth record or destroying the information would be unlawful or unreasonable.  </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title">1.6 How we treat personal information that is also sensitive information   </div>
                                    </a>
                            
                                    <div class="content">
                                        <p>Sensitive information is a sub-set of personal information that is given a higher level of protection under the Australian Privacy Principles. In referring to ‘sensitive information’ we mean information that relates to your racial or ethnic origin, political opinions, religion, trade union or other professional associations or memberships, philosophical beliefs, sexual orientation or practice and criminal records, health information or biometric information. The types of sensitive information we collect include: </p>
                                        <ul>
                                                <li>Disability Pension Number, Medicare Number, and other government identifiers;             </li>
                                                <li>Medical information;             </li>
                                                <li>Health Care Support Plan;             </li>
                                                <li>Contact details; and             </li>
                                                <li>Other personal and sensitive information as needed to provide services.</li>
                                            </ul>
                                        <p>Sensitive information may be used and disclosed for purposes identified in clause 1.1. Sensitive information may also be used or disclosed if required or authorised by law.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="fz-16">Quality and Security of Personal Information</h4>
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title"> 2.1 Data quality</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>Caring Approach takes reasonable steps to ensure that the personal information we collect, use, or disclose (and our record of any consent in relation to that information) is relevant, accurate, up to date and complete. These steps include maintaining and updating personal information when we are advised by individuals that such information has changed.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title">2.2 Data security</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>Caring Approach takes reasonable steps to protect personal information/images we hold against misuse, loss, unauthorised access, modification, and disclosure. These steps include protection for electronic files, securing paper files in locked cabinets, physical access restrictions and taking reasonable steps to ensure that when no longer required personal information is securely destroyed or deleted. Caring Approach adheres to the protocols for maintenance and storage of client and staff information in State and Federal legislation and in specific funding agreements between Caring Approach and State and Federal agencies such as the Department of Social Services.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="fz-16">Our Files and You</h4>
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title"> 3.1 Our Types of Files</div>
                                    </a>
                            
                                    <div class="content">
                                        <h6>Caring Approach primarily maintains the following types of files: </h6>
                                        <ul>
                                            <li>Client Files             </li>
                                            <li>Staff Files             </li>
                                            <li>Complaint, Feedback, and Praise Files             </li>
                                            <li>Service Delivery & Financial Records             </li>
                                            <li>Audit Files             </li>
                                            <li>Enquiries Files             </li>
                                            <li>Contact lists</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title">3.2 Your rights and controlling your personal information</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>informationProviding us with your personal information is optional. You can choose not to provide personal information. When you provide us with your personal information, you consent to the terms in this Privacy Policy, and to us disclosing or receiving such information for these purposes. You may choose to restrict the collection or use of your personal information.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title">3.3 Your provision of third party information
                                            </div>
                                    </a>
                            
                                    <div class="content">
                                        <p>Caring Approach takes reasonable steps to protect personal information/images we hold against misuse, loss, unauthorised access, modification, and disclosure. These steps include protection for electronic files, securing paper files in locked cabinets, physical access restrictions and taking reasonable steps to ensure that when no longer required personal information is securely destroyed or deleted. Caring Approach adheres to the protocols for maintenance and storage of client and staff information in State and Federal legislation and in specific funding agreements between Caring Approach and State and Federal agencies such as the Department of Social Services.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="fz-16">Access and Correction</h4>
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title"> 4.1 Access</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>You may request details of personal information that we hold about you, in certain circumstances set out in the Privacy Act. We may refuse to provide such information if unreasonable or if a legal exception applies. We may charge an administration fee for the provision of such information.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title">4.2 Correction</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>If you believe that any information we hold on you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us by email. We rely in part on customers advising us when their personal information changes. We will respond to any request within a reasonable time and will endeavour to promptly correct any information found to be inaccurate, incomplete, or out of date.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="fz-16">Complaints</h4>
                        <div class="row">
                            <div class="col-md-12 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title"> 5.1 Complaints</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>If you believe that we have breached the APPs and wish to make a complaint, please contact us by email setting out details of the alleged breach through the Caring Approach complaints policy. We will promptly investigate your complaint and respond to you in writing setting out the outcome of our investigation, what steps we propose to take to remedy the breach and any other action taken.   </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="fz-16">Direct Marketing</h4>
                        <div class="row">
                            <div class="col-md-12 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title"> 6.1 Direct Marketing</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>From time to time we may send you direct marketing materials about our organisation that we believe may be of interest to you. In doing so we are subject to various laws including the Privacy Act 1988 (Cth) and the Spam Act 2003 (Cth). We carry out our direct marketing activities with either the consent or reasonable expectation of individuals. Unsubscribe: To unsubscribe from our e-mail database or opt out of communications at any time, please contact us at: <a href="mailto:admin@caringapproach.com.au">admin@caringapproach.com.au</a>     </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="fz-16">Online/Electronic Communications</h4>
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title"> 7.1 Electronic communications</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>No information transmitted over the Internet can be guaranteed to be secure. Although we take measures to safeguard against unauthorised disclosures of information we cannot completely guarantee the security of any information that you transmit or receive from us.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title">7.2 Cookies and web beacons</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>We may use cookies on our website. Cookies are text files placed in your computer's browser to store your preferences. Cookies, by themselves, do not tell us your e-mail address or other personally identifiable information. However, once you choose to furnish the Site with personally identifiable information, this information may be linked to the data stored in the cookie.
                                        </p>
                                        <p>We may use web beacons on our Site. Web beacons or clear .gifs are small pieces of code placed on a web page to monitor the behaviour and collect data about the visitors viewing a web page. For example, web beacons can be used to count the users who visit a web page or to deliver a cookie to the browser of a visitor viewing that page.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title">7.3 Links to other websites

                                            </div>
                                    </a>
                            
                                    <div class="content">
                                        <p>Our Site may contain links to other websites. We do not have any control over those websites. We are not responsible for or liable for the protection and privacy of any information which you provide whilst visiting such websites, and such websites are not governed by this Privacy Policy.   </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="fz-16">Amendments to Policy</h4>
                        <div class="row">
                            <div class="col-md-12 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title"> 8.1 Amendments to Policy</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>This Privacy Policy may be amended from time to time at our sole discretion. Your continued use of our Site following any amendments indicates that you accept the amendments. You should check the Privacy Policy regularly, prior to providing personal information, to ensure you are aware of any changes, and only proceed to provide personal information if you accept the new Privacy Policy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="fz-16">Contact Us</h4>
                        <div class="row">
                            <div class="col-md-12 col-12">
                                <div class="accordion-item">
                                    <a href="#" class="heading">
                                        <div class="icon"></div>
                                        <div class="title"> Get in touch</div>
                                    </a>
                            
                                    <div class="content">
                                        <p>If you would like further information about how we handle personal information or would like to make a complaint about a possible breach of privacy, please contact Caring Approach management at <a href="mailto:admin@caringapproach.com.au"> admin@caringapproach.com.au.</a></p>
                                        <p>Alternatively, you can contact us using the details located below:</p>
                                        <p><b>Phone:</b> <a href="tel:+1300655026"> 1300 655 026</a></p>
                                        <p><b>Postal Address</b> PO Box 452, Wahroonga NSW 2076</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
