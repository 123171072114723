import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare let $: any;
@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  title = 'appBootstrap';
  
  model;

  time = {hour: 14, minute: 30};

  constructor(public router: Router) {}

  ngOnInit(): void {
    /* Request Holiday Datepicker */
    // $('#datepicker').datepicker({
    //   calendarWeeks: true,
    // });
    /* Navbar Menu Active */
    /*$(document).ready(function(){
      $('.navbar-nav li .nav-link').click(function(e) {
        e.preventDefault();
        $('a').removeClass('active');
        $(this).addClass('active');
      });
    });*/

    // $(document).ready(function () {
    //   var url = window.location;
    //   $('ul.nav a[href="'+ url +'"]').addClass('active');
    //   $('ul.nav a').filter(function() {
    //     console.log(url);

    //         return this.href == url;
    //   }).addClass('active');
    // });


    /* Center Align Modal */
    $(document).ready(function(){
      function alignModal(){
        var modalDialog = $(this).find(".modal-dialog");
        /* Applying the top margin on modal dialog to align it vertically center */
        modalDialog.css("margin-top", Math.max(0, ($(window).height() - modalDialog.height()) / 2));
      }
      // Align modal when it is displayed
      $(".modal").on("shown.bs.modal", alignModal);
      // Align modal when user resize the window
      $(window).on("resize", function(){
        $(".modal:visible").each(alignModal);
      });   
    });
    
    
    /* Custom Upload File */
    const realFileBtn = document.getElementById("real-file");
    const customBtn = document.getElementById("custom-button");
    const customTxt = document.getElementById("custom-text");

    customBtn.addEventListener("click", function() {
      realFileBtn.click();
    });

    /*realFileBtn.addEventListener("change", function() {
      //alert(realFileBtn);
      //console.log(realFileBtn);
      if ((realFileBtn).value) {
        customTxt.innerHTML = realFileBtn.value.match(
          /[\/\\]([\w\d\s\.\-\(\)]+)$/
        )[1];
      } else {
        customTxt.innerHTML = "No file chosen, yet.";
      }
    });*/

    const realFileBtn1 = document.getElementById("real-file1");
    const customBtn1 = document.getElementById("custom-button1");
    const customTxt1 = document.getElementById("custom-text1");

    customBtn.addEventListener("click", function() {
      realFileBtn.click();
    });

    
  }
}
