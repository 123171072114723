<section class="services-section inner-wrap">
    <div class="page-banner pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Transport</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home"
                            title="Home">Home</a>
                    </li>
                    <li class="separator separator-home">
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i>
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">Transport</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="services-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10"
                            (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary"
                            (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5 col-12 mb-20">
                    <div class="img-wrap">
                        <img src="assets/images/transport/transport.png" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-7 col-12 mb-20">
                    <h3 class="fw-600 mb-20 mt-40">Transport</h3>
                    <h4 class="subheading">At Caring Approach we offer a range of services to suit our client's needs
                    </h4>
                    <p class="details mb-10">Caring Approach can provide transport to and from appointments, shopping,
                        social activities and to and from the airport. Caring Approach can provide support workers to
                        assist when you reach your destination or just provide the driver and vehicle. All Caring
                        Approach and support worker vehicles are fully insured.</p>

                    <p class="details mb-10">Caring Approach is also an authorised booking service provider including
                        hire care services for point to point transport services in NSW. Caring Approaches booking
                        service provider authorisation number is; BSP-413516.</p>

                    <p class="details mb-30">Caring Approach has a variety of vehicles available including a fully
                        accessible wheelchair van.</p>
                    <hr>
                    <h4 class="mt-15 announce mt-10">Tell us how we can support you for a fully tailored service !</h4>
                    <h4 class="text-theme"><a href="tel:+1300655026"><strong>Call 1300 655 026</strong></a></h4>
                    <hr>
                    <button class="btn btn-primary theme-btn mt-30" (click)="toggleBookingForm()">BOOK TRANSPORT
                        NOW</button>
                </div>
                <!--<div class="offset-md-2 col-md-8 col-12 text-center mb-20">
                    <h3 class="fw-600 mb-20">Transport</h3>
                    <h4 class="subheading">At Caring Approach we offer a range of services to suit our client's needs
                    </h4>
                    <p class="details mb-10">Caring Approach can provide transport to and from appointments, shopping,
                        social activities and to and from the airport. Caring Approach can provide support workers to
                        assist when you reach your destination or just provide the driver and vehicle. All Caring
                        Approach and support worker vehicles are fully insured.</p>

                    <p class="details mb-10">Caring Approach is also an authorised booking service provider including
                        hire care services for point to point transport services in NSW. Caring Approaches booking
                        service provider authorisation number is; BSP-413516.</p>

                    <p class="details mb-10">Caring Approach has a variety of vehicles available including a fully
                        accessible wheelchair van.</p>
                    <hr>
                    <h4 class="mt-15 announce mt-10">Tell us how we can support you for a fully tailored service !</h4>
                    <h4 class="text-theme"><strong>Call 1300 655 026</strong></h4>
                </div>
                <div class="offset-md-2 col-md-4">
                    <div class="trans-wrap">
                        <img class="img-fluid" src="assets/images/transport/trans-1.jpeg">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="trans-wrap">
                        <div class="overlay3"></div>
                        <img class="img-fluid" src="assets/images/transport/trans-2.jpeg">
                        <div class="overlay2"></div>
                    </div>
                </div>
                <hr>
                <div class="offset-md-4 col-md-4 col-12 text-center">
                    <button class="btn btn-primary theme-btn mt-30" onclick="myFunction()">BOOK TRANSPORT NOW</button>
                </div>-->
                <div class="col-md-12 col-12">
                    <div class="book-form" *ngIf="toggleBooking">
                        <!-- <form class="p-20 mt-20" #transport="ngForm" (submit)="addTransport(transport)"> -->
                        <form class="p-20 mt-20" [formGroup]="transportForm" (ngSubmit)="transportSubmit()">
                            <div class="row">
                                <div class="col-md-12 col-12 text-center bg-theme">
                                    <h4>NDIS ACCESSIBLE TRANSPORT BOOKING FORM</h4>
                                </div>
                                <div class="col-md-12 col-12">
                                    <h4 class="mt-20">Customer Details</h4>
                                    <hr>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="name">Name</label>
                                        <input type="text" class="form-control" placeholder="Name" id="name" name="name"
                                            formControlName="name" required>
                                        <div *ngIf="transportForm.get('name').errors?.required && submitted"
                                            class="error">
                                            Name is required.
                                        </div>
                                        <div *ngIf="transportForm.get('name').errors?.pattern" class="error">
                                            Enter a valid name.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="phone">Phone</label>
                                        <input type="text" class="form-control" placeholder="Phone" name="phonenumber"
                                            formControlName="phonenumber" required>
                                        <div *ngIf="transportForm.get('phonenumber').errors?.required && submitted"
                                            class="error">
                                            Phone number is required.
                                        </div>
                                        <div *ngIf="transportForm.get('phonenumber').errors?.pattern" class="error">
                                            Enter a valid phone number.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input type="email" class="form-control" placeholder="Email" name="email"
                                            formControlName="email" required>
                                        <div *ngIf="transportForm.get('email').errors?.required && submitted"
                                            class="error">
                                            Email is required.
                                        </div>
                                        <div *ngIf="transportForm.get('email').errors?.email && submitted"
                                            class="error">
                                            Enter a valid email.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12">
                                    <h4>Booking Information</h4>
                                    <hr>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="transport">Date of transport</label>
                                        <div class="form-group mb-0">
                                            <div class="datepicker date input-group p-0">
                                                <input class="form-control" placeholder="Date of transport"
                                                    name="date_of_transport" format="dd-mm-YYYY"
                                                    formControlName="date_of_transport" ngbDatepicker id="datepiker_d2"
                                                    #d="ngbDatepicker" [showWeekNumbers]="false" autocomplete="off"
                                                    [minDate]="transportMinDate"
                                                    [minDate]="{year: 1900, month: 1, day: 1}"
                                                    [maxDate]="{year: 2048, month: 12, day: 31}" onclick="this.blur()"
                                                    (click)="d.toggle()">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" (click)="d.toggle()">
                                                        <i class="fas fa-calendar-alt"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div *ngIf="transportForm.get('date_of_transport').errors?.required && submitted"
                                                class="error">
                                                Transport date is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="picktime">Pickup Time</label>
                                        <input type="text" class="form-control" placeholder="Pickup time. Eg. 12:00am"
                                            id="picktime" name="booking_pickup_time"
                                            formControlName="booking_pickup_time">
                                        <div *ngIf="transportForm.get('booking_pickup_time').errors?.required && submitted"
                                            class="error">
                                            Pickup time is required.
                                        </div>
                                        <div *ngIf="transportForm.get('booking_pickup_time').errors?.pattern"
                                            class="error">
                                            Pickup time is invalid. Eg: 12:00am
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="passenger">No. of Passengers</label>
                                        <input type="text" class="form-control" placeholder="No. of passengers"
                                            id="passenger" name="no_of_passengers" formControlName="no_of_passengers">
                                        <div *ngIf="transportForm.get('no_of_passengers').errors?.required && submitted"
                                            class="error">
                                            No. of passenger is required.
                                        </div>
                                        <div *ngIf="transportForm.get('no_of_passengers').errors?.pattern"
                                            class="error">
                                            Enter a valid number
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12">
                                    <div class="form-group">
                                        <label for="cost">Cost/ Important Information</label>
                                        <ul>
                                            <li class="text-muted"><i class="fa fa-circle" aria-hidden="true"></i> $0.85
                                                km for normal transport</li>
                                            <li class="text-muted"><i class="fa fa-circle" aria-hidden="true"></i> $2.40
                                                km for wheel chair accessible transport</li>
                                            <li class="text-muted"><i class="fa fa-circle" aria-hidden="true"></i>
                                                Minimum driver cost 1 hour NDIS rate</li>
                                            <li class="text-muted"><i class="fa fa-circle" aria-hidden="true"></i>
                                                Surcharges may apply for travel 35km and above.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="location">Pickup Location</label>
                                        <input type="text" class="form-control" placeholder="Pipckup location"
                                            id="location" name="pickup_location" formControlName="pickup_location">
                                        <div *ngIf="transportForm.get('pickup_location').errors?.required && submitted"
                                            class="error">
                                            Pickup location is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="destination">Destination</label>
                                        <input type="text" class="form-control" placeholder="Destination"
                                            id="destination" name="destination" formControlName="destination">
                                        <div *ngIf="transportForm.get('destination').errors?.required && submitted"
                                            class="error">
                                            Pickup location is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="return">Return Trip</label><br>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" id="radio1"
                                                    name="return_trip" formControlName="return_trip" value="yes">
                                                Yes
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" id="radio2"
                                                    name="return_trip" formControlName="return_trip" value="no">
                                                No
                                            </label>
                                        </div>

                                        <div *ngIf="transportForm.get('return_trip').errors?.required && submitted"
                                            class="error">
                                            Return trip is required.
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="time">Pickup Time</label>
                                        <input type="text" class="form-control" placeholder="Pipckup time" id="time"
                                            name="pickup_time" formControlName="pickup_time">
                                    </div>
                                </div> -->
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="driver">Is Driver Required to Wait</label><br>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input"
                                                    name="is_driver_required_to_wait"
                                                    formControlName="is_driver_required_to_wait" value="yes"> Yes
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input"
                                                    name="is_driver_required_to_wait"
                                                    formControlName="is_driver_required_to_wait" value="no"> No
                                            </label>
                                        </div>

                                        <div *ngIf="transportForm.get('is_driver_required_to_wait').errors?.required && submitted"
                                            class="error">
                                            Is driver required to wait is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="wheelchair">Wheelchair Accesible Transport Required</label><br>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input"
                                                    name="wheelchair_accesible_transport"
                                                    formControlName="wheelchair_accesible_transport" value="yes">
                                                Yes
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input"
                                                    name="wheelchair_accesible_transport"
                                                    formControlName="wheelchair_accesible_transport" value="no">
                                                No
                                            </label>
                                        </div>

                                        <div *ngIf="transportForm.get('wheelchair_accesible_transport').errors?.required && submitted"
                                            class="error">
                                            Wheelchair accesible transport is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12">
                                    <div class="form-group">
                                        <label for="note">Note/ Important Information</label>
                                        <textarea row="3" class="form-control" name="note"
                                            formControlName="note"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12">
                                    <h4>Payment Details</h4>
                                    <hr>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="ndis-number">NDIS Number</label>
                                        <input type="text" class="form-control" placeholder="NDIS Number"
                                            id="ndis-number" name="ndis_number" formControlName="ndis_number">
                                        <div *ngIf="transportForm.get('ndis_number').errors?.required && submitted"
                                            class="error">
                                            NDSI number is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="dob">Partcipant DOB</label>
                                        <div class="form-group mb-0">
                                            <div class="datepicker date input-group p-0">
                                                <input class="form-control" placeholder="DOB" name="partcipant_dob"
                                                    formControlName="partcipant_dob" ngbDatepicker id="datepiker_d1"
                                                    #d1="ngbDatepicker" [showWeekNumbers]="false" autofocus="autofocus"
                                                    autocomplete="off" [maxDate]="maxDate" [minDate]="{year: 1900, month: 1, day: 1}"
                                                    (click)="d1.toggle()"
                                                    onclick="this.blur()">
                                                <div class="input-group-append">
                                                    <span class="input-group-text" (click)="d1.toggle()">
                                                        <i class="fas fa-calendar-alt"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="transportForm.get('partcipant_dob').errors?.required && submitted"
                                            class="error">
                                            Date of birth is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="coordinator">Coordinator</label>
                                        <input type="text" class="form-control" placeholder="Coordinator"
                                            id="coordinator" name="coordinator" formControlName="coordinator">
                                        <div *ngIf="transportForm.get('coordinator').errors?.required && submitted"
                                            class="error">
                                            Coordinator is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-12">
                                    <div class="form-group">
                                        <label for="plan-manager">Plan Manager</label>
                                        <input type="text" class="form-control" placeholder="Plan manager"
                                            id="plan-manager" name="plan_manager" formControlName="plan_manager">
                                        <div *ngIf="transportForm.get('plan_manager').errors?.required && submitted"
                                            class="error">
                                            Plan manager is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-12">
                                    <div class="form-group">
                                        <label for="return">Claim NDIS</label><br>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="claim_ndis"
                                                    formControlName="claim_ndis" value="yes"> Yes
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="claim_ndis"
                                                    formControlName="claim_ndis" value="no"> No
                                            </label>
                                        </div>

                                        <div *ngIf="transportForm.get('claim_ndis').errors?.required && submitted"
                                            class="error">
                                            Claim NDIA is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-12">
                                    <div class="form-group">
                                        <label for="invoices">Invoices To</label>
                                        <input type="text" class="form-control" placeholder="Invoices to" id="invoices"
                                            name="invoices_to" formControlName="invoices_to">
                                        <div *ngIf="transportForm.get('invoices_to').errors?.required && submitted"
                                            class="error">
                                            Invoice to is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-12">
                                    <div class="form-group">
                                        <label for="invoices">Select Location</label>
                                        <select class="form-control" name="location" formControlName="location">
                                            <option value="" selected>Select option</option>
                                            <option value="NSW">NSW</option>
                                            <option value="ACT">ACT</option>
                                        </select>

                                        <div *ngIf="transportForm.get('location').errors?.required && submitted"
                                            class="error">
                                            Service is required.
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-12 col-12 text-center">
                                    <hr>
                                    <h4 class="uppercase fw-600">For Office Use Only</h4>
                                    <hr>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="date">Date</label>
                                        <div class="form-group mb-0">
                                            <div class="datepicker date input-group p-0">
                                                <input class="form-control" placeholder="Date" name="office_date"
                                                    formControlName="office_date" ngbDatepicker id="datepiker"
                                                    #d2="ngbDatepicker" [showWeekNumbers]="false" autofocus="autofocus"
                                                    autocomplete="off" readonly>
                                                <div class="input-group-append">
                                                    <span class="input-group-text" (click)="d2.toggle()">
                                                        <i class="fas fa-calendar-alt"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="booking">Booking Completed By</label>
                                        <input type="text" class="form-control" placeholder="Booking completed by"
                                            id="booking" name="booking_completed_by"
                                            formControlName="booking_completed_by" readonly>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div class="form-group">
                                        <label for="booking">Signature</label>
                                        <div class="file-upload">
                                            <div class="file-select">
                                                <button type="button" class="btn btn-primary" data-toggle="modal"
                                                    data-target="#signaturePad" disabled>Pad</button>
                                            </div>
                                            <img class="signature-img" [src]="formModel.signature" />
                                        </div>
                                    </div>
                                </div> -->
                                <div class="offset-md-8 col-md-4 col-12 text-right">
                                    <button type="submit" class="btn btn-success mr-10" [disabled]="isLoading">
                                        <span class="spinner-border spinner-border-md" role="status" aria-hidden="true"
                                            *ngIf="isLoading"></span>Submit </button>
                                    <button type="button" class="btn btn-danger" (click)="toggleBookingForm()"> Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="our-service-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">01</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/disability-care">
                                        <p class="content mb-0">Disability</p>
                                        <h3 class="title mb-0 mt-0 fw-500">Care </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">02</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/nursing-care">
                                        <p class="content mb-0">Nursing</p>
                                        <h3 class="title mb-0 mt-0 fw-500">Care </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">03</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/dementia-care">
                                        <p class="content mb-0">Dementia</p>
                                        <h3 class="title mb-0 mt-0 fw-500">Care </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">04</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/recovery-care">
                                        <p class="content mb-0">Recovery</p>
                                        <h3 class="title mb-0 mt-0 fw-500">Care</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">05</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/new-mums">
                                        <p class="content mb-0">New Mums Or</p>
                                        <h3 class="title mb-0 mt-0 fw-500">Mums On The Go</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">06</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/respite-help">
                                        <p class="content mb-0"> Respite And Help</p>
                                        <h3 class="title mb-0 mt-0 fw-500">For Carers </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">07</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/transport">
                                        <h3 class="title mb-0 mt-0 fw-500">Transport </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6 no-gutter">
                    <div class="service-holder">
                        <div class="d-md-block d-lg-flex align-items-center">
                            <div class="mr-10 box">
                                <span class="fz-40">08</span>
                            </div>
                            <div class="align-items-center">
                                <div class="dl">
                                    <a href="javascript:void(0);" routerLink="/autism">
                                        <h3 class="title mb-0 mt-0 fw-500">Autism </h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="gov-funded pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Government funded? We’ve got you covered.</h1>
            <p class="text-white">Caring Approach is registered with NDIS & Myagedcare <br> to help you avail their help
                and benefits</p>
        </div>
    </div>
    <div class="testimonial-section">
        <div class="container">
            <div class="row">
                <div class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-12 text-center">
                    <h2 class="title">Client Testimonials</h2>
                    <p class="text">What our clients have to say about Caring Approach</p>
                </div>
            </div>
            <div class="row mt-20">
                <!-- Testimonial -->
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="box">
                        <!-- Testimonial Image -->
                        <div class="video-wrap mr-5">
                            <video controls="controls" poster="assets/images/testi-poster1.png">
                                <source src="assets/video/greg-moran-testimonial.mp4" type="video/mp4"> Your browser
                                does not support the video tag.
                            </video>
                        </div>
                        <!-- / Testimonial Image -->
                        <div class="test-component">
                            <!-- Title -->
                            <article class="test-title">
                                <h4>
                                    Greg Moran
                                </h4>
                            </article>
                            <!-- / Title -->
                            <article class="test-content">
                                <p>
                                    My name is Greg Moran, I'm a C3/4 quadriplegic as a result of a rugby accident in
                                    1983. In my 30 odd years as a quadriplegic I can categorically say that Caring
                                    Approach Agency... <a href="javascript:void(0);" routerLink="/testimonials"
                                        class="text-theme"><small>Read more >></small></a>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- / Testimonial -->
                <!-- Testimonial -->
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="box">
                        <!-- Testimonial Image -->
                        <div class="video-wrap mr-5">
                            <video controls="controls" poster="assets/images/testi-poster2.png">
                                <source src="assets/video/joanna-willis-testimonial.mp4" type="video/mp4"> Your browser
                                does not support the video tag.
                            </video>
                        </div>
                        <!-- / Testimonial Image -->
                        <div class="test-component">
                            <!-- Title -->
                            <article class="test-title">
                                <h4>
                                    Farah
                                </h4>
                            </article>
                            <!-- / Title -->
                            <article class="test-content">
                                <p>
                                    I decided to use Caring Approach for my personal and domestic care needs because
                                    Homecare did not provide the quality of staff or the flexibility of care that I
                                    needed...
                                    <a href="javascript:void(0);" routerLink="/testimonials"
                                        class="text-theme"><small>Read more >></small></a>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- / Testimonial -->
                <!-- Testimonial -->
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="box">
                        <!-- Testimonial Image -->
                        <div class="video-wrap mr-5">
                            <video controls="controls" poster="assets/images/testi-poster3.png">
                                <source src="assets/video/mark-testimonial.mp4" type="video/mp4"> Your browser does not
                                support the video tag.
                            </video>
                        </div>
                        <!-- / Testimonial Image -->
                        <div class="test-component">
                            <!-- Title -->
                            <article class="test-title">
                                <h4>
                                    Mark
                                </h4>
                            </article>
                            <!-- / Title -->
                            <article class="test-content">
                                <p>
                                    I have been receiving home care for approximately nine months. Initially, that care
                                    was provided by another agency. It was only after I began using Caring Approach...
                                    <a href="javascript:void(0);" routerLink="/testimonials"
                                        class="text-theme"><small>Read more >></small></a>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- / Testimonial -->
            </div>
        </div>
    </div>
    <div class="social-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="theme-box p-20">
                                <h4 class="text-white">After Hours Support</h4>
                                <p class="text-white">Our contracted clients are assured that after hours support is
                                    available for both clients and staff if urgent matters arise.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="blue-box p-20">
                                <h4 class="text-white">Easy transfer of services</h4>
                                <p class="text-white">Invoices are provided fortnightly by post or email. We can also
                                    work with your government provider to arrange a simple and easy transfer of
                                    services.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="box p-20">
                                <h4>Easy Referral</h4>
                                <span>
                                    <i class="fas fa-envelope mr-5"></i>
                                    <a  href="mailto:admin@caringapproach.com.au" class="text-theme" target="_blank"> <span>admin@caringapproach.com.au</span></a>
                                </span><br>
                                <span>
                                   <i class="fas fa-phone mr-5"></i>
                                   <a href="tel:+1300655026"><span>1300 655 026</span></a>
                                </span><br>
                                <span>
                                   
                                    <i class="fas fa-phone mr-5"></i>
                                    <a href="tel:+0431744321"><span>0431 744 321</span>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<signature (signature)="addSignaturePadFile($event)"></signature>