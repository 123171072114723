
                
<div class="hero-slider">
    <ngx-slick-carousel class="carousel hero-carousel" #slickModal="slick-carousel" [config]="heroSliderConfig">
        <div ngxSlickItem *ngFor="let slide of heroSlides" class="slide item">
            <img [src]='"https://caringapproach.com.au/superadmin/"+slide.image' alt="Slider Image" width="100%">
            <div class="content">
                <h6 class="wow fadeInUp" data-wow-delay="0.5s" animation-duration="2s">{{slide.title}}</h6>
                <h4>{{slide.heading}}</h4>
                <p>{{slide.description}}</p>
            </div>
        </div>
    </ngx-slick-carousel>
</div>
           