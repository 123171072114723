<section class="accomodation-section">  
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">ACCOMMODATION</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">ACCOMMODATION</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="accomodation-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <h3>ACCOMMODATION</h3>
                    <p class="mb-15">Caring Approach believes having a place to call home that is safe, secure with the right support is important to help you “live life to the fullest”. Caring Approach has shared living vacancies in the suburb of Macgregor in the A. C.T and in early 2020 will be opening a four-bedroom home in the Canberra suburb of Latham.</p>
                    <p class="mb-15"> Current vacancies include:</p>
                </div>
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <!-- <div class="hero-slider">
                                <ngx-slick-carousel class="carousel hero-carousel" #slickModal="slick-carousel" [config]="accomodationSliderConfig">
                                    <div ngxSlickItem *ngFor="let slide of accomodationSlides" class="slide item img-wrap float-left">
                                        <img src="{{ slide.img }}" alt="Slider Image" class="img-fluid w-100">
                                    </div>
                                </ngx-slick-carousel>
                            </div> -->
                             <div class="img-wrap float-left">
                                <ngx-slick-carousel class="carousel hero-carousel" #slickModal="slick-carousel" [config]="accomodationSliderConfig">
                                    <div ngxSlickItem *ngFor="let slide of accomodationSlides" class="slide item">
                                        <img src="{{ slide.img }}" alt="Slider Image" class="img-fluid w-100">
                                    </div>
                                </ngx-slick-carousel>
                            </div> 
                            <div class="content float-right">
                                <h4 class="mt-15">McGregor</h4>
                                <hr>
                                <ul>
                                    <li>This is a two-bedroom unit.</li>
                                    <li>The vacant room has a ceiling hoist and is all fully wheelchair accessible.</li>
                                    <li>We have a vacancy to share with a mature female who has just moved in.</li>
                                    <li>It is an SDA registered property for high care participants.</li>
                                    <li>The participant living there has 24-hour support including a full night duty.</li>
                                    <li>It is close to Kippax shops and the Belconnen Mall.</li>
                             
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                <!-- <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <div class="img-wrap float-left">
                                <img src="assets/images/accomodation/accommodation-01.jpg" class="img-fluid w-100">
                            </div>
                            <div class="content float-right">
                                <h4 class="mt-15">Macgregor</h4>
                                <hr>
                                <ul>
                                    <li>1 x vacancy</li>
                                    <li>Single story dwelling duplex built in 2013</li>
                                    <li>Sharing with one other</li>
                                    <li>24/7 staff support</li>
                                    <li>Two living areas</li>
                                    <li>Paved Back yard with garden</li>
                                    <li>Renovated accessible bathroom</li>
                                    <li>Would suit someone with young onset dementia who is mobile and variable levels of independence</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <div class="img-wrap float-left">
                                <img src="assets/images/accomodation/accommodation-03.png" class="img-fluid w-100">
                            </div>
                            <div class="content float-right">
                                <h4 class="mt-15">Stirling Property</h4>
                                <hr>
                                <p>SIL/ILO vacancy in Stirling A.C.T.  We are looking for 1 NDIS participant to share with 1 other 49 year old male. The house is situated on a large block with plenty of room inside and out.</p>
                                <p>There is ramp access to the back entrance and the dwelling would better suit ambulant participants. The house is currently staffed 24/7, It is 10 minutes to Woden Shopping Centre and 5 minutes to Cooleman Court shopping centre.”</p>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-12 col-12">
                    <div class="card border-0 mb-15">
                        <div  class="card-body text-center">
                            <p  class="mt-15">If you are interested or wish to enquire about vacancies and the direct support Caring Approach can provide, please contact the team on </p>
                            <p><a href="tel:+1300655026"><strong>Call 1300 655 026</strong></a> or <a class="text-theme" href="mailto:admin@caringapproach.com.au"><b>admin@caringapproach.com.au</b></a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


