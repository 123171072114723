// import { GalleryEventComponent } from './gallery-event/gallery-event.component';
import { GalleryYearComponent } from './gallery-year/gallery-year.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ChartsModule } from 'ng2-charts';
import { GaugeChartModule } from 'angular-gauge-chart';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { NgwWowModule } from 'ngx-wow';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
// Interceptor
// import { ExtendedHttpService } from './app.httpInterceptor';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HeroSliderComponent } from './hero-slider/hero-slider.component';
// import { SigninComponent } from './modules/signin/signin.component';
// import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { RegisterComponent } from './register/register.component';
import { ServicesSliderComponent } from './services-slider/services-slider.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ReportsComponent } from './reports/reports.component';
import { PrescriptionComponent } from './prescription/prescription.component';


import { WeightChartComponent } from './weight-chart/weight-chart.component';
import { FastingChartComponent } from './fasting-chart/fasting-chart.component';
import { PostMealChartComponent } from './post-meal-chart/post-meal-chart.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { PolicyComponent } from './policy/policy.component';
import { CareerComponent } from './career/career.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { ResourcesComponent } from './resources/resources.component';
import { NdisChecklistComponent } from './ndis-checklist/ndis-checklist.component';
import { ParticipantsComponent } from './participants/participants.component';
import { AccreditationComponent } from './accreditation/accreditation.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SupportCoordinationComponent } from './support-coordination/support-coordination.component';
import { AccomodationComponent } from './accomodation/accomodation.component';
import { AgedCareComponent } from './aged-care/aged-care.component';
import { PlanManagementComponent } from './plan-management/plan-management.component';
import { CommunityComponent } from './community/community.component';
import { DisabilityCareComponent } from './disability-care/disability-care.component';
import { DementiaCareComponent } from './dementia-care/dementia-care.component';
import { RecoveryCareComponent } from './recovery-care/recovery-care.component';
import { TransportComponent } from './transport/transport.component';
import { AutismComponent } from './autism/autism.component';
import { NewMumsComponent } from './new-mums/new-mums.component';
import { RespiteHelpComponent } from './respite-help/respite-help.component';
import { NursingCareComponent } from './nursing-care/nursing-care.component';
import { ClientReferralFormComponent } from './client-referral-form/client-referral-form.component';
// import { NewPasswordComponent } from './modules/forgot-password/new-password/new-password.component';
//import { NewPasswordComponent } from './new-password/new-password.component';
 
//import { OtpConfirmationComponent } from './otp-confirmation/otp-confirmation.component';
//import { MatFormFieldModule } from '@angular/material/form-field';
  

import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
// import { CustomDateParserFormatter } from '../directive/cutom-date-format';
import { GalleryInnerComponent } from './gallery-inner/gallery-inner.component'
import { AngularFireModule } from '@angular/fire';
import { MessageService } from './services/message/message.service';
import { AuthGuard } from './services/guards/auth-guard.service';
import { SharedModule } from './components/SharedModule.module';
import { GalleryEventComponent } from './gallery-event/gallery-event.component';
import { ExtendedHttpService } from './app.httpInterceptor';
import { CustomDateParserFormatter } from 'src/directive/cutom-date-format';
import { reducer , metaReducers} from './app.reducers';
import { NotificationService } from './modules/employee/services/notification.service';

FullCalendarModule.registerPlugins([ 
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PageNotFoundComponent,
    HeroSliderComponent,
    // SigninComponent,
    // ForgotPasswordComponent,
    RegisterComponent,
    ServicesSliderComponent,
    ChangePasswordComponent,
    ReportsComponent,
    PrescriptionComponent,
    WeightChartComponent,
    FastingChartComponent,
    PostMealChartComponent,
    AppointmentComponent,
    ContactUsComponent,
    AboutUsComponent,
    OurServicesComponent,
    PolicyComponent,
    CareerComponent,
    TestimonialsComponent,
    FeedbackComponent,
    ResourcesComponent,
    NdisChecklistComponent,
    ParticipantsComponent,
    AccreditationComponent,
    GalleryComponent,
    SupportCoordinationComponent,
    AccomodationComponent,
    AgedCareComponent,
    PlanManagementComponent,
    CommunityComponent,
    DisabilityCareComponent,
    DementiaCareComponent,
    RecoveryCareComponent,
    TransportComponent,
    AutismComponent,
    NewMumsComponent,
    RespiteHelpComponent,
    NursingCareComponent,
    ClientReferralFormComponent,
    GalleryInnerComponent,
    GalleryYearComponent,
    GalleryEventComponent
    // NewPasswordComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    AppRoutingModule,
    SlickCarouselModule,
    ChartsModule,
    GaugeChartModule,
    FullCalendarModule,
    FormsModule,
    NgwWowModule,
    StoreModule.forRoot(reducer,{ metaReducers}),
    StoreDevtoolsModule.instrument(),
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    ReactiveFormsModule,
    SharedModule,
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyDodNOQiIhWxaffAGvkLAC5LkNr6Ad6IX8",
      authDomain: "immersiveinfotech-d3768.firebaseapp.com",
      projectId: "immersiveinfotech-d3768",
      storageBucket: "immersiveinfotech-d3768.appspot.com",
      messagingSenderId: "946237935938",
      appId: "1:946237935938:web:a9dddc233b5b6c5a5e38db",
      measurementId: "G-3DWMSRWH81"
    })
   // HttpClientModule
	// BrowserAnimationsModule,
	//  ToastrModule.forRoot()
   // MatFormFieldModule
  ],
  providers: [
    MessageService,
    NotificationService,
    // { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ExtendedHttpService, multi: true },
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
