import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { UserReducer } from './reducers/user.reducer';
import { UserActionTypes } from './actions/user.actions';
import { ClientUserReducer  } from './reducers/client.reducer';
import { CommonReducer } from './reducers/common.reducer';
export const reducer = {
  user:UserReducer,
  client: ClientUserReducer,
  common: CommonReducer
}
export function rootReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  const storage = localStorage.getItem('remember') === 'true' ? 'localStorage': 'sessionStorage';
  return function(state, action) {

    switch (action.type) {
        case UserActionTypes.LOGOUT:
          window[storage].removeItem('auth');
          state = undefined;
    }

    return reducer(state, action);
  }
}
export const metaReducers: MetaReducer<any>[] = [rootReducer];