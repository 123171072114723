import { AuthService } from "src/app/services/auth.service"
import { Router } from "@angular/router"
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { catchError, map } from "rxjs/operators"
import { GlobalConstants } from "src/app/api.constants"

@Injectable()
export class NotificationService {
  private notifications = new BehaviorSubject<any[]>([])
  public readonly notifications$ = this.notifications.asObservable()

  private messageCount = new BehaviorSubject(0)
  public readonly messageCount$ = this.messageCount.asObservable()

  constructor(
    private http: HttpClient,
    private _router: Router,
    private _authService: AuthService
  ) {}

  /**
   *
   * @param userId
   * @param token
   */
  init(userid) {
    this.getAllNotifications()
  }

  /**
   * get all notifications
   */
  getAllNotifications() {
    this.http.get(GlobalConstants.endPoints.GET_NOTIFICATION).subscribe(
      (response: any) => {
        this.messageCount.next(response["Notification count"])
        this.notifications.next(response.result)
      },
      (err) => {
        console.log("error in all notification.", err)
      }
    )
  }

  /**
   * Mark a message as read
   */
  markAsRead(notification_id) {
    return this.http
      .put(`${GlobalConstants.endPoints.READ_NOTIFICATION}`, {
        notification_id,
      })
      .pipe(
        map((res) => {
          this.getAllNotifications()
          return res
        }),
        catchError((err) => {
          return err
        })
      )
  }

  redirectNotification(notification: any) {
    // Incident, Policy, Notice, Training, Appointment, Care Plan, Roster, support
    const type = notification.notify_type
    const baseUrl =
      this._authService.auth["role"] == "1" ? "/employee" : "/client"
    let url = ""
    switch (type) {
      case "Incident": {
        url = "report-incident"
        break
      }
      case "Policy": {
        url = "policy"
        break
      }
      case "Notice": {
        url = "support"
        break
      }
      case "Training": {
        url = "upcoming-training"
        break
      }
      case "Appointment": {
        url = "my-service-agreements"
        break
      }
      case "Care Plan": {
        url = "care-plans"
        break
      }
      case "Roster": {
        if (this._authService.auth["role"] == "1") url = "schedule"
        else url = "my-schedule"
        break
      }
      case "support": {
        url = "support"
        break
      }
      default:
        url = "notification"
    }
    this._router.navigate([baseUrl, url])
  }
}
