<section class="resources-section ">  
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Resources</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">Resources</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="resources-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <h4>Resources And Useful Links</h4>
                    <p class="mb-15">A resource directory for community information, client, family, peer support and advocacy.</p>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>The AuslanSignbank Dictionary</h4>
                            <a href="http://www.auslan.org.au/" target="_blank" class="text-theme">http://www.auslan.org.au/</a>
                            <p>Auslan Signbank is a language resources site for Auslan (Australian Sign Language). Auslan is the language of the deaf community in Australia.</p>
                            <p>Here you will find:</p>                        
                            <ul>
                                <li>A dictionary</li>
                                <li>A special medical and health dictionary</li>
                                <li>Grammar examples on video</li>
                                <li>Videos of deaf people using Auslan naturally</li>
                                <li>Information on the deaf community in Australia</li>
                                <li>Links to Auslan classes</li>
                            </ul>
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Self Advocacy Sydney Inc.</h4>
                            <a href="http://www.sasinc.com.au/" target="_blank" class="text-theme">http://www.sasinc.com.au</a>
                            <p>Self Advocacy Sydney is an organisation run by and for people with Intellectual Disability.  </p>
                            <p>Their Mission Statement</p>                        
                            <ul>
                                <li>Give training support and information to all members / consumers to develop their skills as self advocates.</li>
                                <li>Provide information and education to the community about self advocacy and the rights of the people who have Intellectual Disability.</li>
                                <li>Develop strong links with other advocacy and community groups. </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Council for Intellectual Disability (CID)</h4>
                            <a href="http://www.nswcid.org.au/" target="_blank" class="text-theme">http://www.cid.org.au</a>
                            <p>The NSW Council for Intellectual Disability (CID) is the peak organisation in NSW representing the interests of people with intellectual disability. The Council takes on such activities as providing policy advice, systemic advocacy, community education, and information provision and dissemination. CID has an information service and resource centre providing information to people with intellectual disability, their families, carers, advocates and service providers. CID is committed to the principles of inclusion and participation and we aim to ensure people with intellectual disability are included in every aspect of the work we undertake.</p>                        
                         </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Brain Injury Association of NSW</h4>
                            <a href="https://synapse.org.au/" target="_blank" class="text-theme">http://www.biansw.org.au</a>
                            <p>The Brain Injury Association of New South Wales is the leading advocacy body in NSW for people with an acquired brain injury, their families, carers and interested organisations. They represent their needs to government, service providers, broader community and the media. </p>
                            <p>Affiliated/Related Organisations: </p>                        
                            <ul>
                                <li>The Brain Injury Support Service Incorporated (BISSI) can be found at <a href="http://www.bissi.org.au/" class="text-theme" target="_blank" >http://www.bissi.org.au</a></li>
                                <li>Headway Adult Development Program can be found at  <a href="https://headwayadp.org.au/" class="text-theme" target="_blank" >http://www.headwayadp.org.au</a></li>
                                <li>Brain Injury Australia</li>
                            </ul>                        
                         </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Down Syndrome Association NSW</h4>
                            <a href="https://www.acnc.gov.au/charity/a3a11e39c039a4cdaf2092ccc7e95fa2" target="_blank" class="text-theme">http://www.dsansw.org.au</a>
                            <p>Down Syndrome NSW is an independent, registered charity established and run by parents. The work of Down Syndrome NSW depends on the generosity of its members and the general public in supporting its fundraising efforts and through donations.</p>    
                            <p>The Aim of Down Syndrome NSW is to encourage people with Down syndrome to fulfill their potential for successful and happy lives, in a society which knows their abilities and is supportive of their needs. The work of Down Syndrome NSW is to represent the interests and respond to the needs of people with Down syndrome in the major aspects of their lives; as members of the family, in their individual pursuits, and as members of the community.  </p>                    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Physical Disability Council of NSW </h4>
                            <a href="https://www.pdcnsw.org.au/" target="_blank" class="text-theme">http://www.pdcnsw.org.au/</a>
                            <p>PDCN is the peak body in NSW representing people with life-long physical disability across the state.</p> 
                            <p>Objectives</p>                       
                            <ul>
                                <li>To educate, inform and assist people with physical disabilities in New South Wales regarding the range of services, structures and programs available that enable their full participation, equality of opportunity and equality of citizenship; </li>
                                <li>To develop the capacity of people with physical disabilities in New South Wales to identify their own goals and the confidence to indentify a pathway to achieve their goals; </li>
                                <li>To educate and inform all stakeholders about the needs of people with physical disabilities so they are able to achieve and maintain full participation, equality of opportunity and equality of citizenship.</li>
                            </ul>                        
                         </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Epilepsy Action Australia</h4>
                            <a href="https://www.epilepsy.org.au/" target="_blank" class="text-theme">www.epilepsy.org.au</a>
                            <p>Epilepsy Action provides education and support services to children and adults with epilepsy or other seizure disorders across Australia. Their vision is that all Australians affected by seizures will have the opportunity to live confident lives.</p>    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Carers NSW Inc.</h4>
                            <a href="http://www.carersnsw.org.au/" target="_blank" class="text-theme">http://www.carersnsw.asn.au/</a>
                            <p>Carers NSW is an association for relatives and friends caring for people with a disability, mental illness, drug and alcohol dependencies, chronic condition, terminal illness or who are frail. We are the peak organisation for carers in New South Wales. </p>    
                            <ul>
                                <li>ACT Carers</li>
                                <li>Carers Australia</li>
                                <li>Carers Northern Territory</li>
                                <li>Carers Queensland</li>
                                <li>Carers South Australia</li>
                                <li>Carers Tasmania</li>
                                <li>Carers Victoria</li>
                                <li>Carers Western Australia</li>
                            </ul>
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>People With Disability ( PWD )</h4>
                            <a href="https://pwd.org.au/" target="_blank" class="text-theme">http://www.pwd.org.au/</a>
                            <p>People with Disability Australia Incorporated (PWD) is a national peak disability rights and advocacy organisation. Its primary membership is made up of people with disability and organisations primarily constituted by people with disability. PWD also has a large associate membership of other individuals and organisations committed to the disability rights movement. PWD was founded in 1981, the International Year of Disabled Persons, to provide people with disability with a voice of our own. We have a cross-disability focus - we represent the interests of people with all kinds of disability. PWD is a non-profit, non-government organisation. </p>    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Spinal Cord Injuries Australia ( SCI )</h4>
                            <a href="https://scia.org.au/" target="_blank" class="text-theme">http://www.scia.org.au/</a>
                            <p>A society without barriers for people with spinal cord injury. Constant innovation creates independence and unlimited opportunity for people living with spinal cord injury. </p>    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Alzheimer's Australia</h4>
                            <a href="http://www.alzheimers.org.au/" target="_blank" class="text-theme">http://www.alzheimers.org.au/</a>
                            <p>Alzheimer's Australia is the peak body providing support and advocacy for Australians living with dementia.</p>    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Black Dog Institute</h4>
                            <a href="https://www.blackdoginstitute.org.au/" target="_blank" class="text-theme">http://www.blackdoginstitute.org.au/</a>
                            <p>The Black Dog Institute is an educational, research, clinical and community-oriented facility offering specialist expertise in mood disorders - a range of disorders that include depression and bipolar disorder.  </p>    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Multiple Sclerosis Australia</h4>
                            <a href="https://www.ms.org.au/" target="_blank" class="text-theme">http://www.mssociety.org.au/live/default.asp</a>
                            <p>Multiple Sclerosis Limited is the entity that manages and operates the MS Australia - ACT/NSW/VIC. MS Australia - ACT/NSW/VIC has been supporting and helping people with multiple sclerosis (MS) since 1956. Through an extensive network of centres, branches, support groups and health services, MS Australia - ACT/NSW/VIC provides specialist programs to people with MS, their families, carers, friends and health care professionals. MS Australia - ACT/NSW/VIC informs people with multiple sclerosis how best to achieve as full and healthy a lifestyle as possible while managing the symptoms of MS, raises funds for the provision of services, programs and research, educates the community, and liaises with government and other bodies on MS-related issues.       </p>    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Muscular Dystrophy Association</h4>
                            <a href="https://mdnsw.org.au/" target="_blank" class="text-theme">http://mdnsw.org.au/</a>
                            <p>Muscular Dystrophy Association of NSW (MDNSW) has been serving and supporting people with neuromuscular disorders, including muscular dystrophy for over 50 years. Through the provision of respite care, essential equipment, activities and client contact in homes and clinics, MDNSW actively supports individuals and families in numerous practical ways. </p>    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Parkinson’s Australia</h4>
                            <a href="https://www.parkinsons.org.au/" target="_blank" class="text-theme">http://www.parkinsons.org.au/</a>
                            <p>Parkinson’s Australia is made up of a Board consisting of the Presidents of all the State and Territory Parkinson’s organisations. Our principal service is advocacy and to be the voice of people with Parkinson’s. As the peak nationwide agency, we represent the needs and interests of people with Parkinson’s to all levels of government seeking improved funding for services, research and awareness. Improving the understanding of Parkinson’s within the Australian community is recognised as one of priorities.</p>    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Prader-Willi Syndrome Association of Australia Inc</h4>
                            <a href="http://www.pws.org.au/" target="_blank" class="text-theme">http://www.pws.org.au/</a>
                            <p>We are a self help group comprising people born with Prader-Willi Syndrome, parents, friends and interested professionals. The aim of this site is to provide accurate information on Prader-Willi Syndrome (PWS) and resources that are available</p>    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Aged Care Rights Service</h4>
                            <a href="http://www.tars.com.au/" target="_blank" class="text-theme">http://www.tars.com.au</a>
                            <p>The Aged Care Rights Service (TARS) is an advocacy service for older residents living in supported accommodation in NSW. This organisation offers free legal advice, advocacy and negotiation services for people coming before the Residential Tenancies Tribunal and the Guardianship Tribunal </p>    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Brain Injury Association of NSW</h4>
                            <a href="https://synapse.org.au/" target="_blank" class="text-theme">http://www.biansw.org.au</a>
                            <p>This organisation provides information about the needs, services and issues affecting people who have an acquired brain injury. They provide support groups and programs, and have a free information line - 1800 802 840.</p>    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Carers NSW</h4>
                            <a href="http://www.carersnsw.org.au/" target="_blank" class="text-theme">http://www.carersnsw.asn.au/</a>
                            <p>This organisation offers support, service information and advice to carers of the frail aged and people with disabilities. They have a national free call number - 1800 242 636. </p>    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Deaf Society NSW</h4>
                            <a href="http://www.deafsocietynsw.org.au/" target="_blank" class="text-theme">http://www.deafsocietynsw.org.au/</a>
                            <p>The Deaf Society NSW offers information and services (including vocational support and interpreter assistance) to people with a hearing impairment in NSW. </p>    
                        </div>
                    </div>
                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Vision Australia NSW</h4>
                            <a href="http://www.visionaustralia.org.au/" target="_blank" class="text-theme">http://www.visionaustralia.org.au/</a>
                            <p>Vision Australia provides specialist services, information, advice and support to people who are blind or vision impaired, their families and carers, who reside in NSW.</p>    
                        </div>
                    </div>

                    <div class="card border-0 mb-15">
                        <div class="card-body">
                            <h4>Older Persons Advocacy Network</h4>
                            <a href="https://opan.org.au/" target="_blank" class="text-theme">https://opan.org.au/</a>
                            <p>
                                The Older Persons Advocacy Network is made up of nine state and territory member organisations that have helped thousands of people just like you to work through issues with their Australian Government-funded aged care services.</p>    
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
