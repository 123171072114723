import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../../app.globalConstants';
interface Response{
  status:boolean,
  result:any,
  message:string
}

@Injectable({
  providedIn: 'root'
})
export class DynamicapiService {

  constructor(  private http: HttpClient,) { }

  // heroSlides(heroSlides){
  //   return this.http.post(GlobalConstants.endPoints.GET_HEROSLiDES, {'homeslider':heroSlides});
  // }
  heroSlides(){
    return this.http.get(GlobalConstants.endPoints.GET_HEROSLIDES);
  }

  aboutData(){
    return this.http.get(GlobalConstants.endPoints.GET_ABOUTDATA);
  }

  yourlifeData(){
    return this.http.get(GlobalConstants.endPoints.GET_YOURLIFE);
  }

  testimonialsData(){
    return this.http.get(GlobalConstants.endPoints.GET_TESTIMONIALS);
  }

  // About us Page
  
  aboutusData(){
    return this.http.get(GlobalConstants.endPoints.GET_ABOUT);
  }

}
