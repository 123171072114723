<div class="error-page">
    <div class="error-content">
        <div class="container">
            <img src="assets/images/error.png" alt="" class="img-fluid">
            <div class="error-message">
                <h1 class="error-title">Whoops!</h1>
                <p>Looks like you are lost.</p>
            </div>
            <form class="search-form">
                <button type="submit" class="btn theme-btn" routerLink="/home">Go to Home!</button>
            </form>
        </div>
    </div>
</div>
