import { Action } from '@ngrx/store';
import { CommonActionType } from '../actions/common.actions';

const defaultState={
       country:[],
       serviceTypes:[],
       healthNews:[],
       healthTips:[]       
}
export function CommonReducer(state = defaultState, action:Action){
	switch (action.type) {
        case CommonActionType.GET_COUNTRIES:
            return Object.assign({}, state, {country: action['payload']})
        case CommonActionType.GET_SERVICE_LIST:
            return Object.assign({}, state, {serviceTypes: action['payload']})
        case CommonActionType.GET_HEALTH_NEWS_SUCCESS:
            return Object.assign({}, state, { healthNews: action['payload']})
        case CommonActionType.GET_HEALTH_NEWS_FAILURE:
            return Object.assign({}, state, { healthNews : [] })
        case CommonActionType.GET_HEALTH_TIPS_SUCCESS:
            return Object.assign({}, state, { healthTips : action['payload']})
        case CommonActionType.GET_HEALTH_TIPS_FAILURE:
            return Object.assign({}, state, { healthTips: [] })
        default:
			return state;
	}
}
