<section class="about-section">  
    <div class="page-heading pos-rel">
        <img [src]='"https://caringapproach.com.au/superadmin/"+aboutusData[0].image' alt="">
        <div class="container pos-rel d-flex flex-column align-items-center">

            <h1 class="page-heading-title text-white">{{aboutusData[0].title}}</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">{{aboutusData[0].title}}</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="about-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-6 col-12 md-mb-30">   
                    <div class="card border-0">
                        <div class="card-body">
                            <h3><b>{{aboutusData[1].title}}</b></h3>
                            <hr>
                            <p class="mb-20">{{aboutusData[1].description}}</p>
                            <h5 class="mb-20"><b>{{aboutusData[2].title}}</b></h5>
                            <p class="mb-20 pb-50">{{aboutusData[2].description}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-12">
                    <div class="row">
                        <div class="col-md-6 col-12 img-wrap d-none d-lg-block d-md-none d-sm-none">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <div class="card border-0 mb-5">
                                        <div class="card-body p-0">
                                            <img [src]='"https://caringapproach.com.au/superadmin/"+aboutusData[1].image'  class="img-fluid w-100">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-12">
                                   <div class="card border-0">
                                        <div class="card-body p-0">
                                            <img [src]='"https://caringapproach.com.au/superadmin/"+aboutusData[2].image' class="img-fluid w-100">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-12">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <div class="card border-0">
                                        <div class="card-body p-0">
                                            <div class="box p-20">
                                                <img [src]='"https://caringapproach.com.au/superadmin/"+aboutusData[3].image' alt="">
                                                <p class="text-white">{{aboutusData[3].description}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--<section class="services-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12 text-center">
                    <h2 class="title">Our Team</h2>
                    <p class="text">We are proud to play a part in helping you live your best life. Our experienced team is committed to help you live your life to the fullest.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12">
                    <div class='content'>
                        <div class='slider single-item'>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content">
                                        <div class="info-wrap">
                                            <div class="info">
                                                <img src="assets/images/services/our-services.png" class="img-fluid profile-pic">
                                                <div class="team-header">
                                                    <h3 class="name">Ora Klein</h3>
                                                    <div class="position">Psychologist</div>
                                                    <p class="content">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content">
                                        <div class="info-wrap">
                                            <div class="info">
                                                <img src="assets/images/services/our-services.png" class="img-fluid profile-pic">
                                                <div class="team-header">
                                                    <h3 class="name">Ora Klein</h3>
                                                    <div class="position">Psychologist</div>
                                                    <p class="content">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content">
                                        <div class="info-wrap">
                                            <div class="info">
                                                <img src="assets/images/services/our-services.png" class="img-fluid profile-pic">
                                                <div class="team-header">
                                                    <h3 class="name">Ora Klein</h3>
                                                    <div class="position">Psychologist</div>
                                                    <p class="content">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content">
                                        <div class="info-wrap">
                                            <div class="info">
                                                <img src="assets/images/services/our-services.png" class="img-fluid profile-pic">
                                                <div class="team-header">
                                                    <h3 class="name">Ora Klein</h3>
                                                    <div class="position">Psychologist</div>
                                                    <p class="content">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content">
                                        <div class="info-wrap">
                                            <div class="info">
                                                <img src="assets/images/services/our-services.png" class="img-fluid profile-pic">
                                                <div class="team-header">
                                                    <h3 class="name">Ora Klein</h3>
                                                    <div class="position">Psychologist</div>
                                                    <p class="content">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>-->
    <div class="other-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="d-flex align-items-center mt-20">
                        <img src="assets/images/about/tick.svg" class="img-fluid w-100 mr-15" style="width:20%!important;">
                        <span class="fz-16">{{aboutusData[4].title}} <br><span class="text-blue fw-600 fz-20"> {{aboutusData[4].other_content}}</span></span>
                    </div>
                </div>
                <div class="col-md-9" [innerHTML]="aboutusData[4].description">
                    <!-- <p>Should your circumstances, needs, or simply your preferences change at any time our services offer the flexibility to fit in with your changing life so that you won't be stuck in the same old routine day in day out. We believe in everyone's right to feel safe</p>
                    <p>It is important to us that all our clients and staff are safe and secure at all times. Therefore all our staff members are highly trained in identifying and correcting or reporting any potential hazards, and in manual handling and safety procedures so that our clients are in the safest of hands.</p> -->
                </div>
            </div>
        </div>
    </div>
    <div class="services-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="box-wrap">
                        <div class="icon-wrap mb-30 pos-rel">
                            <img src="assets/images/services/support.svg" class="img-fluid normal">
                        </div>
                        <div class="box-content">
                            <h4 class="title mb-10 mt-0"><b>{{aboutusData[5].title}}</b></h4>
                            <p class="content">{{aboutusData[5].description}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <a href="javascript:void(0);" routerLink="/">
                        <div class="box-wrap">
                            <div class="icon-wrap mb-30 pos-rel">
                                <img src="assets/images/services/tools.svg" class="img-fluid normal">
                            </div>
                            <div class="box-content">
                                <h4 class="title mb-10 mt-0"><b>{{aboutusData[6].title}}</b></h4>
                                <p class="content">{{aboutusData[6].description}}</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-4 col-12">
                    <a href="javascript:void(0);" routerLink="/">
                        <div class="box-wrap">
                            <div class="icon-wrap mb-30 pos-rel">
                                <img src="assets/images/services/wheelchair.svg" class="img-fluid normal">
                            </div>
                            <div class="box-content">
                                <h4 class="title mb-10 mt-0"><b>{{aboutusData[7].title}}</b></h4>
                                <p class="content">{{aboutusData[7].description}}</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="gov-funded pos-rel inner-wrap">
         <img [src]='"https://caringapproach.com.au/superadmin/"+aboutusData[8].image' alt="">
        <div class="container pos-rel d-flex flex-column align-items-center">
           
            <h1 class="page-heading-title text-white">{{aboutusData[8].title}}</h1>
            <p class="text-white">Caring Approach is registered with NDIS & Myagedcare <br> to help you avail their help and benefits</p>
        </div>
    </div>
    <div class="social-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="theme-box p-20">
                                <h4 class="text-white">{{aboutusData[9].title}}</h4>
                                <p class="text-white">{{aboutusData[9].description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="blue-box p-20">
                                <h4 class="text-white">{{aboutusData[10].title}}</h4>
                                <p class="text-white">{{aboutusData[10].description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="box p-20">
                                <h4>{{aboutusData[11].title}}</h4>
                                <span>
                                    <i class="fas fa-envelope mr-5"></i>
                                    <a  href="mailto:admin@caringapproach.com.au" class="text-theme" target="_blank"><span>admin@caringapproach.com.au</span></a>
                                </span><br>
                                <span>
                                   <i class="fas fa-phone mr-5"></i>
                                   <a href="tel:+1300655026"><span>1300 655 026</span></a>
                                </span><br>
                                <span>
                                   
                                    <i class="fas fa-phone mr-5"></i>
                                    <a href="tel:+0431744321"><span>0431 744 321</span>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
